import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import clsx from 'clsx';
import WelcomeBanner from './WelcomeBanner';
import useFetch from '../../../../hooks/useFetch';
import UpcomingAppointments from './UpcomingAppointments';
import styles from './member_self_serve.module.scss';
import { csrfHeader, noop } from '../../../../helperFunctions';
import { useModal } from '../../../../context/modal';
import CancelReason from '../../../../components/appointments/CancelReason';
import ButtonWrapper from './ButtonWrapper';
import WarningWrapper from './WarningWrapper';
import './style.scss';
import useWindowSize from '../../../../hooks/useWindowSize';
import ResendMssLink from '../../../../components/call_center/member_self_serve_journey/ResendMssLink';
import TopMessageBanner from './TopMessageBanner';

const MemberSelfServe = ({
  setLoader,
  memberSelfServeId,
  patient,
  // eslint-disable-next-line no-unused-vars
  bannerText = {},
  welcomeText,
  assessmentButtonTexts,
  startAssessmentUrl,
  cancelReasonEnabled,
  organizationID,
  cancellationPollingTimeout,
  sendLinkFormTexts,
  cancelApptModalText,
  showSendLinkForm,
  initiallyShowRetrySendLinkBanner,
}) => {
  const [, upcomingAppointments, , fetchUpcomingAppointments] = useFetch(
    `/member_self_serve/${memberSelfServeId}/existing_appointments`,
  );
  const [limits, setLimits] = useState([]);
  const [displaySendLinkForm, setDisplaySendLinkForm] =
    useState(showSendLinkForm);

  const fetchMSSData = () =>
    axios.get(`/member_self_serve/${memberSelfServeId}/verify_limits`);

  const fetchLimits = ({ showLoader = true } = {}) => {
    if (showLoader) setLoader(true);
    fetchMSSData()
      .then((res) => {
        setLimits(res.data);
      })
      .catch((err) => {
        console.log('Error: fetchLimits', err);
      })
      .finally(() => {
        if (showLoader) setLoader(false);
      });
  };
  const { alertDialogue, setModal, unSetModal } = useModal();
  // eslint-disable-next-line no-unused-vars
  const [dynamicSecondaryText, setDynamicSecondaryText] = React.useState('');
  const [patientLocation, setPatientLocation] = React.useState(null);
  const [topBannerMessage, setTopBannerMessage] = React.useState();
  const startTime = React.useRef();

  const getEvents = () => {
    fetchUpcomingAppointments();
    fetchLimits();
  };

  const reloadPage = () => {
    setLoader(true);
    window.location.reload();
  };

  const toggleMSSLinkForm = (message) => {
    setLoader(true);
    fetchMSSData()
      .then((res) => {
        if (res.data) {
          setDisplaySendLinkForm(res.data.showSendLinkForm);
        }
        getEvents();
        setTopBannerMessage(message);
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        setTopBannerMessage(message);
        console.log('Error: toggleMSSLinkForm', err);
      });
  };

  const continueConfirm = useCallback((id, data) => {
    axios
      .patch(`/member_self_serve/update_status?id=${id}`, data, {
        csrfHeader,
      })
      .then((res) => {
        setLoader(false);
        getEvents();
        return res.data;
      })
      .catch((err) => {
        setLoader(false);
        const msg =
          err.response.data && err.response.data.message
            ? err.response.data.message
            : err;
        if (msg) {
          alert(msg);
          setDynamicSecondaryText(msg);
        }
      });
  }, []);

  const pollingCall = useCallback((transactionId, id, data, patientId) => {
    axios
      .get(`/call_centers/transaction_confirmation`, {
        params: {
          transaction_id: transactionId,
          patient_id: patientId,
        },
      })
      .then((response) => {
        const currentTime = Date.now() / 1000;
        if (response.data.status === 'success') {
          continueConfirm(id, data);
        } else if (
          currentTime - startTime.current > cancellationPollingTimeout ||
          response.data.status === 'failed'
        ) {
          alert(
            'We’re sorry, but your original appointment can no longer be modified by Lucet. Please contact the provider directly for any appointment changes needed.',
          );
          setLoader(false);
        } else {
          pollingCall(transactionId, id, data, patientId);
        }
      });
  }, []);

  const handleVisitCancellationRequest = useCallback((id, data, patientId) => {
    axios
      .get(`/call_centers/create_visit_cancellation_request_transaction`, {
        params: {
          encounter_id: id,
          patient_id: patientId,
        },
      })
      .then((res) => {
        startTime.current = Date.now() / 1000;
        pollingCall(res.data.transaction_id, id, data, patientId);
      });
  }, []);

  const handleCancelAppointment = useCallback(
    (id, cancelOnProvider, patientId) => {
      const data = {
        visit_status: 'cancelled',
        pageName: 'Member Self-Serve Page',
      };

      if (cancelReasonEnabled) {
        setModal(
          <CancelReason
            id={id}
            closeModal={unSetModal}
            formName="cancel-reason"
            setLoader={setLoader}
            getEvents={getEvents}
            /* eslint-disable-next-line jsx-a11y/aria-role */
            role="member"
            organizationID={organizationID}
            customClass="mss_cancel_reason"
            pageName={data.pageName}
            cancelOnProvider={cancelOnProvider}
            cancellationPollingTimeout={cancellationPollingTimeout}
            patientId={patientId}
            stringValues={cancelApptModalText}
          />,
        );
        // eslint-disable-next-line no-restricted-globals
      } else if (confirm('Are you sure you want to cancel this appointment')) {
        setLoader(true);
        if (cancelOnProvider) {
          handleVisitCancellationRequest(id, data, patientId);
        } else {
          continueConfirm(id, data);
        }
      }
    },
    [],
  );

  const handleRescheduleAppointment = useCallback((id) => {
    setLoader(true);
    axios({
      method: 'post',
      url: '/member_self_serve/reschedule_appointment',
      data: { encounter_id: id },
      headers: csrfHeader,
    })
      .then((res) => {
        const { data } = res;
        if (!data.success && !data.redirect) {
          setLoader(false);
        }
        if (data.success) {
          if (data.redirect) {
            setLoader(true);
            window.location.href = data.redirect;
          }
        } else if (data.errors) {
          alertDialogue(data.errors.map((e) => <div>{e}</div>));
        } else if (data.messages) {
          setDynamicSecondaryText(data.messages[0]);
        } else {
          alertDialogue('Something went wrong');
        }
      })
      .catch((err) => {
        console.log('error', err);
        setLoader(false);
        alertDialogue('Something went wrong');
      });
  }, []);

  const fetchPatientLocation = useCallback(
    (location) => axios.get(`/seach_address?q=${encodeURIComponent(location)}`),
    [],
  );

  useEffect(() => {
    fetchLimits();
  }, []);

  // call patient location api with delay on load
  React.useEffect(() => {
    if (patient?.location) {
      setTimeout(() => {
        fetchPatientLocation(patient.location).then((r) => {
          if (r.data && r.data.results && r.data.results.length > 0) {
            setPatientLocation(r.data.results[0]);
          }
        });
      }, 800);
    }
  }, [fetchPatientLocation, patient?.location]);

  const { windowWidth, windowHeight } = useWindowSize();
  // `vh` does not work in mobile views as it does not represent
  // the actually available height. i.e., it includes the address
  // bar, bookmark bar height into it as well (if present).
  useEffect(() => {
    // eslint-disable-next-line no-use-before-define
    setMainContainerHeight();
    setTimeout(() => {
      // eslint-disable-next-line no-use-before-define
      setMainContainerHeight();
    }, 500);
  }, [windowWidth, windowHeight]);

  const setMainContainerHeight = () => {
    const mainContainer = document.getElementsByClassName(
      'member_self_serve-right-section',
    )[0];
    const topHeader = document.getElementsByClassName(
      'member_self_serve-header',
    )[0];
    const footerElement = document.getElementsByClassName('footer-fixed')[0];
    if (mainContainer && topHeader) {
      const { innerWidth, innerHeight } = window;
      const topHeaderHeight = innerWidth <= 1234 ? topHeader.clientHeight : 0; // subtract height when header is at top
      const availableHeight =
        innerHeight - topHeaderHeight - footerElement.clientHeight;
      const calculatedContainerHeight = `${availableHeight - 1}px`; // cover a little less than available to avoid outer overflow issues
      mainContainer.setAttribute(
        'style',
        `max-height: ${calculatedContainerHeight}; overflow-y: auto`,
      );
    }
  };

  // A member may cancel an appointment on other tab and may come
  // back to this page, at that moment we want to refresh the data.
  useEffect(() => {
    // The event is not triggered when switching to a different program or window using ALT+TAB2.
    // Also, the event doesn't fire when navigating away from a document.
    function handleVisibilityChange() {
      try {
        if (document.visibilityState === 'visible') {
          // console.log('Dashboard is active');
          if (!showSendLinkForm) getEvents();
        } else {
          // console.log('Dashboard is inactive');
        }
      } catch (e) {
        console.log('Error: handleVisibilityChange', e.message);
      }
    }
    try {
      document.addEventListener('visibilitychange', handleVisibilityChange);
    } catch (e) {
      console.log('Error: addEventListener:visibilitychange', e.message);
    }
    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      try {
        document.removeEventListener(
          'visibilitychange',
          handleVisibilityChange,
        );
      } catch (e) {
        console.log('Error: removeEventListener:visibilitychange', e.message);
      }
    };
  }, [showSendLinkForm]); // Empty dependency array means this effect runs once on mount and cleanup on unmount

  const DynamicComponent = useCallback(
    ({ children }) => {
      const Component = displaySendLinkForm ? 'div' : React.Fragment;
      const includeClass = displaySendLinkForm;
      const classNames = displaySendLinkForm
        ? [styles.scrollableMidsection, 'flex-grow', styles.displayFlexCol]
        : [];
      return includeClass ? (
        <Component className={clsx(...classNames)}>{children}</Component>
      ) : (
        <Component>{children}</Component>
      );
    },
    [displaySendLinkForm],
  );

  return (
    <div className={clsx(styles.selfServeWrapper, 'mss-member-home')}>
      <WelcomeBanner
        limits={limits}
        styles={styles}
        welcomeText={welcomeText}
      />
      <DynamicComponent>
        {topBannerMessage && <TopMessageBanner message={topBannerMessage} />}
        <UpcomingAppointments
          upcomingAppointments={upcomingAppointments}
          retakeScreener={limits && limits.retakeScreener}
          cancellationLimitReached={limits && limits.cancellationLimitReached}
          handleCancelAppointment={handleCancelAppointment}
          handleRescheduleAppointment={handleRescheduleAppointment}
          patientLocation={patientLocation}
          styles={styles}
          expired={limits.expired}
          noAppointmentsTexts={limits.noAppointmentsTexts}
          showSendLinkForm={displaySendLinkForm}
          showActionButtons={limits.showActionButtons}
        />
        {displaySendLinkForm && (
          <ResendMssLink
            patientId={patient.id}
            resendMssLinkData={{
              can_resend_mss_link: true,
              notification_methods: patient.notification_data,
            }}
            refreshJourneyData={noop}
            version="member_self_serve"
            sendLinkFormTexts={sendLinkFormTexts}
            onSuccess={({ message }) => {
              toggleMSSLinkForm(message);
            }}
            onPartialSuccess={reloadPage}
            memberSelfServeId={memberSelfServeId}
            initiallyShowRetrySendLinkBanner={initiallyShowRetrySendLinkBanner}
          />
        )}
      </DynamicComponent>
      {!displaySendLinkForm && (
        <div className={styles.footerWrapperContainer}>
          <WarningWrapper styles={styles} limits={limits} />
          {limits.showActionButtons && (
            <ButtonWrapper
              styles={styles}
              limits={limits}
              startAssessmentUrl={startAssessmentUrl}
              memberSelfServeId={memberSelfServeId}
              assessmentButtonTexts={assessmentButtonTexts}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default MemberSelfServe;
