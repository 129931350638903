import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import clsx from 'clsx';
import AppTooltip from '../../components/shared/AppTooltip';
import styles from './pronoun_badge.module.scss';
import useWindowSize from '../../hooks/useWindowSize';

const PronounBadge = ({
  pronoun,
  tooltipPlacement = 'right',
  theme = 'light',
  customClassName,
}) => {
  const [uniqueId] = useState(() => uuidv4());
  const { windowWidth } = useWindowSize();
  if (!pronoun) return null;
  const isMobile = windowWidth < 1235;
  const tooltip = pronoun.tooltip_text;
  return (
    <React.Fragment>
      <span
        className={`${styles.pronounName} ${customClassName} ${
          theme === 'light' ? styles.dimWhiteClr : styles.dimBlackClr
        }`}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...(!isMobile && {
          'data-tip': true,
          'data-for': `pronouns-tooltip__${uniqueId}`,
        })}
      >
        {pronoun.name}
      </span>
      {tooltip && (
        <React.Fragment>
          {isMobile && (
            <span
              className={clsx(
                styles.infoIcon,
                'margin-left-xs material-symbols-outlined',
              )}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...(isMobile && {
                'data-tip': true,
                'data-for': `pronouns-tooltip__${uniqueId}`,
              })}
            >
              info
            </span>
          )}
          <AppTooltip
            className={styles.badgeTooltipReact}
            id={`pronouns-tooltip__${uniqueId}`}
            event={isMobile && 'click'}
            place={tooltipPlacement}
            type={theme}
          >
            <div style={isMobile ? { width: 180 } : undefined}>{tooltip}</div>
          </AppTooltip>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};
export default PronounBadge;
