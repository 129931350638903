import React from 'react';
import DialogModal from "../../../components/DialogModal";

class Confirm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {modal: {}}

  }

  handleSubmit = () => {
    const {submitURL, setLoader} = this.props;
    if (submitURL) {
      setLoader(true);
      window.location.href = submitURL;
    }
  }

  handleCancel = () => {
    this.openModal("DialogModal")
  }

  openModal = (name) => {
    const {setLoader} = this.props;
    setLoader(false);
    document.body.classList.add("modal-open");
    this.setState({
      modal: Object.assign({}, this.state.modal, {
        [name]: true,
      })
    });
  };

  closeModal = (name) => {
    document.body.className = document.body.className.replace(
      'modal-open',
      ''
    );
    this.setState({
      modal: Object.assign({}, this.state.modal, {
        [name]: false
      }),
    });
  };

  render() {
    const {dialogSaveButtonURL, patient, welcomeTitle, firstNameLabel,
      middleNameLabel, lastNameLabel, dobLabel, locale, patientDOB, nextButtonLabel,
      backButtonLabel, backButtonLabelMobile} = this.props;
    const {modal} = this.state;
    return (patient ?
        <div>
          <h2 className="prompt">{welcomeTitle}</h2>
          <div className={`rows`}>
            <div className="form-group">
              <h3>{firstNameLabel}</h3>
              <span className="prompt capitalize">{patient.first_name}</span>
            </div>
            {locale !== "es" && (
              <div className="form-group">
                <h3>{middleNameLabel}</h3>

                <span className="prompt capitalize">{patient.middle_initial}</span>
              </div>
            )}
            <div className="form-group">
              <h3>{lastNameLabel}</h3>
              <span className="prompt capitalize">{patient.last_name}</span>
            </div>
            <div className="form-group">
              <h3>{dobLabel}</h3>
              <span className="prompt">{patientDOB}</span>
            </div>
          </div>

          <div className="navigation-buttons-space clear">
            <button type="submit" className="next-btn-active flt-rgt confirm-btn btn-redirect" id="btn-next" onClick={this.handleSubmit}>
              {nextButtonLabel}
            </button>
            <button type="submit" className="btn-back flt-lft cancel-btn desktop-only" onClick={this.handleCancel}>
              {backButtonLabel}
            </button>
            <button type="submit" className="btn-back flt-lft cancel-btn mobile-only" onClick={this.handleCancel}>
              {backButtonLabelMobile}
            </button>
          </div>
          {modal.DialogModal && (
            <DialogModal
              {...this.props}
              closeModal={() => {
                this.closeModal('DialogModal');
                window.location.href = "/welcome"
              }}
              saveModal={() => {
                this.closeModal('DialogModal');
                if(dialogSaveButtonURL) {
                  window.location.href = dialogSaveButtonURL;
                }
              }}
            />
          )}
        </div>
        : <span className="prompt">No Patient Found.</span>
    );
  }
}

export default Confirm;
