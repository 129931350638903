/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import axios from 'axios';
import { Formik, Form, FieldArray } from 'formik';

import { ModalContext } from '../../../../context/modal';
import navigationHandler from '../../../components/navigationHandler';
import { RadioGroup } from '../../../components/inputs';

class PatientConsent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      initialValues: {},
      fields: {},
    };
  }

  fetchInitialValues = () => {
    const { getCurrentParams, surveyId } = this.props;
    const currentParams = getCurrentParams();
    const { setLoader } = this.props;
    setLoader(true);
    axios
      .get(`open_filters/patient_consent?survey_id=${surveyId}`, {})
      .then((r) => {
        const { data } = r;
        if (data !== null || data !== 'undefined') {
          const fields = Object.values(data.fields);
          const defaultValues = Object.assign(
            ...fields.map((field) => ({ [field.name]: field.default })),
          );
          this.setState(
            {
              fields: data.fields,
              initialValues: defaultValues,
            },
            () => {
              setLoader(false);
            },
          );
        }
      })
      .catch((err) => {
        setLoader(false);
        window.location.href = '/survey-not-authorized';
      });
  };

  componentDidMount() {
    this.fetchInitialValues();
  }

  handleSubmit = (values) => {
    const { setLoader, surveyId } = this.props;
    const { patient_consent_status } = values;
    setLoader(true);
    axios({
      method: 'post',
      url: 'save_patient_consent',
      data: {
        survey_id: surveyId,
        patient_consent_status:
          patient_consent_status === 1 || patient_consent_status === '1',
      },
    })
      .then((r) => {
        if (r && r.data && r.data.next_page) {
          window.location.replace(r.data.next_page);
        }
      })
      .catch((err) => {
        setLoader(false);
        const msg =
          err.response.data && err.response.data.message
            ? err.response.data.message
            : err;
        if (msg) alert(msg);
      });
  };

  render() {
    const {
      initialValues,
      fields: { patient_consent_status },
    } = this.state;
    return (
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={this.handleSubmit}
      >
        {({ errors, values }) => (
          <Form className="consent-screen assessment-page-content survey-form">
            <div className="survey-form-inner" aria-live="polite" tabIndex={-1}>
              <div className="consent-screen__title">
                <span>Consent to Share Information</span>
              </div>
              <div className="consent-screen__placeholder">
                By selecting <strong>Yes</strong>, I agree to share assessment
                results and appointment information that may INCLUDE alcohol and
                substance use or abuse information.
              </div>
              <div>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <div className="consent-screen__check-wrapper">
                    <div style={{ width: '33%' }} />
                  </div>
                  <FieldArray
                    name="checked"
                    render={(arrayHelpers) => (
                      <React.Fragment>
                        {patient_consent_status && (
                          <RadioGroup
                            label={patient_consent_status.label}
                            name="patient_consent_status"
                            options={patient_consent_status.options || []}
                          />
                        )}
                      </React.Fragment>
                    )}
                  />
                </div>
              </div>
              <div className="consent-screen__disclaimer">
                <i>
                  Lucet does not condition payment, enrollment, or eligibility
                  for benefits on whether I consent to this authorization. I may
                  grant/revoke this authorization at any time by notifying my
                  provider or Lucet.
                </i>
              </div>
            </div>

            <div className="navigation-buttons-space clear">
              <button
                type="submit"
                className="flt-rgt next-button nav-btn1 nav-btn-nxt next-btn-active"
                id="btn-next"
                name="nav-button"
                value="Next"
                tabIndex="0"
              >
                SUBMIT
              </button>
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}

PatientConsent.contextType = ModalContext;

export default navigationHandler(PatientConsent);
