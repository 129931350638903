const emailStatus = (notificationStatus) =>
  notificationStatus?.individual_status?.email?.status;

const smsStatus = (notificationStatus) =>
  notificationStatus?.individual_status?.sms?.status;

const isNotificationInStateOf = (
  method,
  state,
  notificationStatus,
  { defaultValue } = {},
) => {
  if (method === 'email') {
    const status = emailStatus(notificationStatus);
    if (status) {
      return status === state;
    }
    return defaultValue;
  }
  if (method === 'sms') {
    const status = smsStatus(notificationStatus);
    if (status) {
      return status === state;
    }
    return defaultValue;
  }
  return defaultValue;
};

const isEmailSuccess = (notificationStatus, { defaultValue } = {}) =>
  isNotificationInStateOf('email', 'success', notificationStatus, {
    defaultValue,
  });
const isSmsSuccess = (notificationStatus, { defaultValue } = {}) =>
  isNotificationInStateOf('sms', 'success', notificationStatus, {
    defaultValue,
  });
const isEmailFailed = (notificationStatus, { defaultValue } = {}) =>
  isNotificationInStateOf('email', 'failed', notificationStatus, {
    defaultValue,
  });
const isSmsFailed = (notificationStatus, { defaultValue } = {}) =>
  isNotificationInStateOf('sms', 'failed', notificationStatus, {
    defaultValue,
  });
const isEmailPending = (notificationStatus, { defaultValue } = {}) =>
  isNotificationInStateOf('email', 'pending', notificationStatus, {
    defaultValue,
  });
const isSmsPending = (notificationStatus, { defaultValue } = {}) =>
  isNotificationInStateOf('sms', 'pending', notificationStatus, {
    defaultValue,
  });

// this method returns true if both the email and sms sent
// successfully. The default is set to true which means if
// say any of the field was not filled then that would be
// considered true.
const notificationSent = (notificationStatus) => {
  if (notificationSent) {
    const emailSuccess = isEmailSuccess(notificationStatus, {
      defaultValue: true,
    });
    const smsSuccess = isSmsSuccess(notificationStatus, {
      defaultValue: true,
    });
    return emailSuccess && smsSuccess;
  }
  return false;
};

const getSymbolicBgColorClass = (notificationCommonStatus) => {
  const symbolicColor = notificationCommonStatus?.recommended_symbolic_color;
  switch (symbolicColor) {
    case 'red':
      return 'red-bg';
    case 'green':
      return 'green-bg';
    case 'orange':
      return 'orange-bg';
    default:
      return 'orange-bg';
  }
};

export {
  emailStatus,
  smsStatus,
  isEmailSuccess,
  isSmsSuccess,
  isEmailFailed,
  isSmsFailed,
  isEmailPending,
  isSmsPending,
  notificationSent,
  getSymbolicBgColorClass,
};
