import React from "react";
import CustomHtmlPrompt from "../../../components/inputs/CustomHtmlPrompt";

class AtriumThankyou extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  render() {
    const {
      surveyPresent,
      id,
      thankYou,
      thankyouHtmlSafe,
      thankyouText,
      thankYouText1,
      thankYouText2,
      closeWarning,
      optinThankyou,
      thankYouText3
    } = this.props;
    return (   
        <div id="thank_you">          
          {surveyPresent ?
          <div>   
            <div>
                <div>
                  <h2>{thankYou}</h2>
                  <p><b>{thankyouText && thankyouHtmlSafe}</b></p><br/><br/>
                  <p><CustomHtmlPrompt label={thankYouText1} /><br/><br/>
                  <CustomHtmlPrompt label={thankYouText2} /></p>
                </div>
              </div>
              <p className="close-warning">
               {closeWarning}
             </p>
            <div>
             <div className="click_here">
              { thankYouText3 }
             </div>
             </div>
        </div>
      :
      <div tabIndex="0" dangerouslySetInnerHTML={{__html:optinThankyou}}></div>
      }    
      </div>
    );
  }
}

export default AtriumThankyou