import React from "react"

class TimeOutAlert extends React.Component {
  constructor(props) {
    super(props);

    this.state = {}

  }

  render() {
    const {closeModal, saveModal, organization, timeOutAlertModal} = this.props
    return (
        <div id="dialog-time-out" className="popup" style={{display:'none'}} role="alertdialog" aria-modal="true" aria-labelledby="dialog_time_head" aria-describedby="dialog_time_desc">
          <p id="dialog_time_desc">{timeOutAlertModal.timeOutPromptText}</p>
           <div className="button_section">
             <button id="time-out-close_btn" className="close_btn">
              {timeOutAlertModal.closeBtnText}
             </button>
          </div>
       </div>
    );
  }
}

export default TimeOutAlert