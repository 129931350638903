import React, { useCallback, useMemo } from 'react';
import {
  ReactSelect,
  customStyle as ReactSelectCustomStyle,
} from './ReactSelect';
import wrapField from './wrapField';
import FieldError from './FieldError';
import { noop } from '../../../helperFunctions';

const SingleSelectField = ({
  options,
  placeholder,
  customClass,
  showRequiredLabel,
  disabled,
  valueKey = 'value',
  labelKey = 'label',
  noOptionsMessageString = 'No options',
  lucetTheme,
  field: { value },
  label,
  meta: { touched, error },
  menuPlacement = 'auto',
  helpers: { setValue },
  customStyles = {},
  isClearable = false,
  showError = false,
  onChange = noop,
  addPlaceholderOption = false,
}) => {
  const selected = useMemo(
    () => options.filter((option) => value === option[valueKey]),
    [options, value, valueKey],
  );
  const noOptionsMessage = useCallback(
    () => noOptionsMessageString,
    [noOptionsMessageString],
  );

  const optionsWithPlaceholder = useMemo(() => {
    if (addPlaceholderOption) {
      const placeholderOption = {};
      placeholderOption[labelKey] = placeholder || 'Select options';
      placeholderOption[valueKey] = '';
      return [placeholderOption].concat(options);
    }
    return options;
  }, [options, value, valueKey, labelKey, placeholder]);

  const defaultStyles = addPlaceholderOption
    ? {
        singleValue: (provided, state) => ({
          ...provided,
          color:
            state.data.label === (placeholder || 'Select option')
              ? '#808080'
              : '#333333',
        }),
      }
    : {};

  const customAndDefaultStyles = {
    ...defaultStyles,
    ...customStyles,
  };

  const sanitizedCustomStyles = ReactSelectCustomStyle.sanitizeCustomStyles(
    customAndDefaultStyles,
  );

  return (
    <div
      className={`${error ? 'has-error' : ''} ${
        touched ? 'touched' : ''
      } field-wrapper-inner ${customClass || ''}`}
    >
      <fieldset>
        {label && (
          <legend className="prompt">
            <span>
              {showRequiredLabel ? (
                <span className="required-star">* </span>
              ) : null}
              {label}
            </span>
          </legend>
        )}
        <div className="prompt">
          <ReactSelect
            isClearable={isClearable}
            value={selected}
            onChange={(option) => {
              setValue(option[valueKey]);
              onChange({ value: option[valueKey] });
            }}
            blurInputOnSelect={false}
            options={optionsWithPlaceholder}
            getOptionLabel={(option) => option[labelKey]}
            getOptionValue={(option) => option[valueKey]}
            placeholder={placeholder || 'Select options'}
            className="react-select"
            isDisabled={disabled}
            theme={(theme) => ({
              ...theme,
              borderRadius: 0,
              colors: {
                ...theme.colors,
                primary: lucetTheme ? '#851c55' : '#69ad24',
              },
            })}
            classNamePrefix="custom-react-select"
            noOptionsMessage={noOptionsMessage}
            menuPlacement={menuPlacement}
            styles={sanitizedCustomStyles}
          />

          {showError && <FieldError {...{ error, touched }} />}
        </div>
      </fieldset>
    </div>
  );
};

const FormikSingleSelect = wrapField(SingleSelectField);
export default SingleSelectField;
export { FormikSingleSelect };
