import React from "react"
import DialogModal from "../../../components/DialogModal";
import RegisterForm from "../forms/RegisterForm";
import Thankyou from "../thankyou";
import RegisterMRNForm from "../forms/RegisterMRNForm";
import RegisterPGTForm from "../forms/RegisterPGTForm"
import ConfigureForm from "../forms/ConfigureForm";
import PhoneMatchForm from "../forms/PhoneMatchForm";
import RegisterTPIForm from "../forms/RegisterTPIForm";
import ProtectedNotAuthorizedForm from "../forms/ProtectedNotAuthorizedForm";

class RightPageForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {modal: {}}

  }

  goToSubmitPage = () => {
    const {submitURL} = this.props;
    if (submitURL) {
      window.location.href = submitURL
    }
  }
  
  handleGetStarted = () => {
    const {submitURL, organization} = this.props;
    if (organization.intro_video) {
      this.openModal("DialogModal")
    } else if (submitURL) {
      this.goToSubmitPage()
    }
  }

  openModal = (name) => {
    document.body.classList.add("modal-open");
    this.setState({
      modal: Object.assign({}, this.state.modal, {
        [name]: true,
      })
    });
  };
  
  closeModal = (name) => {
    document.body.className = document.body.className.replace(
      'modal-open',
      ''
    );
    this.setState({
      modal: Object.assign({}, this.state.modal, {
        [name]: false
      }),
    });
  };

  render() {
    const {pageName, welcomeTitle, welcomePrompt, titleClass, wrapperClass, rightSectionTopClass, rightSectionSecondaryClass} = this.props;
    const {modal} = this.state;
    return (
      <div className={`right-page-form ${rightSectionTopClass}`}>
        <div id="content" className={`${rightSectionSecondaryClass}`}>
          <div className={`${wrapperClass}`}>
            {welcomeTitle && (<h2 className={`${titleClass}`}>{welcomeTitle}</h2>)}
            {welcomePrompt && (<p>{welcomePrompt}</p>)}
            
            {(pageName === 'register' || pageName === 'patient_details') && (
              <RegisterForm {...this.props} />
            )}
            {(pageName === 'register_mrn') && (
              <RegisterMRNForm {...this.props} />
            )}
            {pageName === 'thankyou' && (
              <Thankyou {...this.props} />
            )}
            {pageName === 'register_pgt' && (
              <RegisterPGTForm {...this.props} />
            )}
            {pageName === 'configure' && (
              <ConfigureForm {...this.props} />
            )}
            {pageName === 'phone_number_match' && (
              <PhoneMatchForm {...this.props} />
            )}
            {pageName === 'tpi_register_mrn' && (
              <RegisterTPIForm {...this.props} />
            )}
            {pageName === 'protected_not_authorized' && (
              <ProtectedNotAuthorizedForm {...this.props} />
            )}

          </div>
        </div>

        {modal.DialogModal && (
            <DialogModal
              {...this.props}
              closeModal={() => {
                this.closeModal('DialogModal');
                this.goToSubmitPage();
              }}
            />
        )}
      </div>
    );
  }
}

export default RightPageForm