import moment from 'moment';
import React, { useCallback, useMemo } from 'react';
import useWindowSize from '../../../../../hooks/useWindowSize';
import { CheckBox } from '../../../../components/inputs';
import DateField from '../../../../components/inputs/DateField';
import MultiSelectField from '../../../../components/inputs/MultiSelectField';
import FilterWrapper from '../filter_section/FilterWrapper';
import TypeAhead from '../../../../components/inputs/TypeAhead';
import SliderField from '../../../../components/inputs/SliderField';

import styles from '../filter_section/filter_section.module.scss';

const validate = (values) => {
  const errors = {};
  if (values.start_time && !values.end_time) {
    errors.end_time = 'Please enter end time';
  }
  if (!values.start_time && values.end_time) {
    errors.start_time = 'Please enter start time';
  }
  return errors;
};

const FilterSection = ({
  handleSubmit,
  handleReset,
  fieldsData,
  initialValues = {},
  children,
  stringValues,
  headerFilterOpen,
  handleHeaderFilterToggle,
}) => {
  const { windowWidth } = useWindowSize();
  const isMobile = windowWidth < 1235;
  return (
    <FilterWrapper
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validate={validate}
      handleReset={handleReset}
      stringValues={stringValues}
      isFilterOpen={headerFilterOpen}
      setIsFilterOpen={handleHeaderFilterToggle}
      slotsInstance={children}
    >
      {({
        errors,
        touched,
        isValidating,
        isValid,
        setFieldValue,
        values,
        submitForm,
        resetForm,
        handleChange,
      }) => {
        const handleClickClear = useCallback(() => {
          handleReset();
          resetForm();
        }, [handleReset]);
        const onStartDateChange = useCallback(
          (value) => {
            if (
              value &&
              moment(values.end_date, 'MM/DD/YYYY').isBefore(
                moment(value, 'MM/DD/YYYY'),
              )
            ) {
              setFieldValue('end_date', value);
            }
          },
          [values.end_date],
        );
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const minEndDate = useMemo(
          () => moment(values.start_date, 'MM/DD/YYYY').toDate(),
          [values.start_date],
        );
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const minStartDate = useMemo(
          () => moment().add(2, 'days').toDate(),
          [],
        );
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const maxStartDate = useMemo(
          () => moment().add(90, 'days').toDate(),
          [],
        );
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const maxEndDate = useMemo(
          () =>
            moment(values.start_date, 'MM/DD/YYYY').add(90, 'days').toDate(),
          [values.start_date],
        );
        const onStartTimeChange = useCallback(
          (value) => {
            if (
              moment(values.end_time, 'h:mm a').isBefore(
                moment(value, 'h:mm a'),
              )
            ) {
              setFieldValue('end_time', value);
            }
          },
          [values.end_time],
        );
        const onEndTimeChange = useCallback(
          (value) => {
            if (
              moment(value, 'h:mm a').isBefore(
                moment(values.start_time, 'h:mm a'),
              )
            ) {
              setFieldValue('end_time', values.start_time);
            }
          },
          [values.start_time],
        );

        // eslint-disable-next-line react-hooks/rules-of-hooks
        const defaultPatientLocation = useMemo(
          () =>
            values.patient_location
              ? [{ displayString: values.patient_location }]
              : [],
          [values.patient_location],
        );
        const months = Object.values(stringValues.month_full || {});
        const days = Object.values(stringValues.day || {});
        return (
          <React.Fragment>
            <fieldset className={styles.dateRangeWrap}>
              <legend className="prompt">{stringValues['date_range']}</legend>
              <div className="space-between">
                <DateField
                  name="start_date"
                  placeholder="MM/DD/YYYY"
                  onChange={onStartDateChange}
                  minDate={minStartDate}
                  maxDate={maxStartDate}
                  fallback={values.start_date}
                  locale={{
                    localize: {
                      month: (n) => months[n],
                      day: (n) => days[n],
                    },
                    formatLong: {},
                    match: () => null,
                  }}
                />
                &nbsp;&nbsp;{stringValues['to']}&nbsp;&nbsp;
                <DateField
                  name="end_date"
                  placeholder="MM/DD/YYYY"
                  popperPlacement="bottom-end"
                  minDate={minEndDate}
                  maxDate={maxEndDate}
                  fallback={values.end_date}
                  locale={{
                    localize: {
                      month: (n) => months[n],
                      day: (n) => days[n],
                    },
                    formatLong: {},
                    match: () => null,
                  }}
                />
              </div>
            </fieldset>
            <fieldset className={styles.dateRangeWrap}>
              <legend className="prompt">{stringValues.time_range}</legend>
              <div className="flex align-items-start">
                <DateField
                  showTimeSelect
                  showTimeSelectOnly
                  name="start_time"
                  onChange={onStartTimeChange}
                  placeholder="HH:MM"
                  timeIntervals={15}
                  dateFormat="h:mm a"
                  saveFormat="HH:mm:ss[.000]"
                />
                <span className="margin-top-xs">
                  &nbsp;&nbsp;{stringValues.to}&nbsp;&nbsp;
                </span>
                <DateField
                  showTimeSelect
                  showTimeSelectOnly
                  name="end_time"
                  onChange={onEndTimeChange}
                  placeholder="HH:MM"
                  timeIntervals={15}
                  dateFormat="h:mm a"
                  saveFormat="HH:mm:ss[.000]"
                />
              </div>
            </fieldset>

            <fieldset>
              <TypeAhead
                name="patient_location"
                label={stringValues.distance_from}
                placeholder={stringValues.start_typing_address}
                url="/seach_address?q="
                getOptions={(r) => r.data.results}
                valueKey="displayString"
                labelKey={(option) => `${option.displayString}`}
                getValue={(value) => (value[0] ? value[0].displayString : '')}
                defaultOptions={defaultPatientLocation}
                emptyLabel={stringValues.no_matches_found}
                searchText={stringValues.searching}
                helpText={stringValues.enter_two_or_more_chars}
                promptText={stringValues.type_to_search}
                onSelection={(value) => {
                  setFieldValue(
                    'patient_location_state_code',
                    value[0]?.place?.properties?.stateCode,
                  );
                  setFieldValue(
                    'patient_location_longitude',
                    value[0]?.place?.geometry?.coordinates?.[0],
                  );
                  setFieldValue(
                    'patient_location_latitude',
                    value[0]?.place?.geometry?.coordinates?.[1],
                  );
                }}
              />
              <SliderField
                name="distance"
                label=""
                min={0}
                max={5}
                sliderLabels={{
                  0: `1 ${stringValues.mile}`,
                  1: '5',
                  2: '10',
                  3: '20',
                  4: '25',
                  5: '50',
                }}
                sliderValues={{
                  0: '1',
                  1: '5',
                  2: '10',
                  3: '20',
                  4: '25',
                  5: '50',
                }}
                wrapperClassName={styles.slider}
              />
            </fieldset>
            <fieldset>
              <legend className={`${styles.appointmentTypeLabel} prompt`}>
                {stringValues['appointment_type']}
              </legend>
              <div className="flex-start">
                <CheckBox
                  label={stringValues['in_person']}
                  name="in_person"
                  className="switch-checkbox"
                  icon={
                    <i
                      className={`${styles.slotTypeIcon} fa fa-user`}
                      aria-label="in person time slot"
                    />
                  }
                />
                <CheckBox
                  label={stringValues['online']}
                  name="virtual"
                  className="switch-checkbox"
                  icon={
                    <i
                      className={`${styles.slotTypeIcon} fa fa-video-camera`}
                      aria-label="online time slot"
                    />
                  }
                />
              </div>
            </fieldset>

            <MultiSelectField
              label={stringValues['site']}
              placeholder={stringValues['select_one_or_more']}
              name="site_ids"
              options={(fieldsData.sites && fieldsData.sites.options) || []}
              disabled={
                fieldsData.sites && fieldsData.sites.options.length === 1
              }
              noOptionsMessageString={stringValues.no_options}
            />
          </React.Fragment>
        );
      }}
    </FilterWrapper>
  );
};

export default FilterSection;
