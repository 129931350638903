import axios from 'axios';

/**
 * Description
 * @param {String} providerId
 * @param {String} siteId
 * @returns {Object} response from the sever.
 */
// eslint-disable-next-line import/prefer-default-export
export const getSiteDetails = ({
  providerId,
  siteId,
  patientId,
  patientLocation = '',
  patientLocationStateCode,
  dfd = false,
}) => {
  const queryParams = `site_id=${siteId}&patient_id=${patientId}&patient_location=${patientLocation}&patient_location_state_code=${patientLocationStateCode}`;
  let url = `/admin/get-practitioners-details/${providerId}?${queryParams}`;
  if (dfd) {
    url = `/member_self_serve/${providerId}/practitioner_details?${queryParams}`;
  }
  return axios.get(url).then((r) => r.data);
};
