import React, { useCallback } from 'react';
import moment from 'moment/moment';

const AppointmentDetail = ({
  encounter,
  retakeScreener,
  cancellationLimitReached,
  handleCancelAppointment,
  handleRescheduleAppointment,
  styles = {},
}) => {
  const handleCancel = useCallback(() => {
    handleCancelAppointment(
      encounter.id,
      encounter.cancel_on_provider,
      encounter.patient.id,
    );
  }, [handleCancelAppointment, encounter]);

  const handleReschedule = useCallback(() => {
    handleRescheduleAppointment(encounter.id);
  }, [handleRescheduleAppointment, encounter]);

  const startDate = moment(encounter.start_time_patient_timezone);

  return (
    <React.Fragment>
      <div className={styles.appointmentDetail}>
        <div className={styles.appointmentDetailInner}>
          <div className={styles.dateTime}>
            <div className={styles.date}>
              {encounter.day_name} {startDate && startDate.format('MM/DD/YY')}
            </div>
            <div className={styles.time}>
              {startDate && startDate.format('h:mm A')}
            </div>
          </div>
          <div className={styles.typeIconAndVisit}>
            <div className={styles.typeIcon}>
              <img
                className={styles.icon}
                src={`/assets/icons/encounter-${
                  encounter.is_virtual ? 'video' : 'in_person'
                }-individual_white.png`}
                alt="icon"
              />
              <span className={styles.type}>
                {encounter.is_virtual ? encounter.virtual : encounter.in_person}
              </span>
            </div>
            <div className={styles.visitType}>
              <span>{encounter.visit_type}</span>
            </div>
          </div>
          {cancellationLimitReached === false &&
            encounter.cancellation_timeframe_reached === false &&
            encounter.is_accepted !== true &&
            encounter.cancellation_on_provider_failed !== true && (
              <div className={styles.links}>
                <div>
                  <button
                    type="button"
                    onClick={handleCancel}
                    className={styles.cancelButton}
                  >
                    {encounter.cancel_button}
                  </button>
                </div>
                <div>
                  {retakeScreener !== true && (
                    <button
                      type="button"
                      onClick={handleReschedule}
                      className={styles.rescheduleButton}
                    >
                      {encounter.reschedule_button}
                    </button>
                  )}
                </div>
              </div>
            )}
          {encounter.show_cancellation_timeframe === true ? (
            <div className={styles.cancellationBanner}>
              <span
                className={styles.limitNotReached}
                dangerouslySetInnerHTML={{
                  __html: encounter.cancel_reschedule_text,
                }}
              />
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default AppointmentDetail;
