/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';

const Accordion = ({
  panelList,
  title,
  description,
  index = 0,
  customClass,
  closeAccordion,
  accordion_data,
}) => (
  <div className={customClass}>
    <h5 className="main-text inl-blk">{title}</h5>
    <a
      className="close_btn"
      onClick={() => closeAccordion(accordion_data, 'close')}
    />
    <div className="flex">
      <p dangerouslySetInnerHTML={{ __html: description }} />
      <div className="panel-group" id={`parentAccordion${index}`}>
        {panelList.map((list, i) => (
          <div className="panel panel-default" key={list.title}>
            <div className="panel-heading">
              <h4 className="panel-title">
                <a
                  data-toggle="collapse"
                  data-parent={`#parentAccordion${index}`}
                  href={`#childContent${i}${index}`}
                  className="collapsed"
                >
                  {list.title}
                </a>
              </h4>
            </div>
            <div
              id={`childContent${i}${index}`}
              className="panel-collapse collapse"
            >
              <div
                className="panel-body"
                dangerouslySetInnerHTML={{ __html: list.content }}
              >
                {}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  </div>
);

export default Accordion;
