import React from "react";
import CustomHtmlPrompt from "../../../components/inputs/CustomHtmlPrompt";

class ThankyouPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
  }
 
  render() {
    const {
      surveyPresent,
      id,
      thankYou,
      thankyouHtmlSafe,
      thankyouText,
      thankYouText1,
      thankYouText2,
      closeWarning,
      optinThankyou
    } = this.props;
    return (   
          <div id="thank_you">          
          {surveyPresent ?
          <div>   
            <div>
               {thankyouText ?
                <p>
                  {thankyouHtmlSafe}
                </p>
                :
                <div>
                <h2>{thankYou}</h2>
                <p><CustomHtmlPrompt label={thankYouText1} />
                <br/><CustomHtmlPrompt label={thankYouText2} /></p>
                </div>
 }
                </div>
               <p className="close-warning">
               {closeWarning}
             </p>
             <div>
             </div>
             </div>
      :
      <div tabIndex="0" dangerouslySetInnerHTML={{__html:optinThankyou}}></div>
      }    
      </div>
    );
  }
}

export default ThankyouPage