import { useEffect } from 'react';

const usePendoUpdateUrl = (url) => {
  useEffect(() => {
    // eslint-disable-next-line no-undef
    pendo?.location?.setUrl(
      () => `${window.location.protocol}//${window.location.host}/${url}`,
    );
    return () => {
      // eslint-disable-next-line no-undef
      pendo?.location?.useBrowserUrl();
    };
  }, [url]);
};

export default usePendoUpdateUrl;
