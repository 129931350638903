import React, { useCallback, useState } from 'react';
import './breadcrumb.css.scss';
import { useHistory } from 'react-router-dom';

const AppBreadcrumb = ({ crumbs, selected, handleSelectBreadcrumb }) => {
  const isLast = (index) => index === crumbs.length - 1;

  const history = useHistory();
  const [loader, setLoader] = useState(false);
  const goBack = useCallback(() => {
    history.goBack();
  }, [history]);

  const breadCrumbSelected = (breadcrumb) => {
    if (handleSelectBreadcrumb) {
      handleSelectBreadcrumb(breadcrumb);
    } else {
      setLoader(true);
      if (breadcrumb.goBack) {
        goBack();
      } else if (breadcrumb.url) {
        window.location.href = breadcrumb.url;
      }
      setTimeout(() => {
        setLoader(false);
      }, 2000);
    }
  };

  return (
    <React.Fragment>
      {crumbs && crumbs.length > 0 && (
        <nav className="breadcrumb-cont">
          <ul className="breadcrumb">
            {crumbs.map((crumb, ci) => {
              const disabled = isLast(ci) ? 'disabledLink' : '';
              // eslint-disable-next-line no-shadow
              const selected = isLast(ci) ? 'selected' : '';

              return (
                <li key={crumb.tag} className="breadcrumb-item">
                  {/* eslint-disable-next-line react/button-has-type */}
                  <button
                    title={crumb.name}
                    className={`btn btn-link ${disabled} ${selected} ${crumb.tag}`}
                    onClick={() => breadCrumbSelected(crumb)}
                  >
                    {crumb.tag === 'home' ? (
                      <img
                        src="/assets/icons/icon_home_gray.png"
                        alt="home_icon"
                      />
                    ) : (
                      crumb.name
                    )}
                  </button>
                </li>
              );
            })}
          </ul>

          {loader ? (
            <div className="loader">
              <div />
            </div>
          ) : null}
        </nav>
      )}
    </React.Fragment>
  );
};

export default AppBreadcrumb;
