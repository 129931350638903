import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Field, Formik, Form } from 'formik';
import { selectBox } from '../../../../components/inputs';

const BookAppointment = ({
  styles,
  bookingInstructions,
  assessmentButtonTexts,
}) => {
  const [loader, setLoader] = useState(false);
  const [referralInstruction, setReferralInstruction] = useState(null);
  const [referralOptions, setReferralOptions] = useState([]);
  const [referralOption, setReferralOption] = useState(null);
  const [, setTotalInstructions] = useState(0);

  const formRef = useRef();

  useEffect(() => {
    const instruction = bookingInstructions.find(
      (outcome) => outcome.populate === true,
    );
    setReferralInstruction(instruction);
    setTotalInstructions(bookingInstructions.length);
  }, [bookingInstructions]);

  useEffect(() => {
    if (referralInstruction && referralInstruction.selected_option) {
      setReferralOption(referralInstruction.selected_option);
    } else {
      setReferralOption(null);
    }
    if (referralInstruction && referralInstruction.options) {
      setReferralOptions(referralInstruction.options);
    } else {
      setReferralOptions(null);
    }
  }, [bookingInstructions, referralInstruction]);

  const handleAppointmentClick = useCallback(() => {
    setLoader(true);
    if (referralOption && referralOption.booking_url) {
      window.location.href = referralOption.booking_url;
    } else if (referralInstruction && referralInstruction.booking_url) {
      window.location.href = referralInstruction.booking_url;
    }
  }, [referralInstruction, referralOption]);

  return (
    <React.Fragment>
      <div className="book-appointment-cont lucet-brand-filter-section">
        <Formik
          initialValues={{
            referral_instruction_id: referralInstruction
              ? referralInstruction.value
              : '',
            referral_option_id: referralOption ? referralOption.value : '',
          }}
          enableReinitialize
        >
          {/* eslint-disable-next-line no-unused-vars */}
          {({ values, setFieldValue }) => (
            <Form
              aria-label="Multi Referral"
              className="margin-top-md referral-form"
              method="post"
              ref={formRef}
              id="target"
            >
              <div className="form-wrapper">
                <div className="fields-wrapper">
                  <Field
                    onChange={(outcome) => {
                      if (outcome) {
                        const selectedOutcome = bookingInstructions.find(
                          (item) => item.value === outcome,
                        );
                        setReferralInstruction(selectedOutcome);
                      }
                    }}
                    options={bookingInstructions.map((item) => ({
                      label: item.label,
                      value: item.value,
                    }))}
                    classNamePrefix="custom-react-select"
                    lucetTheme
                    name="referral_instruction_id"
                    component={selectBox}
                    menuPlacement="top"
                    disabled={false}
                  />
                  {referralOption && (
                    <Field
                      onChange={(outcome) => {
                        if (outcome) {
                          const selectedOutcome = referralOptions.find(
                            (item) => item.value === outcome,
                          );
                          setReferralOption(selectedOutcome);
                        }
                      }}
                      options={referralOptions}
                      classNamePrefix="custom-react-select"
                      lucetTheme
                      customClass={`referral-option count-${
                        referralOptions && referralOptions.length
                      }`}
                      name="referral_option_id"
                      component={selectBox}
                      menuPlacement="top"
                      disabled={false}
                    />
                  )}
                </div>
                <div className="buttons-wrapper">
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                  <a
                    onClick={handleAppointmentClick}
                    className={`${styles.bookAppointmentButton}`}
                  >
                    {assessmentButtonTexts.book_appointment}
                  </a>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      {loader && (
        <div className="loader">
          <div />
        </div>
      )}
    </React.Fragment>
  );
};

export default BookAppointment;
