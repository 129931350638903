import React from 'react';
import Slots from '../slot/Slots';
import styles from './provider_drill_down.module.scss';

const SlotSection = ({
  slotData,
  stringValues,
  onClickSlot,
  filtersApplied,
  outOfTravelRadius,
}) => (
  <section>
    <h2 className={styles.pickATimeTitle}>{stringValues.pick_a_time}:</h2>
    {slotData && slotData.length > 0 ? (
      slotData.map((dayData) => (
        <section className={styles.daySection} key={dayData.title}>
          <h4 className={styles.dayTitle}>{dayData.title}</h4>
          <Slots
            slots={dayData.timeSlots}
            onClick={onClickSlot}
            stringValues={stringValues}
            filtersApplied={filtersApplied}
            outOfTravelRadius={outOfTravelRadius}
          />
        </section>
      ))
    ) : (
      <i>{stringValues.no_slots_matching_the_filters}.</i>
    )}
  </section>
);

export default SlotSection;
