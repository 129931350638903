import React from 'react';
import style from './progress_bar.module.scss';

const tridiuumTheme = {
  circle1: {
    color: '#5c40a9',
    width: 10,
  },
  circle2: {
    color: '#2f4f0e',
    width: 15,
    colorProgress: '#933c9e',
  },
  circle3: {
    color: '#76c724',
    width: 14,
  },
  circle4: {
    color: '#112B11',
  },
  progressWheelBorder: {
    color: '#686C71',
  },
};
const lucetTheme = {
  circle1: {
    color: '#141E29',
    width: 5,
  },
  circle2: {
    color: '#FFFFFF',
    width: 25,
    colorProgress: '#C7D8EC',
  },
  circle3: {
    color: '#C7D8EC',
    width: 4,
    shadow: 'url(#dropshadow)',
  },
  circle4: {
    color: 'url(#progress-dial-gradient)',
  },
  progressWheelBorder: {
    color: '#686C71',
  },
};

function polarToCartesian(centerX, centerY, radius, angleInDegrees) {
  const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;
  return {
    x: centerX + radius * Math.cos(angleInRadians),
    y: centerY + radius * Math.sin(angleInRadians),
  };
}
function describeArc(x, y, radius, startAngle, endAngle) {
  const endAngleOriginal = endAngle;
  if (endAngle > 358) {
    endAngle = 358;
  }
  const start = polarToCartesian(x, y, radius, endAngle);
  const end = polarToCartesian(x, y, radius, startAngle);
  const arcSweep = endAngle - startAngle <= 180 ? '0' : '1';
  if (endAngleOriginal === 360) {
    return [
      'M',
      start.x,
      start.y,
      'A',
      radius,
      radius,
      0,
      arcSweep,
      0,
      end.x,
      end.y,
      'z',
    ].join(' ');
  }
  return [
    'M',
    start.x,
    start.y,
    'A',
    radius,
    radius,
    0,
    arcSweep,
    0,
    end.x,
    end.y,
  ].join(' ');
}

const getProgressWheelBorderCoordinates = (
  innerCircleRadius,
  wheelThickness,
  angleInDeg,
) => {
  const circleCenter = { x: 0, y: 0 };
  const innerWheelCoord = polarToCartesian(
    circleCenter.x,
    circleCenter.y,
    innerCircleRadius,
    angleInDeg,
  );
  const outerWheelCoord = polarToCartesian(
    circleCenter.x,
    circleCenter.y,
    innerCircleRadius + wheelThickness,
    angleInDeg,
  );
  return {
    basePointRelativeToCenter: innerWheelCoord,
    headPointerRelativeToBase: {
      x: outerWheelCoord.x - innerWheelCoord.x,
      y: outerWheelCoord.y - innerWheelCoord.y,
    },
  };
};

class ProgressBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      themeConfig: props.theme === 'lucet' ? lucetTheme : tridiuumTheme,
    };
  }

  render() {
    const { progress = 0 } = this.props;
    const { themeConfig } = this.state;
    const radius = 120;
    const center = radius;

    const radius1 = radius - themeConfig.circle1.width / 2;
    const radius2 =
      radius1 - themeConfig.circle1.width / 2 - themeConfig.circle2.width / 2;
    const radius3 =
      radius2 - themeConfig.circle2.width / 2 - themeConfig.circle3.width / 2;
    const radius4 = radius3 - themeConfig.circle3.width / 2;

    const innerWheelRadius = radius4 + themeConfig.circle3.width;
    const progressWheelThickness = themeConfig.circle2.width;
    const zeroPercentProgressWheelBorderCoord =
      getProgressWheelBorderCoordinates(
        innerWheelRadius,
        progressWheelThickness,
        0,
      );
    const currentProgressWheelBorderCoord = getProgressWheelBorderCoordinates(
      innerWheelRadius,
      progressWheelThickness,
      progress * 3.6,
    );
    return (
      <div className={style.svgWrapper}>
        <div className={style.svgInnerWrapper}>
          <svg viewBox={`0 0 ${center * 2} ${center * 2}`}>
            <defs>
              <filter id="dropshadow" height="130%">
                <feGaussianBlur in="SourceAlpha" stdDeviation="5" />
                <feOffset dx="0" dy="0" result="offsetblur" />
                <feComponentTransfer>
                  <feFuncA type="linear" slope="0.95" />
                </feComponentTransfer>
                <feMerge>
                  <feMergeNode />
                  <feMergeNode in="SourceGraphic" />
                </feMerge>
              </filter>
              <linearGradient
                id="progress-dial-gradient"
                x1="0%"
                y1="0%"
                x2="0%"
                y2="100%"
              >
                <stop
                  offset="0%"
                  style={{ stopColor: '#141E29', stopOpacity: 1 }}
                />
                <stop
                  offset="50%"
                  style={{ stopColor: '#373f48', stopOpacity: 1 }}
                />
                <stop
                  offset="100%"
                  style={{ stopColor: '#818487', stopOpacity: 1 }}
                />
              </linearGradient>
            </defs>
            <path
              fill="none"
              stroke={themeConfig.circle1.color}
              strokeWidth={themeConfig.circle1.width}
              d={`
                M ${center} ${center}
                m -${radius1}, 0
                a ${radius1},${radius1} 0 1,0 ${radius1 * 2},0
                a ${radius1},${radius1} 0 1,0 -${radius1 * 2},0
              `}
            />
            <path
              fill="none"
              stroke={themeConfig.circle2.color}
              strokeWidth={themeConfig.circle2.width}
              d={`
                M ${center} ${center}
                m -${radius2}, 0
                a ${radius2},${radius2} 0 1,0 ${radius2 * 2},0
                a ${radius2},${radius2} 0 1,0 -${radius2 * 2},0
              `}
            />
            {/* Arc */}
            <path
              fill="none"
              stroke={themeConfig.circle2.colorProgress}
              strokeWidth={themeConfig.circle2.width}
              d={describeArc(center, center, radius2, 0, progress * 3.6)}
            />
            <path
              fill="none"
              stroke={themeConfig.progressWheelBorder.color}
              strokeWidth={themeConfig.circle1.width}
              d={`
                M ${center} ${center}
                m ${zeroPercentProgressWheelBorderCoord.basePointRelativeToCenter.x}, ${zeroPercentProgressWheelBorderCoord.basePointRelativeToCenter.y}
                l ${zeroPercentProgressWheelBorderCoord.headPointerRelativeToBase.x}, ${zeroPercentProgressWheelBorderCoord.headPointerRelativeToBase.y}
              `}
            />
            <path
              fill="none"
              stroke={themeConfig.progressWheelBorder.color}
              strokeWidth={themeConfig.circle1.width}
              d={`
                M ${center} ${center}
                m ${currentProgressWheelBorderCoord.basePointRelativeToCenter.x}, ${currentProgressWheelBorderCoord.basePointRelativeToCenter.y}
                l ${currentProgressWheelBorderCoord.headPointerRelativeToBase.x}, ${currentProgressWheelBorderCoord.headPointerRelativeToBase.y}
              `}
            />
            <path
              fill="none"
              stroke={themeConfig.circle3.color}
              strokeWidth={themeConfig.circle3.width}
              d={`
                M ${center} ${center}
                m -${radius3}, 0
                a ${radius3},${radius3} 0 1,0 ${radius3 * 2},0
                a ${radius3},${radius3} 0 1,0 -${radius3 * 2},0
              `}
              style={{ filter: themeConfig.circle3.shadow }}
            />
            <circle
              cx={center}
              cy={center}
              r={radius4}
              fill={themeConfig.circle4.color}
            />
            <text
              x={center}
              y={center}
              dy={`${radius4 / 10}px`}
              dominantBaseline="middle"
              textAnchor="middle"
              className={style.svgText}
              fontSize={`${radius4 / 1.2}px`}
              fill="#fff"
            >
              {Math.round(progress)}
              <tspan
                dy={`-${radius4 / 5.2}px`}
                fontSize={`${radius4 / 2.6}px`}
                className={style.svgTextSymbol}
              >
                %
              </tspan>
            </text>
          </svg>
        </div>
        {/* <div className={style.percentageText}>
            {progress}
            <span className={style.percentageSymbol}>%</span>
          </div> */}
      </div>
    );
  }
}

export default ProgressBar;
