// @see https://react-select.com/styles#inner-components
/* eslint-disable no-param-reassign */
// eslint-disable-next-line arrow-body-style
const sanitizeCustomStyles = (stylesObject) => {
  return Object.keys(stylesObject).reduce((sanitizedStyles, styleType) => {
    const style = stylesObject[styleType];
    sanitizedStyles[styleType] = (baseStyles, state) => ({
      ...baseStyles,
      ...(typeof style === 'function' ? style(baseStyles, state) : style),
    });
    return sanitizedStyles;
  }, {});
};
/* eslint-disable no-param-reassign */

// Used as a replacement for jquery chosen select. Example: @see Manage User Access modal.
const jqueryChosenSelect = ({
  control = {},
  multiValueLabel = {},
  multiValueRemove = {},
  option = {},
}) => ({
  control: {
    overflow: 'auto',
    padding: '7px',
    cursor: 'pointer',
    ...control,
  },
  multiValueLabel: {
    fontSize: '14px',
    background: '#fff',
    color: '#777',
    padding: '4px 20px 5px 7px',
    border: '1px solid #aaaaaa',
    whiteSpace: 'break-spaces',
    borderRight: 'none',
    paddingRight: '5px',
    cursor: 'default',
    lineHeight: '1',
    ...multiValueLabel,
  },
  multiValueRemove: {
    background: '#fff',
    border: '1px solid #aaaaaa',
    borderLeft: 'none',
    ...multiValueRemove,
  },
  option: {
    fontSize: '14px',
    cursor: 'pointer',
    ...option,
  },
});

const adminFacingLegacyCustomSelect = {
  control: {
    padding: '0 10px !important',
    minHeight: '1.9rem !important',
    borderRadius: '7px !important',
  },
};

export {
  jqueryChosenSelect,
  sanitizeCustomStyles,
  adminFacingLegacyCustomSelect,
};
