/* eslint-disable camelcase */
import React from 'react';
import { useModal } from '../../../../../context/modal';
import CustomHtmlPrompt from '../../../../components/inputs/CustomHtmlPrompt';
import { addPatientLog } from '../../../../../services/patient';

const PauseDialogue = ({
  unSetModal,
  survey_id,
  videoSession,
  stringValues,
  mode,
  patient = {},
  proxy,
  is_proxy,
  locale,
  time_out_hash = { pause_complete_timeout: 20 },
  setLoader, // Do It Later: Move to a context
  rebrand,
  pauseSurvey,
}) => {
  const { confirmDialogue, alertDialogue } = useModal();

  const recordPatientLog = (operation) => {
    addPatientLog(patient?.id, {
      survey_id,
      operation,
    });
  };

  const handleResumeAssessment = () => {
    recordPatientLog('resume_assessment_clicked');
    unSetModal();
  };

  const handlePauseSurvey = async () => {
    recordPatientLog('exit_assessment_clicked');
    // eslint-disable-next-line no-unused-vars
    pauseSurvey().then(async (r) => {
      const redirectionUrl = `/${
        mode === 'home' ? 'time_out' : 'welcome'
      }?locale=${locale}&survey_id=${survey_id}`;
      setTimeout(() => {
        setLoader(true);
        window.location.replace(redirectionUrl);
      }, time_out_hash.pause_complete_timeout * 1000);
      await alertDialogue(
        <div>
          <div>
            <strong>
              {stringValues['thank-you']},{' '}
              {is_proxy && proxy ? proxy.first_name : patient.first_name}.
            </strong>
          </div>
          <br />
          <div>
            {mode === 'home'
              ? stringValues['pause-message-at-home']
              : stringValues['pause-message3']}
          </div>
          <br />
          <div>{stringValues['pause-message4']}</div>
        </div>,
        {
          okText: stringValues['close-button'],
          customProps: { disableLucetTheme: !rebrand },
        },
      );
      setLoader(true);
      window.location.replace(redirectionUrl);
    });
  };

  const handleSkipToScheduling = async () => {
    recordPatientLog('skip_to_scheduling_clicked');
    const response = await confirmDialogue(
      <div>
        <CustomHtmlPrompt label={stringValues.skip_assessment_message} />
      </div>,
      {
        cancelText: stringValues.no_resume_assessment,
        okText: stringValues.yes_book_appointment,
        width: 740,
      },
    );
    if (response) {
      setLoader(true);
      window.location.href = `/surveys/${survey_id}/skip_to_mss_book_appointment`;
    } else {
      unSetModal();
    }
  };

  const handleSkipToVideo = async () => {
    recordPatientLog('skip_to_video_clicked');
    const response = await confirmDialogue(
      <CustomHtmlPrompt label={stringValues.skip_assessment_to_video_body} />,
      {
        cancelText: stringValues.skip_assessment_to_video_resume,
        okText: stringValues.skip_assessment_to_video_start,
        width: 740,
      },
    );
    if (response) {
      setLoader(true);
      window.location.href = `/surveys/${survey_id}/skip_to_video_session`;
    }
  };

  return (
    <div
      id="dialog"
      role="alertdialog"
      aria-modal="true"
      aria-labelledby="dialog_head"
      aria-describedby="dialog_desc"
      className="confirm-dialogue pause-dialog"
    >
      {/* eslint-disable-next-line jsx-a11y/heading-has-content */}
      <h2 style={{ margin: 0 }} id="dialog_head" title="Pause Survey" />
      <div id="dialog_desc" className="confirm-desc">
        {mode === 'dfd' ? (
          <div>{stringValues['pause-message6']}</div>
        ) : (
          <div>
            {stringValues['pause-message1']}
            <br />
            {stringValues['pause-message2']}
          </div>
        )}
      </div>
      <div className="footer-btn-wrap modal-footer">
        {mode === 'dfd' ? (
          <button
            onClick={handleSkipToScheduling}
            className="btn btn-primary"
            type="button"
            style={{ width: 210 }}
          >
            {stringValues.skip_assessment}
          </button>
        ) : (
          <button
            onClick={handlePauseSurvey}
            className="btn btn-primary"
            type="button"
            style={{ width: 200 }}
          >
            {stringValues['save-exit-button']}
          </button>
        )}
        <button
          onClick={handleResumeAssessment}
          className="btn btn-default"
          type="button"
        >
          {mode === 'dfd'
            ? stringValues.resume_assessment
            : stringValues['resume-survey-button']}
        </button>
      </div>
      {videoSession && videoSession.session_allowed && (
        <div style={{ marginBottom: 20, textAlign: 'center' }}>
          <button
            id="skip_button"
            className="skip_text btn btn-small"
            type="button"
            style={{ padding: 3 }}
            onClick={handleSkipToVideo}
          >
            {stringValues.skip_assessment_to_video}
          </button>
        </div>
      )}
    </div>
  );
};

export default PauseDialogue;
