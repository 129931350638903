import QueryString from 'qs';
import { useCallback, useMemo, useState } from 'react';

function filterURL(prefix, value) {
  if (value === '') {
    return undefined;
  }
  return value;
}

const useQueryNormalizer = (path, initialState = {}) => {
  const [formState, setFormState] = useState(initialState);
  const handleSubmit = useCallback((values) => {
    setFormState(values);
  }, []);
  const url = useMemo(
    () =>
      `${path || ''}${QueryString.stringify(formState, {
        filter: filterURL,
        encode: false,
        arrayFormat: 'brackets',
      })}`,
    [path, formState],
  );

  return [url, formState, handleSubmit];
};

export default useQueryNormalizer;
