import React, { useCallback } from 'react';
import {
  reduxForm,
  getFormValues,
  getFormSyncErrors,
  getFormMeta,
  stopSubmit,
  Field,
} from 'redux-form';
import { connect } from 'react-redux';
import axios from 'axios';
import { load as loadAccount } from '../actions/actionCreators';
import { default as CustomField } from './Field';
import { validations, tryProperty, masks } from '../helperFunctions';
import formProvider from './formProvider';
import '../styles/common_styles.scss';
import '../styles/modal_styles.scss';
import Modal from '../shared/modal';
import { checkBoxInline, textFieldInline } from './inputs';

const headers = {
  'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
};

const handleSubmit = (values, dispatch, props) => {
  const { closeModal, setLoader, case_id, optionId, instructionId } = props;
  setLoader(true);
  let formData = {};
  if (values.patient_optout) {
    formData = { patient_optout: values.patient_optout };
  } else {
    formData = values;
  }
  axios({
    method: 'post',
    url: '/admin/referral_home/send_email_sms',
    data: case_id
      ? {
          ...formData,
          case_id,
          referral_option_id: optionId,
          referral_instruction_id: instructionId,
        }
      : {
          ...formData,
          referral_option_id: optionId,
          referral_instruction_id: instructionId,
        },
    headers,
  })
    .then(() => {
      closeModal();
      if (case_id) {
        window.location.reload();
      } else {
        Object.keys(formData).forEach((key) => {
          document.getElementById('target').insertAdjacentHTML(
            'beforeend',
            `<input
                type="hidden"
                className=${key}
                name=${key}
                value='${values[key]}'
              />`,
          );
        });
        document.getElementById('target').submit();
      }
    })
    .catch((err) => {
      setLoader(false);
      const msg =
        err.response.data && err.response.data.message
          ? err.response.data.message
          : err;
      alert(msg);
    });
};

let InitializeFromStateForm = ({
  handleSubmit,
  submitting,
  formValues = {},
  closeModal,
  change,
  invalid,
  locale,
  organizationStatus,
  sendAssetsText,
  customProps,
}) => {
  const emailValidation = useCallback(
    (value) =>
      formValues.email_checkbox &&
      (validations.required(value, locale) || validations.email(value, locale)),
    [formValues.email_checkbox, locale],
  );
  const phoneValidation = useCallback(
    (value) =>
      formValues.sms_checkbox &&
      (validations.required(value, locale) || validations.phone(value, locale)),
    [formValues.sms_checkbox, locale],
  );

  const { title, subtitle, subtitle2, formLabels } = sendAssetsText;
  const fields = {
    patient_optout: {
      label:
        tryProperty(formLabels, 'patientOptoutText') ||
        'Patient opted out of communication',
      type: 'checkbox',
      name: 'patient_optout',
    },
  };
  return (
    <Modal closeModal={closeModal} customProps={customProps}>
      <div className="modal-header">
        <h4 id="dialog_head" className="modal-title vertically-center">
          {title || 'Send Digital Assets'}
        </h4>
      </div>
      <form onSubmit={handleSubmit}>
        <label htmlFor="save-changes">{subtitle}</label>
        <p className="text-5 margin-top-md">{subtitle2}</p>
        <div className="flex-column margin-top-lg">
          <div className="cell w-100">
            <div className="no-label">
              <div className="space-between">
                <Field
                  name="email_checkbox"
                  component={checkBoxInline}
                  label={
                    tryProperty(formLabels, 'sendViaEmail') || 'Send via email'
                  }
                  disabled={formValues.patient_optout}
                />
                <Field
                  placeholder={
                    tryProperty(formLabels, 'enterEmail') || 'Enter email'
                  }
                  name="email"
                  component={textFieldInline}
                  type="text"
                  // className="ip-small-packed"
                  style={{ width: 300 }}
                  normalize={masks.email}
                  disabled={
                    formValues.patient_optout || !formValues.email_checkbox
                  }
                  validate={[emailValidation]}
                  // validate={[(val) => validations.email(val, locale)]}
                />
              </div>
            </div>
          </div>

          {organizationStatus && (
            <div className="cell w-100">
              <div className="no-label">
                <div className="space-between">
                  <Field
                    name="sms_checkbox"
                    component={checkBoxInline}
                    label={
                      tryProperty(formLabels, 'sendViaSmsTxtMsg') ||
                      'Send via SMS text message'
                    }
                    disabled={formValues.patient_optout}
                  />
                  <Field
                    placeholder={
                      tryProperty(formLabels, 'enterPhoneNumber') ||
                      'Enter phone number'
                    }
                    name="sms"
                    component={textFieldInline}
                    type="text"
                    // className="ip-small-packed"
                    style={{ width: 300 }}
                    normalize={masks.phone}
                    disabled={
                      formValues.patient_optout || !formValues.sms_checkbox
                    }
                    validate={[phoneValidation]}
                    // validate={[(val) => validations.phone(val, locale)]}
                  />
                </div>
              </div>
            </div>
          )}

          <CustomField
            change={change}
            formValues={formValues}
            fieldData={fields.patient_optout}
            onChange={(event, newValue) => {
              if (newValue === true || newValue === 'true') {
                change('email_checkbox', false);
                change('sms_checkbox', false);
              }
            }}
          />
        </div>
        <div className="modal-footer">
          <div>
            <button
              type="button"
              className="btn btn-default"
              name="nav-button"
              onClick={() => closeModal()}
            >
              {tryProperty(formLabels, 'cancel') || 'Cancel'}
            </button>
            <button
              type="submit"
              id="save-changes"
              className="btn btn-primary"
              disabled={
                invalid ||
                submitting ||
                (formValues &&
                  !formValues.patient_optout &&
                  !formValues.email_checkbox &&
                  !formValues.sms_checkbox)
              }
            >
              {formValues.patient_optout
                ? tryProperty(formLabels, 'done') || 'Done'
                : tryProperty(formLabels, 'send') || 'Send'}
            </button>
          </div>
        </div>
      </form>
    </Modal>
  );
};

InitializeFromStateForm = reduxForm({
  form: 'modalForm', // a unique identifier for this form
  onSubmit: handleSubmit,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(InitializeFromStateForm);

InitializeFromStateForm = connect(
  (state, ownProps) => ({
    initialValues: ownProps.initialValues, // pull initial values from account reducer
    stopSubmit,
    formValues: getFormValues('modalForm')(state),
    synchronousErrors: getFormSyncErrors('modalForm')(state),
    meta: getFormMeta('modalForm')(state),
  }),
  { load: loadAccount }, // bind account loading action creator
)(InitializeFromStateForm);

export default formProvider(InitializeFromStateForm);
