/* eslint-disable camelcase */
import axios from 'axios';
import { delay } from '../../helperFunctions';

const createVisitCancellationRequestTransaction = ({
  encounterId,
  patientId, // optional - only for mss
  mode,
}) =>
  axios
    .get(`/call_centers/create_visit_cancellation_request_transaction`, {
      params: {
        encounter_id: encounterId,
        patient_id: patientId, // optional - only for mss
        initiated_from: mode === 'call_center' ? 'Tridiuum ONE Connect' : '',
      },
    })
    .then((response) => response.data);

const createSlotBookRequestTransaction = ({
  survey_id,
  patient_id, // only for mss
  slot_id,
  visit_type_id,
  start_date,
  end_date,
  duration,
  site_id,
  is_virtual,
  timezone,
  phone,
  email,
  comments,
  callCenterEncounterId,
}) =>
  axios
    .get(`/call_centers/create_slot_book_request_transaction`, {
      params: {
        survey_id,
        patient_id, // only for mss
        slot_id,
        visit_type_id,
        start_date,
        end_date,
        duration,
        site_id,
        is_virtual,
        timezone,
        phone,
        email,
        notes: comments,
        call_center_encounter_id: callCenterEncounterId,
      },
    })
    .then((response) => response.data);

const confirmTransactionByPolling = ({
  transactionId,
  patientId, // optional - only for mss
  pollingTimeout = 120, // 2 minute
}) => {
  const startingTime = Date.now();
  const pollingCall = () =>
    axios
      .get(`/call_centers/transaction_confirmation`, {
        params: {
          transaction_id: transactionId,
          patient_id: patientId, // only for mss
        },
      })
      .then((response) => {
        if (response.data.status === 'success') {
          return response.data;
        }
        if (
          Date.now - startingTime > pollingTimeout * 1000 ||
          response.data.status === 'failed'
        ) {
          throw new Error('Transaction confirmation failed');
        }
        return delay(50).then(() => pollingCall());
        // await delay(50);
        // const result = await pollingCall(transaction_id);
        // return result;
      });

  return pollingCall();
};

export {
  createVisitCancellationRequestTransaction,
  createSlotBookRequestTransaction,
  confirmTransactionByPolling,
};
