import React from 'react';
import clsx from 'clsx';
import PronounBadge from '../../../../../shared/PronounBadge';

import styles from './provider_block.module.scss';

const ProviderMediaBlock = ({
  provider,
  bestMatch,
  stringValues,
  selectedSiteId,
  hasMultipleSites,
  selectedSite,
  changeSelectedSiteId,
  displayMatch = false,
}) => (
  <div className="media-block">
    <div className={`${styles.profPic} media-block__media`}>
      <div
        className="image"
        style={{ backgroundImage: `url(${provider.profile_pic_url})` }}
      />
    </div>
    <div className="media-block__content">
      <div className={`media-block ${styles.mediaDetail}`}>
        <div className="media-block__content">
          <h1 className={styles.mediaTitle}>{provider.name}</h1>
          <div className={styles.mediaMeta}>
            <div>{provider.suffix}</div>
            <div className="flex align-center">
              <span className="margin-right-sm">
                {provider.sex &&
                  (typeof provider.sex === 'object'
                    ? provider.sex.name
                    : provider.sex)}
              </span>
              {provider.pronoun && (
                <PronounBadge
                  pronoun={provider.pronoun}
                  theme="dark"
                  tooltipPlacement="bottom"
                />
              )}
            </div>
          </div>
          <div className={styles.location}>
            {provider.sites_with_open_slots?.length > 1 && (
              <select
                className={clsx('select-site', styles.locationSelect)}
                value={selectedSiteId}
                onChange={(e) => changeSelectedSiteId(e.target.value)}
              >
                {provider.sites_with_open_slots.map((site) => (
                  <option
                    key={site.id}
                    value={site.id}
                    label={`Location: ${site.name}`}
                  >
                    {site.name}
                  </option>
                ))}
              </select>
            )}
            {provider.intake_appt_role !== 'coordinator' &&
              (!hasMultipleSites
                ? selectedSite
                  ? !selectedSite.hide_address &&
                    selectedSite.site_address_lines.join(', ')
                  : [provider.address_line_1, provider.address_line_2]
                      .filter((n) => n)
                      .join(', ')
                : stringValues.multiple_locations_available)}
          </div>
          {
            // provider.phone_number is the slot site phone number, see CallCenter::ProviderListing#set_dfd_data
            !hasMultipleSites && (
              <div className={styles.mediaMeta}>
                {selectedSite?.phone_number
                  ? selectedSite?.phone_number
                  : provider.phone_number}
              </div>
            )
          }
        </div>
        {displayMatch && (
          <div className={`${styles.matchWrap} media-block__media`}>
            <div className={styles.matchText}>
              {stringValues.match}:{' '}
              {provider.strength ? `${provider.strength}%` : 'N/A'}
            </div>
            {bestMatch && (
              <span className={styles.bestMatchPill}>
                {stringValues.best_match}
              </span>
            )}
          </div>
        )}
      </div>
    </div>
  </div>
);

export default ProviderMediaBlock;
