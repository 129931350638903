import React from 'react';
import CustomHtmlPrompt from "../../../components/inputs/CustomHtmlPrompt";

class Notify extends React.Component {

  render() {
    const {welcomeTitle, welcomePrompt, titleClass, wrapperClass, rightSectionTopClass, rightSectionSecondaryClass} = this.props;
    return (
      <div className={`notify-cont`}>
        <div className={`${rightSectionTopClass}`}>
          <div id="content" className={`${rightSectionSecondaryClass}`}>
            <div className={`${wrapperClass}`}>
              <h2 className={`${titleClass}`}><CustomHtmlPrompt label={welcomeTitle} /></h2>
              <p>{welcomePrompt}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Notify;
