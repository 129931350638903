import React from 'react';
import {BrowserRouter as Router, Route} from 'react-router-dom';
import ThankyouPage from './ThankyouPage';
import TPIThankyou from './TPIThankyou';
import HomeThankyou from './HomeThankyou';
import BeaconThankyou from './BeaconThankyou';
import AtriumThankyou from './AtriumThankyou';
import PostThankyou from './PostThankyou';
import './style.scss';
import axios from "axios";

class Thankyou extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: {},
      nextButtonClass: 'display-none',
      data: props
    }
  }

  completeSurvey = (redirect) => {
    const {id, idleThankYouURL} = this.props;
    if (id) {
      this.props.handleUnloadUpdates(false);
      return axios({
        url: `/surveys/${id}/complete`,
        method: "GET",
        params: {trigger: "submit"}
      })
        .then(res => {
          if (redirect && idleThankYouURL) {
            setTimeout(() => {
              window.location.href = idleThankYouURL;
            }, 1000);
          }
        });
    }
  }

  timedOut = () => {
    const {idleThankYouURL, surveyObject, surveyDefinitionObject, checkAllowResponseEdit} = this.props;
    if (idleThankYouURL) {
      this.props.handleUnloadUpdates(false);
      if (checkAllowResponseEdit) {
        if (surveyObject && surveyDefinitionObject && surveyDefinitionObject.allow_response_edit) {
          if (surveyDefinitionObject.asynchronous_submission) {
            this.completeSurvey(true);
          } else {
            window.location.href = `/surveys/${surveyObject.id}/complete?trigger=timeout`;
          }
        } else {
          window.location.href = idleThankYouURL;
        }
      } else {
        window.location.href = idleThankYouURL;
      }
    }
  }

  componentDidMount() {
    const {completeSurveyOnLoad} = this.props;
    this.props.handleUnloadUpdates(true);
    if (completeSurveyOnLoad) {
      this.completeSurvey(false);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.hasTimedOut === false && this.props.hasTimedOut === true) {
        this.timedOut();
    }
  }

  render() {
    return (
      <div className={`thankyou-presentation`}>
        <Router>
          <Route path={["/thankyou"]} render={(props) => (
            <ThankyouPage {...props} {...this.state.data}/>
          )}/>

          <Route path={["/tpi/thankyou", "/nw/thankyou", "/satss/thankyou", "/lifestance/thankyou"]}
                 render={(props) => (
                   <TPIThankyou {...props} {...this.state.data}/>
                 )}/>

          <Route path={["/home/thankyou"]} render={(props) => (
            <HomeThankyou {...props} {...this.state.data}/>
          )}/>
          <Route path={["/beacon/thankyou"]} render={(props) => (
            <BeaconThankyou {...props} {...this.state.data}/>
          )}/>
          <Route path={["/atrium/thankyou"]} render={(props) => (
            <AtriumThankyou {...props} {...this.state.data}/>
          )}/>
          <Route path={["/post/thankyou"]} render={(props) => (
            <PostThankyou {...props} {...this.state.data}/>
          )}/>
        </Router>
      </div>
    );
  }
}

export default Thankyou;
