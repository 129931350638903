import React from 'react';
import style from '../../shared/modal/modal.module.scss';
import { noop } from '../../helperFunctions';

export const loaderMessages = [
  'We are booking the appointment, this may take a few moments…',
  'We are sending the booking confirmation to the patient…',
  'We are confirming that the booking confirmation was sent…',
  'Finishing the appointment booking process…',
];
export const referralLoaderMessages = [
  'We are sending the patient referral, this may take a few moments…',
  'We are sending the patient information to partner organization…',
  'We are confirming that the patient referral was received by partner organization…',
  'Finishing the referral process…',
];

const RequestLoader = React.forwardRef(
  (
    {
      requestCount,
      customHeaderMessage,
      loaderMessage,
      customClassName,
      referral,
      showCloseButton = false,
      closeLoader = noop,
      customStyles = {},
    },
    ref,
  ) => {
    const messages = referral ? referralLoaderMessages : loaderMessages;
    return (
      <div
        ref={ref}
        className={
          customClassName ||
          customStyles.bookAppointmentLoader ||
          'book-appointment-loader'
        }
      >
        <div className={customStyles.loaderContent || 'loader-content'}>
          <div className={customStyles.loaderHeader || 'loader-header'}>
            {showCloseButton && (
              <div className={style.closeButtonWrapper}>
                <button
                  onClick={closeLoader}
                  type="button"
                  className={`${style.closeButton} ${style.closeButtonInLoader}`}
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            )}
            <h4>{customHeaderMessage || 'Processing Request'}</h4>
          </div>

          <div className={customStyles.loaderBody || 'loader-body'}>
            <div className={customStyles.loaderSpinner || 'loader-spinner'} />
            <p
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html:
                  requestCount >= 0
                    ? requestCount > 3
                      ? messages[3]
                      : messages[requestCount]
                    : loaderMessage,
              }}
            />
          </div>
        </div>
      </div>
    );
  },
);

export default RequestLoader;
