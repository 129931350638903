import axios from 'axios';

// eslint-disable-next-line import/prefer-default-export
export const geocodeAddress = (location) =>
  axios
    .get(`/geocode_address?location=${encodeURIComponent(location)}`)
    .then((r) => {
      const { data } = r;
      const { street, adminArea5, adminArea3, adminArea1, postalCode } = data;
      return {
        ...data,
        displayString: [
          street,
          adminArea5,
          [adminArea3, postalCode].filter(Boolean).join(' '),
          adminArea1,
        ]
          .filter(Boolean)
          .join(', '),
      };
    });
