import axios from 'axios';
import { csrfHeader } from '../../helperFunctions';

const sendMssLink = ({
  patientId,
  surveyId,
  values: { email_checkbox, sms_checkbox, email, sms },
}) =>
  axios({
    method: 'post',
    url: '/member_self_serve',
    data: {
      patient_id: patientId,
      survey_id: surveyId,
      email: email_checkbox ? email : null,
      sms: sms_checkbox ? sms : null,
    },
    headers: csrfHeader,
  }).then(({ data: memberSelfServeRecord }) => memberSelfServeRecord);

const getMssNotificationStatus = ({ mssId, email, sms, isMemberPortal }) =>
  axios
    .get(`/member_self_serve/${mssId}/fetch_patient_notification_status`, {
      params: {
        email,
        sms,
        member_portal: isMemberPortal,
      },
    })
    .then(({ data: notificationStatus }) => notificationStatus);

export { sendMssLink, getMssNotificationStatus };
