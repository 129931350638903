import React from 'react';
import Appointment from './Appointment';

const UpcomingAppointments = ({
  upcomingAppointments,
  retakeScreener,
  cancellationLimitReached,
  handleCancelAppointment,
  handleRescheduleAppointment,
  patientLocation,
  styles = {},
  expired,
  noAppointmentsTexts,
  showSendLinkForm = false,
  showActionButtons,
}) => (
  <div
    className={styles.appointmentsWrapper}
    style={showSendLinkForm ? { overflowY: 'initial' } : {}}
  >
    {(expired !== true || showSendLinkForm) &&
      (!showSendLinkForm &&
      upcomingAppointments &&
      upcomingAppointments.length > 0 ? (
        upcomingAppointments &&
        upcomingAppointments.map((encounter) => (
          <div className={styles.appointment} key={encounter.id}>
            <Appointment
              encounter={encounter}
              retakeScreener={retakeScreener}
              cancellationLimitReached={cancellationLimitReached}
              handleCancelAppointment={handleCancelAppointment}
              handleRescheduleAppointment={handleRescheduleAppointment}
              patientLocation={patientLocation}
              showActionButtons={showActionButtons}
              styles={styles}
            />
          </div>
        ))
      ) : (
        <div
          className={styles.noAppointmentsContainer}
          style={showSendLinkForm ? { overflowY: 'initial' } : {}}
        >
          <div className={styles.noAppointmentsSubContainer}>
            {noAppointmentsTexts?.map((textDatum, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <p className={styles[textDatum.style]} key={index}>
                {textDatum.text}
              </p>
            ))}
          </div>
        </div>
      ))}
  </div>
);

export default UpcomingAppointments;
