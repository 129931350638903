import React from "react"
import DialogModal from "../../../components/DialogModal";
import {Formik, Form} from 'formik';
import {SelectField, TextField} from "../../../components/inputs";
import {masks, validations, csrfHeader} from "../../../../helperFunctions";
import {formValidations} from "../../../helpers/moduleHelper";
import axios from 'axios';

class ConfigureForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {surveyDefinitionOptions: [], showNextButton: false}

  }

  handleSubmit = (values, url) => {
    const {pageName, submitURL, patientAutoCreate, mrn, setLoader} = this.props;
    setLoader(true);
    return axios({
      method: 'post',
      url: url,
      data: values,
      headers: csrfHeader,
    })
      .then(response => {
        if (response.status === 200 || response.status === 302) {
          window.location.href = response.request.responseURL;
        }
      })
      .catch(err => {
        setLoader(false);
        const msg =
          err.response.data && err.response.data.message
            ? err.response.data.message
            : err;
        alert(msg);
      });
  }

  handleSiteChange = (site_id) => {
    const {setLoader} = this.props;
    setLoader(true);
    return axios({
      method: 'get',
      url: 'get-survey-definitions',
      params: { site_id : site_id },
      headers: csrfHeader,
    })
      .then(response => {
        setLoader(false);
        if (response && response.data) {
           var surveyDefinitions = [];
           response.data.forEach(surveyDefinition => {
              surveyDefinitions.push({label: surveyDefinition[1], value: surveyDefinition[0]})
           })
          this.setState({surveyDefinitionOptions: surveyDefinitions})
        }
      })
      .catch(err => {
        setLoader(false);
        const msg =
          err.response.data && err.response.data.message
            ? err.response.data.message
            : err;
        alert(msg);
      });
  }

  validate = (values) => {
    const {locale} = this.props;
    const fieldErrorMessage = formValidations.fieldErrorMessage(locale);
    let errors = {};
    if (!values.site) {
      errors.site = fieldErrorMessage;
    }
    this.setState({showNextButton: Object.keys(errors).length === 0})
    return errors;
  }

  render() {
    const {
      pageName, locale, siteOptions, submitURL
    } = this.props;
    const {surveyDefinitionOptions, showNextButton} = this.state;
    return (
    <div className="configure-cont">
        <Formik
          initialValues={{
            site: '',
            survey_definition: '',
          }}
          onSubmit={(values, {setSubmitting}) => {
            setTimeout(() => {
              this.handleSubmit(values, submitURL).then(r => {

              })
              setSubmitting(false);
            }, 400);
          }}
          validate={this.validate}
        >
          {props => (
            <Form className="user_match" aria-label="Configure form">
              <div className={`rows`}>
                <div className="row clear">
                  <div className="form-group col-md-5 col-sm-12" aria-labelledby="site">
                    <SelectField
                      name="site"
                      className={`form-control input`}
                      placeholder={`Select a Site`}
                      options={(siteOptions) || []}
                      onChange={(e) => {
                        props.setFieldValue('survey_definition', '');
                        props.handleChange(e);
                        const value = e.target.value;
                        if (formValidations.isNotNull(value)) {
                          this.handleSiteChange(value)
                        } else {
                          this.setState({surveyDefinitionOptions: []})
                        }
                      }}
                    />
                  </div>
                </div>

                <div className={`row clear`}>
                  <div className="form-group col-md-5 col-sm-12" aria-labelledby="survey_definition">
                    <SelectField
                      name="survey_definition"
                      className={`form-control input`}
                      placeholder={`Select a Survey`}
                      options={(surveyDefinitionOptions) || []}
                    />
                  </div>
                </div>

              </div>
              <div className="navigation-buttons-space clear">
                {showNextButton && (
                  <button type="submit" className={`btn-next flt-rgt sub-form`} name=" action" id="
                                    btn-next">{`Submit`}</button>
                )}
              </div>
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}

export default ConfigureForm