import React from "react"
import DialogModal from "../../../components/DialogModal";
import {Formik, Form, Field} from 'formik';
import {TextField, SelectField, DateTextField} from "../../../components/inputs";
import {masks, validations, csrfHeader} from "../../../../helperFunctions";
import {formValidations} from "../../../helpers/moduleHelper";
import bowser from "bowser";
import axios from 'axios';

class RegisterPGTForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {modal: {}, showNextButton: false}

  }

  handleSubmit = (values, url) => {
    const {submitURL, setLoader} = this.props;
    setLoader(true);
    url += bowser.msie ? '.json' : '';
    return axios({
      method: 'post',
      url: url,
      data: values,
      headers: csrfHeader,
    })
      .then(response => {
        if (response.status === 200 || response.status === 302 || response.data.location) {
          window.location.href = response.data.location || response.request.responseURL;
        }
      })
      .catch(err => {
        setLoader(false);
        const msg =
          err.response.data && err.response.data.message
            ? err.response.data.message
            : err;
        alert(msg);
      });
  }

  goToWelcomePage = () => {
    window.location.href = "/welcome"
  }

  handleBackButton = () => {
    const {backPath} = this.props;
    window.location.href = backPath ? backPath : "/welcome";
  }

  openModal = (name) => {
    const {setLoader} = this.props;
    setLoader(false);
    document.body.classList.add("modal-open");
    this.setState({
      modal: Object.assign({}, this.state.modal, {
        [name]: true,
      })
    });
  };

  closeModal = (name) => {
    document.body.className = document.body.className.replace(
      'modal-open',
      ''
    );
    this.setState({
      modal: Object.assign({}, this.state.modal, {
        [name]: false
      }),
    });
  };

  validate = (values) => {
    const {locale} = this.props;
    const fieldErrorMessage = formValidations.fieldErrorMessage(locale);
    let errors = {};
    if (!values.group_id) {
      errors.group_id = fieldErrorMessage;
    } else {
      this.props.handleUnloadUpdates(true);
    }
    
    this.setState({showNextButton: Object.keys(errors).length === 0})
    return errors;
  }

  render() {
    const {
      organization, site, patient_id, counselorsLabel, counselorsValue, counselorsPlaceholder,
      counselorsOptions, nextButtonLabel, backButtonLabel, submitURL
    } = this.props;
    const {modal, showNextButton} = this.state;
    return (
      <div>
        <Formik
          initialValues={{
            org_id: organization && organization.id,
            site_id: site && site.id,
            patient_id: patient_id,
            group_id: (formValidations.isNotNull(counselorsValue) === true) ? counselorsValue : ''
          }}
          onSubmit={(values, {setSubmitting}) => {
            this.props.handleUnloadUpdates(false);
            setTimeout(() => {
              this.handleSubmit(values, submitURL).then(r => {

              })
              setSubmitting(false);
            }, 400);
          }}
          validateOnMount={true}
          validate={this.validate}
        >
          {({errors, touched, isValidating, setFieldValue}) => (
            <Form className="user_match">
              <div className={`rows`}>
                {counselorsOptions && (
                  <div className="form-group row clear">
                    <div className="col-lg-8 col-md-8 col-sm-12">
                      <SelectField
                        label={counselorsLabel}
                        name="group_id"
                        className={`form-control input`}
                        placeholder={counselorsPlaceholder}
                        options={(counselorsOptions) || []}
                      />
                    </div>
                  </div>
                )}
              </div>
              <div className="navigation-buttons-space clear">
                {showNextButton && (
                  <button type="submit" className={`btn-next flt-rgt sub-form`} name=" action" id="
                                                    btn-next">{nextButtonLabel}</button>
                )}
                <button type="button" onClick={this.handleBackButton}
                        className={`btn-back flt-lft`}>{backButtonLabel}</button>
              </div>
            </Form>
          )}
        </Formik>
        {modal.DialogModal && (
          <DialogModal
            {...this.props}
            closeModal={() => {
              this.closeModal('DialogModal');
              this.goToWelcomePage();
            }}
            saveModal={() => {
              this.closeModal('DialogModal');
              this.goToWelcomePage();
            }}
          />
        )}
      </div>
    );
  }
}

export default RegisterPGTForm