import React from 'react';
import Slider from 'react-rangeslider';
import 'react-rangeslider/lib/index.css';
import Prompt from './Prompt';
import wrapField from './wrapField';

const SliderField = ({
  min = 0,
  max = 0,
  field,
  label,
  meta: { touched, error },
  helpers: { setValue },
  sliderLabels,
  sliderValues = sliderLabels,
}) => (
  <div>
    <Prompt {...{ label }} />
    <div>
      <div className="slider-wrap">
        <Slider
          min={Number(min)}
          max={Number(max)}
          value={
            (sliderValues
              ? Object.values(sliderValues).indexOf(field.value.toString())
              : field.value) || 0
          }
          onChange={(value) => {
            setValue(sliderValues ? sliderValues[value] : value);
          }}
          labels={sliderLabels}
          format={() => field.value}
        />
      </div>

      {touched && error && <span className="error">{error}</span>}
    </div>
  </div>
);

export default wrapField(SliderField);
