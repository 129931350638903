import React from 'react';

class Timer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      counter: props.time,
    };
  }

  componentDidMount() {
    this.timer = setInterval(this.updateCounter, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  updateCounter = () => {
    this.setState((prevState) => ({
      counter: prevState.counter - 1,
    }));
  };

  render() {
    const { counter } = this.state;
    return counter >= 0 ? counter : 0;
  }
}

export default Timer;
