import React from "react"
import CustomHtmlPrompt from "../../../components/inputs/CustomHtmlPrompt";


class HomeThankyou extends React.Component {
  constructor(props) {
    super(props);
    this.state = {modal: {}, nextButtonClass: 'display-none'}
  }

  render() {
    const {
        survey,
        videoSessionPresent,
        videoSessionAllowed,
        spanishVideoBtn,
        thankYouBody,
        surveyPresent,
        thankyouText,
        thankyouHtmlSafe,
        optinThankyou,
        videoSessionPath
    } = this.props;
    
    return (
      <div id="tpi_thank_you"> 
      {surveyPresent ?
      <div id="thank_you">     
      <div className="page-inner-content" role="main">
      {thankyouText &&
      <div>
          <p className="tpi_text_head">
            <CustomHtmlPrompt label={thankyouHtmlSafe} />
          </p>
          <p className="tpi_text"><CustomHtmlPrompt label={thankYouBody} /></p>
          </div>
         }
         {(survey && videoSessionPresent && videoSessionAllowed) &&       
            <div class="button-wrapper">
            <a id="vid-session-initiation-button" className="btn btn-primary" href={videoSessionPath}>{spanishVideoBtn}</a>             
            </div>
        }
      </div>      
    </div>
    :
    <div tabIndex="0" dangerouslySetInnerHTML={{__html:optinThankyou}}></div>
    }
   </div>
    );
  }
}

export default HomeThankyou