import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import axios from 'axios';
import wrapField from './wrapField';
import Prompt from './Prompt';
import FieldError from './FieldError';

// Removing the 'Hint' component temporarily due to a bug in the library.
// https://github.com/ericgio/react-bootstrap-typeahead/issues/761
const customRenderInput = ({
  inputRef,
  referenceElementRef,
  ...inputProps
}) => (
  <input
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...inputProps}
    ref={(node) => {
      inputRef(node);
      referenceElementRef(node);
    }}
  />
);

const TypeAhead = (props) => {
  const {
    field,
    meta: { error, touched },
    helpers: { setValue },
    placeholder = 'Start typing...',
    label,
    labelClass,
    showRequiredLabel,
    additionalInfo,
    moreText,
    disabled,
    url,
    filterBy = () => true,
    getOptions,
    labelKey,
    valueKey = 'id',
    multiple,
    defaultOptions,
    getValue,
    onSelection,
    emptyLabel = 'No matches found.',
    searchText = 'Searching...',
    showHelpText = true,
    helpText = 'Please enter 2 or more characters',
    promptText = 'Type to search...',
    showError = true,
    baseMinCharacters = 2,
  } = props;

  const [query, setQuery] = useState(
    defaultOptions && defaultOptions[0] ? labelKey(defaultOptions[0]) : '',
  );
  const [options, setOptions] = useState(defaultOptions || []);
  const [isLoading, setIsLoading] = useState(false);
  const [hasFocus, setHasFocus] = useState(false);
  const triggersForEmptyStrings = baseMinCharacters === 0;
  const [actualPromptText, setActualPromptText] = useState(
    triggersForEmptyStrings ? searchText : promptText,
  );

  useEffect(() => {
    if (!query) {
      setQuery(
        defaultOptions && defaultOptions[0] ? defaultOptions[0][labelKey] : '',
      );
    }
  }, [defaultOptions, labelKey, query]);

  // Re-setting to the default option when setting the value from outside. eg: clear form.
  useEffect(() => {
    if (!(defaultOptions && defaultOptions[0])) return;

    const defaultValue =
      (getValue ? getValue(defaultOptions) : defaultOptions[0].id) || '';
    if (field.value === defaultValue) {
      setOptions(defaultOptions);
    }
  }, [field.value]);

  const selected = useMemo(
    () =>
      options.filter(
        (option) => field.value && field.value.includes(option[valueKey]),
      ),
    [options, field.value, valueKey],
  );

  const handleSearch = useCallback(
    (q) => {
      const trimmedQuery = q.trim();
      if (trimmedQuery.length < baseMinCharacters) {
        return;
      }
      setIsLoading(true);
      axios
        .get(`${url}${trimmedQuery}`)
        .then((r) => {
          const receivedOptions = getOptions
            ? getOptions(r)
            : (r.data && r.data.data) || [];
          setOptions(receivedOptions);
          if (triggersForEmptyStrings && receivedOptions.length === 0)
            setActualPromptText(emptyLabel);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    [url, getOptions],
  );

  const handleInputChange = useCallback(
    (queryText) => setQuery(queryText),
    [setQuery],
  );
  const handleChange = useCallback(
    (value) => {
      setValue(getValue ? getValue(value) : value[0] ? value[0].id : '');
      if (onSelection) onSelection(value);
    },
    [setValue, getValue],
  );

  return (
    <div>
      <fieldset style={{ position: 'relative' }} className="field-wrape-text">
        <Prompt
          {...{
            label,
            showRequiredLabel,
            additionalInfo,
            moreText,
            overrideClass: labelClass,
          }}
        />
        <AsyncTypeahead
          id={`${field.name}_typeahead`}
          filterBy={filterBy}
          isLoading={isLoading}
          labelKey={labelKey}
          minLength={baseMinCharacters + (query.match(/\s/g) || []).length}
          onSearch={handleSearch}
          onInputChange={handleInputChange}
          options={options}
          placeholder={placeholder}
          disabled={disabled}
          selected={selected}
          onChange={handleChange}
          useCache={false}
          multiple={multiple}
          onFocus={(event) => {
            if (triggersForEmptyStrings && event?.target?.value?.length === 0)
              handleSearch('');
            setHasFocus(true);
          }}
          onBlur={() => {
            setHasFocus(false);
          }}
          emptyLabel={emptyLabel}
          searchText={searchText}
          promptText={actualPromptText}
          renderInput={customRenderInput}
        />

        {showHelpText &&
          hasFocus &&
          query.trim().length < baseMinCharacters && (
            <div
              className="rbt-menu-min dropdown-menu show"
              style={{ padding: '10px', display: 'block' }}
            >
              <span>{helpText}</span>
            </div>
          )}
        {showError && <FieldError {...{ error, touched }} />}
      </fieldset>
    </div>
  );
};

export default wrapField(TypeAhead);
