import React, { useState } from 'react';

const WarningWrapper = ({ styles, limits }) => {
  const [collapsed, setCollasped] = useState(false);
  const handleCollapseClick = () => setCollasped((prevState) => !prevState);
  return limits && limits.infoBanners ? (
    <div className={styles.warningBannerWrapper}>
      <div className={styles.collapseButtonWrapper}>
        <button
          type="button"
          onClick={handleCollapseClick}
          className="material-symbols-outlined"
        >
          {collapsed ? 'expand_more' : 'expand_less'}
        </button>
      </div>

      {/* Has first-child style on children */}
      <div
        className={
          collapsed ? styles.collapsedInfoBanner : styles.expandedInfoBanner
        }
      >
        {limits.infoBanners.map((infoBanner, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <div
            /* eslint-disable-next-line react/no-array-index-key */
            key={index}
            className={styles.warningBannerText}
            dangerouslySetInnerHTML={{ __html: infoBanner }}
          />
        ))}
      </div>
    </div>
  ) : null;
};
export default WarningWrapper;
