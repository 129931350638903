import React from 'react';
import './style.scss';
import DialogModal from "../../../components/DialogModal";

class Welcome extends React.Component {
  constructor(props) {
    super(props);

    this.state = {modal: {}}

  }

  goToSubmitPage = () => {
    const {submitURL} = this.props;
    if (submitURL) {
      window.location.href = submitURL
    }
  }

  handleGetStarted = () => {
    const {submitURL, organization} = this.props;
    if (organization.intro_video) {
      this.openModal("DialogModal")
    } else if (submitURL) {
      this.goToSubmitPage()
    }
  }

  openModal = (name) => {
    document.body.classList.add("modal-open");
    this.setState({
      modal: Object.assign({}, this.state.modal, {
        [name]: true,
      })
    });
  };

  closeModal = (name) => {
    document.body.className = document.body.className.replace(
      'modal-open',
      ''
    );
    this.setState({
      modal: Object.assign({}, this.state.modal, {
        [name]: false
      }),
    });
  };

  render() {
    const {pageName, organization, surveyDefinition, welcomeTitle, welcomePrompt, getStartedButtonTitle, titleClass, wrapperClass, rightSectionTopClass, rightSectionSecondaryClass} = this.props;
    const {modal} = this.state;
    return (
      <div className={`welcome-presentation`}>
        <div className={`${rightSectionTopClass}`}>
          <div id="content" className={`${rightSectionSecondaryClass}`}>
            {(organization && surveyDefinition && organization.name === "UMASS medical center" && surveyDefinition.name === "HERA Screener") && (
              <img alt="" className="help-icon" src={"/assets/oie_transparent.png"}/>
            )
            }
            <div className={`${wrapperClass}`} style={{marginLeft: '0px', paddingLeft: '0px', paddingTop: '20px'}}>
              <h2 className={`${titleClass}`}>{welcomeTitle}</h2>
              <p>{welcomePrompt}</p>

              {pageName === 'welcome' && getStartedButtonTitle && (
                <div className="get-started">
                  <button className="welcome-get-started-button nav-link assessment_green_btn"
                          onClick={this.handleGetStarted}>
                    {getStartedButtonTitle}
                  </button>
                </div>
              )}
            </div>
          </div>

          {modal.DialogModal && (
            <DialogModal
              {...this.props}
              closeModal={() => {
                this.closeModal('DialogModal');
                this.goToSubmitPage();
              }}
            />
          )}
        </div>
      </div>
    );
  }
}

export default Welcome;
