import React from 'react';
import Footer from './Footer';

import styles from './right_section.module.scss';

class RightSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = { modal: {} };
  }

  goToSubmitPage = () => {
    const { submitURL } = this.props;
    if (submitURL) {
      window.location.href = submitURL;
    }
  };

  render() {
    const { children, pageName, bannerText, currentYear } = this.props;
    return (
      <div className={styles.wrapper}>
        <main
          role="main"
          className={`content-section-right page-content ${styles.main} ${pageName}-right-section`}
        >
          {/* Any common components specific to right side */}
          {children}
        </main>
        {bannerText && (
          <Footer
            bannerText={bannerText}
            footerText={`Copyright © ${currentYear}  Lucet Health. All Rights Reserved.`}
          />
        )}
      </div>
    );
  }
}

export default RightSection;
