/* eslint-disable camelcase */
import React from 'react';
import axios from 'axios';
import { Formik, Form } from 'formik';

import { ModalContext } from '../../../../context/modal';
import {
  TextField,
  MultiCheckBox,
  RadioGroup,
  Scale,
  Message,
  RadioGroupGrid,
  CustomHtmlPrompt,
  MultiProxy,
  TextArea,
} from '../../../components/inputs';
import { queryBuilder, validations, masks } from '../../../../helperFunctions';
import navigationHandler from '../../../components/navigationHandler';

import './style.scss';

class Assessment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      questions: [],
      initialValues: {},
    };
    this.nodeRef = React.createRef();
  }

  fetchQuestions = () => {
    const { updateAssessmentData, getCurrentParams, locale, token, setLoader } =
      this.props;
    const currentParams = getCurrentParams();
    const { page, survey_id } = currentParams;
    setLoader(true);
    return axios
      .get(`/api/v1/surveys/${survey_id}/pages/${page}`, {
        params: {
          survey_id,
          survey_token: token,
        },
        headers: {
          'Accept-Language': locale,
        },
      })
      .then((r) => {
        const { data } = r;
        updateAssessmentData({
          proxy: data.meta.proxy,
          is_proxy: data.meta.is_proxy,
          progress: data.meta.progress,
          page,
          survey_id,
          showPauseButtonForMssScreener:
            data.meta.show_pause_button_for_mss_screener,
        });
        this.setState(
          {
            data,
            questions: data.questions,
            initialValues: data.questions.reduce((o, item) => {
              const key = item.id;
              if (item.question_type == 'multi') {
                o[key] =
                  data.responses
                    .filter((sr) => sr.survey_definition_question_id == key)
                    .map((sr) => sr.value) || null;
              } else {
                o[key] =
                  (
                    data.responses.find(
                      (sr) => sr.survey_definition_question_id == key,
                    ) || {}
                  ).value || null;
              }
              return o;
            }, {}),
          },
          () => {
            setLoader(false);
            // this.nodeRef.current && this.nodeRef.current.focus();
          },
        );
      })
      .catch((err) => {
        const msg =
          err.response && err.response.data && err.response.data.message
            ? err.response.data.message
            : err;
        alert(msg);
        if (
          err.response &&
          err.response.data &&
          err.response.data.status === 401
        ) {
          window.location.href = '/survey-not-authorized';
        } else {
          // setLoader(false);
        }
      });
  };

  goBack = () => {
    const { updateQueryUrl, getCurrentParams, setLoader } = this.props;
    const { data } = this.state;
    const currentParams = getCurrentParams();
    const { survey_id, mode } = currentParams;
    const prevLink = data.meta.prev;
    if (mode === 'edit') {
      setLoader(true);
      window.location = `/surveys/${survey_id}/review`;
    } else if (
      prevLink &&
      prevLink.includes('surveys') &&
      prevLink.includes('pages')
    ) {
      const prevPage = prevLink.match(/.*pages\/(.*)/)[1];
      updateQueryUrl({
        ...getCurrentParams(),
        ...(mode === 'resume' ? { mode: '' } : {}),
        page: prevPage,
      });
    } else {
      // Handle other pages
      alert('Encountered some other page');
    }
  };

  goToNextPage = (data) => {
    const {
      updateQueryUrl,
      isDFD,
      getCurrentParams,
      after_survey_redirect_path,
      setLoader,
    } = this.props;
    const currentParams = getCurrentParams();
    const { survey_id, mode } = currentParams;
    const nextLink = data.meta.next;
    if (mode === 'edit') {
      setLoader(true);
      window.location = `/surveys/${survey_id}/review${
        isDFD ? '?is_dfd=true' : ''
      }`;
    } else if (
      nextLink &&
      nextLink.includes('surveys') &&
      nextLink.includes('pages')
    ) {
      const nextPage = nextLink.match(/.*pages\/(.*)/)[1];
      updateQueryUrl({
        ...currentParams,
        ...(mode === 'resume' ? { mode: '' } : {}),
        page: nextPage,
      });
    } else if (after_survey_redirect_path) {
      setLoader(true);
      window.location =
        after_survey_redirect_path +
        (isDFD
          ? after_survey_redirect_path.includes('?')
            ? '&is_dfd=true'
            : '?is_dfd=true'
          : '');
    }
  };

  handleSubmit = (values, { setSubmitting, setErrors }) => {
    const { setLoader, getCurrentParams, token } = this.props;
    const { data } = this.state;
    const currentParams = getCurrentParams();
    const { page, survey_id } = currentParams;

    const responses = [];
    data.questions.forEach((q) => {
      const key = q.id;
      const value = values[key];
      if (value !== undefined) {
        if (q.question_type === 'multi') {
          Array.isArray(value) &&
            value.forEach((v) => {
              responses.push({
                survey_definition_question_id: key,
                value: v,
              });
            });
        } else {
          responses.push({
            survey_definition_question_id: key,
            value,
          });
        }
      }
    });

    setLoader(true);
    return axios({
      method: 'patch',
      url: `/api/v1/surveys/${survey_id}/pages/${page}`,
      data: {
        responses,
        survey_id,
        survey_token: token,
        lite: true,
      },
      // headers,
    })
      .then((r) => {
        setLoader(false);
        if (r.data && r.data.meta) {
          this.goToNextPage(r.data);
        } else if (
          r.data &&
          r.data.errors &&
          typeof r.data.errors === 'object'
        ) {
          setErrors(r.data.errors);
        } else {
          alert('Something went wrong. Please try again.');
        }
      })
      .catch((err) => {
        setLoader(false);
        if (err && err.errors && typeof err.errors === 'object') {
          setErrors(err.errors);
        } else {
          const msg =
            err.response && err.response.data && err.response.data.message
              ? err.response.data.message
              : err;
          alert(msg);
        }
      });
  };

  showResumePopup = () => {
    const { stringValues, patient, proxy, is_proxy } = this.props;
    const { alertDialogue } = this.context;
    alertDialogue(
      <div>
        <strong>
          {stringValues['welcome-back']},{' '}
          {is_proxy && proxy ? proxy.label : patient.first_name}.
        </strong>
        <p>{stringValues['pause-message5']}</p>
        {is_proxy && proxy && (
          <p>
            {`${stringValues['only-tag']} ${proxy.name} ${stringValues['complete-message-popup']} ${stringValues['if-you-are-not']} ${proxy.name}, ${stringValues['exit-assessment-popup']}`}
          </p>
        )}
      </div>,
      {
        okText: stringValues['continue-button'],
        customProps: { disableLucetTheme: true },
        className: 'welcome_back_modal',
      },
    );
  };

  completeSurvey = (timedout) => {
    const { getCurrentParams, token, locale, mode, setLoader } = this.props;
    const currentParams = getCurrentParams();
    const { page, survey_id } = currentParams;
    setLoader(true);
    axios({
      method: 'patch',
      url: `/api/v1/surveys/${survey_id}`,
      data: {
        paused_at: page,
        status: 'paused',
        survey_id,
        survey_token: token,
      },
    })
      .then((r) => {})
      .catch((err) => {})
      .then((r) => {
        let redirectionUrl = `/${
          mode === 'home' ? 'time_out' : 'welcome'
        }?locale=${locale}&survey_id=${survey_id}&has_timed_out=${timedout}`;
        if (mode === 'dfd') {
          redirectionUrl = `/message?message=mss_screener_timeout&has_timed_out=${timedout}`;
        }
        window.location.replace(redirectionUrl);
      });
  };

  timedOut = () => {
    this.completeSurvey(true);
  };

  removePendoResourceCenterBadge = () => {
    document.body.classList.add('removePendoResourceCenterBadge');
  };

  componentDidMount() {
    const { getCurrentParams, updateQueryUrl, location } = this.props;
    const defaultParams = {
      page: 1,
      ...getCurrentParams(),
    };
    const currentParams = location.search.replace(/^\?/gi, '');
    if (queryBuilder(defaultParams) === currentParams) {
      this.fetchQuestions();
    } else {
      updateQueryUrl(defaultParams);
    }
    if (defaultParams.mode === 'resume') {
      this.showResumePopup();
    }
    this.removePendoResourceCenterBadge();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.search !== this.props.location.search) {
      this.fetchQuestions();
    }
    if (prevProps.hasTimedOut === false && this.props.hasTimedOut === true) {
      this.timedOut();
    }
  }

  render() {
    const {
      stringValues,
      organizationRelationships,
      track_id,
      getCurrentParams,
      locale,
    } = this.props;
    const { questions, initialValues, data } = this.state;
    const prevLink = data.meta && data.meta.prev;
    const disallowBackNavigation =
      data &&
      data.questions &&
      data.questions.some((o) => o.disallow_back_navigation);
    const currentParams = getCurrentParams();
    const { survey_id, mode } = currentParams;
    return (
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={this.handleSubmit}
        validateOnMount
      >
        {({
          errors,
          touched,
          isValidating,
          isValid,
          setFieldValue,
          values,
          submitForm,
          handleChange,
        }) => (
          <Form className="assessment-page-content survey-form">
            <div
              ref={this.nodeRef}
              className="survey-form-inner"
              aria-live="polite"
              tabIndex={-1}
            >
              {questions.map((q, i) => {
                const commonProps = {
                  label: q.prompt,
                  name: q.id,
                  additionalInfo: q.additional_info,
                  validate: (val) => validations.required(val, locale),
                  moreText: stringValues.more,
                };
                return (
                  <React.Fragment key={q.id}>
                    {q.group_prompt && i === 0 && (
                      <h2 className="prompt">
                        <CustomHtmlPrompt label={q.group_prompt} />
                      </h2>
                    )}
                    {q.question_type === 'multi' ? (
                      <MultiCheckBox {...commonProps} options={q.options} />
                    ) : q.question_type === 'single' &&
                      q.group_prompt &&
                      q.group_type === 'grid' ? (
                      <RadioGroupGrid
                        {...commonProps}
                        options={q.options}
                        gridIndex={i}
                      />
                    ) : q.question_type === 'single' ||
                      q.question_type === 'demographic' ? (
                      <RadioGroup {...commonProps} options={q.options} />
                    ) : q.question_type === 'multi_proxy' ? (
                      <MultiProxy
                        {...commonProps}
                        options={q.options}
                        stringValues={stringValues}
                        organizationRelationships={organizationRelationships}
                        setFieldValue={setFieldValue}
                        values={values}
                        submitForm={submitForm}
                        track_id={track_id}
                        survey_id={survey_id}
                        handleChange={handleChange}
                        mode={mode}
                      />
                    ) : q.question_type === 'number' ? (
                      <TextField
                        {...commonProps}
                        type="number"
                        placeholder={q.placeholder}
                        max={q.slider_max}
                        min={q.slider_min}
                        areaLabel={stringValues['text-box-warning']}
                        validate={[
                          (val) => validations.required(val, locale),
                          (val) => validations.max(val, q.slider_max, locale),
                          (val) => validations.min(val, q.slider_min, locale),
                        ]}
                      />
                    ) : q.question_type === 'scale' ? (
                      <Scale
                        {...commonProps}
                        max={q.slider_max}
                        min={q.slider_min}
                        scaleLowLabel={q.scale_low_label}
                        scaleMidLabel={q.scale_mid_label}
                        scaleHighLabel={q.scale_high_label}
                        allowNoResponse={q.scale_allow_no_response}
                        allowNoResponseLabel={q.scale_no_response_label}
                        allowNoResponseValue={q.scale_no_response_value}
                      />
                    ) : q.question_type === 'message' ||
                      q.question_type === 'warn' ? (
                      <Message {...commonProps} warningText={q.warning_text} />
                    ) : q.question_type === 'phone' ? (
                      <TextField
                        {...commonProps}
                        mask="phone"
                        placeholder={q.placeholder}
                        areaLabel={stringValues['text-box-warning']}
                        allowNoResponse={q.scale_allow_no_response}
                        allowNoResponseLabel={q.scale_no_response_label}
                        allowNoResponseValue={q.scale_no_response_value}
                        validate={[
                          ...(q.tag !== 'rbestnum2'
                            ? [(val) => validations.required(val, locale)]
                            : []),
                          ...(!q.scale_allow_no_response
                            ? [(val) => validations.phone(val, locale)]
                            : []),
                        ]}
                      />
                    ) : q.question_type == 'textarea' ? (
                      <TextArea
                        {...commonProps}
                        placeholder={q.placeholder}
                        areaLabel={stringValues['text-box-warning']}
                      />
                    ) : q.question_type === 'email' ? (
                      <TextField
                        {...commonProps}
                        type="text"
                        placeholder={q.placeholder}
                        normalize={masks.email}
                        validate={[
                          (val) => validations.required(val, locale),
                          (val) => validations.email(val, locale),
                        ]}
                      />
                    ) : (
                      <TextField
                        {...commonProps}
                        type="text"
                        placeholder={q.placeholder}
                        areaLabel={stringValues['text-box-warning']}
                      />
                    )}
                  </React.Fragment>
                );
              })}
            </div>
            {
              // A different set of buttons are present for proxy questions while creating a proxy
              !values.createNewProxy && (
                <div
                  className={`${
                    mode === 'edit' ? 'edit-buttons-space' : ''
                  } navigation-buttons-space clear`}
                >
                  <button
                    type="submit"
                    className={`flt-rgt next-button nav-btn1 nav-btn-nxt next-btn-active ${
                      !isValid ? 'inactive' : ''
                    }`}
                    id="btn-next"
                    name="nav-button"
                    value="Next"
                    tabIndex="0"
                    disabled={!isValid}
                  >
                    {(mode === 'edit'
                      ? stringValues['save-changes-button']
                      : stringValues['next-button']) || 'Next'}
                  </button>
                  {((prevLink && !disallowBackNavigation) ||
                    mode === 'edit') && (
                    <button
                      type="button"
                      className="btn-back flt-lft nav-btn1"
                      name="nav-button"
                      value="Back"
                      id="btn-back"
                      onClick={this.goBack}
                    >
                      {(mode === 'edit'
                        ? stringValues['cancel-changes-button']
                        : stringValues['back-button']) || 'Back'}
                    </button>
                  )}
                </div>
              )
            }
          </Form>
        )}
      </Formik>
    );
  }
}

Assessment.contextType = ModalContext;

export default navigationHandler(Assessment);
