import React from "react"
import DialogModal from "../../../components/DialogModal";
import {Formik, Form} from 'formik';
import {TextField} from "../../../components/inputs";
import {masks, validations, csrfHeader} from "../../../../helperFunctions";
import {formValidations} from "../../../helpers/moduleHelper";
import axios from 'axios';

class PhoneMatchForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {modal: {}, showNextButton: false, pageErrors: this.pageErrors(props.data)}

  }

  handleSubmit = (values, url) => {
    const {pageName, setLoader} = this.props;
    setLoader(true);
    return axios({
      method: 'post',
      url: url,
      data: values,
      headers: csrfHeader,
    })
      .then(response => {
        if (response && response.data && response.data.id && (response.data.present && response.data.present.toLowerCase() === 'true'))
            window.location.href = `/confirm?patient_id=${response.data.id}&org_id=${values.org_id}&site_id=${values.site_id}&group=${values.group_id}`
        else {
          setLoader(false);
          this.openModal("DialogModal");
          setTimeout(() => this.goToWelcomePage(),20000);
        }
      })
      .catch(err => {
        setLoader(false);
        const msg =
          err.response.data && err.response.data.message
            ? err.response.data.message
            : err;
        alert(msg);
      });
  }
  goToWelcomePage = () => {
    window.location.href = "/welcome"
  }

  handleBackButton = () => {
    const {backPath} = this.props;
    window.location.href = backPath ? backPath : "/welcome";
  }

  openModal = (name) => {
    const {setLoader} = this.props;
    setLoader(false);
    document.body.classList.add("modal-open");
    this.setState({
      modal: Object.assign({}, this.state.modal, {
        [name]: true,
      })
    });
  };

  closeModal = (name) => {
    document.body.className = document.body.className.replace(
      'modal-open',
      ''
    );
    this.setState({
      modal: Object.assign({}, this.state.modal, {
        [name]: false
      }),
    });
  };

  validate = (values) => {
    const {locale} = this.props;
    const fieldErrorMessage = formValidations.fieldErrorMessage(locale);
    let errors = {};
    let phoneErrors = !values.phone_number ? fieldErrorMessage : validations.phone(values.phone_number, locale);
    if (phoneErrors) {
      errors.phone_number = phoneErrors;
    }
    this.setState({showNextButton: Object.keys(errors).length === 0, pageErrors: this.pageErrors(values)})
    return errors;
  }

  pageErrors = (values) => {
    const {locale} = this.props;
    return (values.dob && formValidations.dob(values.dob,"MM/DD/YYYY", locale));
  }

  render() {
    const {
      pageName, organization, site, data, group_id, stringValues, nextButtonLabel, backButtonLabel,
      submitURL
    } = this.props;
    const {modal, showNextButton, pageErrors} = this.state;
    return (
      <div>
        <Formik
          initialValues={{
            first_name: data ? data.first_name : '',
            last_name: data ? data.last_name : '',
            dob: data ? data.dob : '',
            phone_number: '',
            org_id: organization && organization.id,
            site_id: site && site.id,
            group_id: group_id,
          }}
          onSubmit={(values, {setSubmitting}) => {
            setTimeout(() => {
              this.handleSubmit(values, submitURL).then(r => {

              })
              setSubmitting(false);
            }, 400);
          }}
          validate={this.validate}
        >
          {props => (
            <Form className="user_match">
              <div className={`rows`}>
                <div className="row clear">
                  <div className="form-group col-md-6 col-sm-12 flt-lft">
                    <TextField
                      label={stringValues && stringValues["enter-phone-number-prompt"]}
                      name="phone_number"
                      type="text"
                      mask="phone"
                      inputClass={`form-control`}
                    />
                  </div>
                </div>
                {pageErrors && (
                  <div className="row clear">
                    <div className="form-group col-md-12 col-sm-12 flt-lft">
                      <div className="field-error">{pageErrors}
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="navigation-buttons-space clear">
                {showNextButton && (
                <button type="submit" className={`btn-next flt-rgt sub-form`} name=" action" id="
                                    btn-next">{(stringValues && stringValues["next-button"])}</button>
                )}
                <button type="button" onClick={this.handleBackButton} className={`btn-back flt-lft`}>{(stringValues && stringValues["back-button"])}</button>
              </div>
            </Form>
          )}
        </Formik>
        {modal.DialogModal && (
          <DialogModal
            {...this.props}
            closeModal={() => {
              this.closeModal('DialogModal');
              this.goToWelcomePage();
            }}
            saveModal={() => {
              this.closeModal('DialogModal');
              this.goToWelcomePage();
            }}
          />
        )}
      </div>
    );
  }
}

export default PhoneMatchForm