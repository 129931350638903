/* eslint-disable camelcase */
import axios from 'axios';
import { csrfHeader } from '../../helperFunctions';

/**
 * Locks the selected slot with a patient. This is done to prevent
 * the slot from being booked by another patient.
 * @param {String} {slotId - The id of the slot to be locked.
 * @param {String} patientId} - The id of the patient to be locked with.
 * @returns {any} - The response from the server.
 */
const lockSlotForPatient = ({ slotId, patientId }) =>
  axios.post(
    `/lock_slot/${slotId}`,
    {
      locked_for_id: patientId,
      locked_for_type: 'Patient',
    },
    { headers: csrfHeader },
  );
/**
 * Releases the lock on the slot. This is done when the patient
 * closes the modal without booking the slot.
 * @param {String} {slotId - The id of the slot to be unlocked.
 * @param {String} patientId} - The id of the patient to be unlocked with.
 * @returns {any} - The response from the server.
 */
const unlockSlotForPatient = ({ slotId, patientId }) =>
  axios.post(
    `/slots/${slotId}/unlock/`,
    {
      locked_for_id: patientId,
      locked_for_type: 'Patient',
    },
    { headers: csrfHeader },
  );

export { lockSlotForPatient, unlockSlotForPatient };
