import React from 'react';
import { parseURL, queryBuilder } from '../../helperFunctions';

export default function navigationHandler(WrappedComponent) {
  return class NavigationHandler extends React.Component {
    getCurrentParams = () => {
      return parseURL(this.props.location.search.slice(1));
    };

    updateQueryUrl = (queryData) => {
      const query = queryBuilder(queryData);
      this.props.history.push(`?${query}`);
    };

    render() {
      return (
        <WrappedComponent
          getCurrentParams={this.getCurrentParams}
          updateQueryUrl={this.updateQueryUrl}
          {...this.props}
        />
      );
    }
  };
}
