/* eslint-disable camelcase */
import React, { useEffect, useRef, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import axios from 'axios';
import useWindowSize from '../../../../hooks/useWindowSize';
import ProviderDetailWrapper from './ProviderDetailWrapper';
import ProviderDetailPopUp from '../shared/ProviderDetailModal';

const ProviderDetail = ({ encounter, patientLocation, styles = {} }) => {
  const provider = encounter && encounter.provider;

  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [profileData, setProfileData] = useState(null);
  const [, showTooltip] = useState(true);
  const [modal, showModal] = useState(false);
  const tooltipReference = useRef();

  const { windowWidth, windowHeight } = useWindowSize();

  const hideModal = () => {
    if (isMobile) {
      showModal(false);
    } else {
      // this is required if you want to hide the tooltip while you are still hovering over it.
      if (tooltipReference && tooltipReference.current) {
        tooltipReference.current.tooltipRef = null;
        ReactTooltip.hide();
      }
      showTooltip(false);
    }
  };

  // eslint-disable-next-line consistent-return
  const showProviderDetail = () => (
    <ProviderDetailPopUp
      profileData={profileData}
      provider={{
        ...provider,
        travel_radius: profileData && profileData.travel_radius,
      }}
      customClass="mss-show-provider-detail-cont"
      onClose={hideModal}
      asToolTip
      show
    />
  );

  const setData = () => {
    if (provider) {
      const { site_id, patient } = encounter;
      const location = patientLocation && patientLocation.displayString;
      const stateCode =
        patientLocation && patientLocation.place?.properties?.stateCode;
      if (patient) {
        const queryParams = `site_id=${site_id}&patient_id=${patient.id}&patient_location=${location}&patient_location_state_code=${stateCode}`;
        axios
          .get(
            `/member_self_serve/${provider.id}/practitioner_details?${queryParams}`,
          )
          .then((r) => {
            setProfileData(r.data);
          });
      }
    }
  };

  const showModalDialog = () => {
    showModal(true);
    setData();
  };

  const closeModalDialog = () => {
    showModal(false);
  };

  useEffect(() => {
    setIsMobile(windowWidth < 768);
  }, [windowWidth, windowHeight]);

  return (
    <React.Fragment>
      <div className={styles.providerDetail}>
        <div className={styles.providerDetailInner}>
          <div className={styles.detailLeft}>
            <div className={styles.providerName}>
              <span>{provider && provider.name}</span>
            </div>
            {encounter &&
              encounter.location &&
              provider &&
              provider.intake_appt_role !== 'coordinator' && (
                <div className={styles.providerAddress}>
                  <span>{encounter.location}</span>
                </div>
              )}
            {encounter && encounter.phone_number && (
              <div>
                <a
                  className={styles.providerPhone}
                  href={`tel: ${encounter.phone_number}`}
                >
                  <span>{encounter.phone_number}</span>
                </a>
              </div>
            )}
          </div>
          <div className={`provider_details ${styles.detailRight}`}>
            <div
              className={styles.avatar}
              style={{
                backgroundImage: `url(${
                  (provider && provider.avatar) || '/assets/profile-img.png'
                })`,
              }}
            />
            <ProviderDetailWrapper
              encounter={encounter}
              isMobile={isMobile}
              styles={styles}
              modal={modal}
              showModalDialog={showModalDialog}
              closeModalDialog={closeModalDialog}
              setData={setData}
              showProviderDetail={showProviderDetail}
              tooltipReference={tooltipReference}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ProviderDetail;
