import axios from 'axios';
import { csrfHeader, delay } from '../../helperFunctions';
import { lockSlotForPatient } from './slot_locking';
import cancelAppointment from './cancellation';
import {
  createSlotBookRequestTransaction,
  confirmTransactionByPolling,
} from './transaction';

const checkWhetherInsuranceMatches = ({ patientId, providerId }) =>
  axios
    .get(`/call_centers/insurance_matching`, {
      params: {
        patient_id: patientId,
        provider_id: providerId,
      },
    })
    .then((r) => r.data.status);

const getExperianPatientBenefitById = ({
  patientId,
  surveyId,
  callCenterEncounterId,
}) =>
  axios.get(`/get_experian_patient_benefit_by_id`, {
    params: {
      patient_id: patientId,
      survey_id: surveyId,
      call_center_encounter_id: callCenterEncounterId,
    },
  });

// Checking whether the slot is still free.
const checkIsSlotFree = ({
  provider_id,
  appointment: {
    start_date,
    site,
    site_id,
    on_hold: isHeldAppointment,
    timezone,
  },
  patient_id,
}) =>
  axios
    .get(`/check_practitioner_busy`, {
      params: {
        provider_id,
        appointment: {
          start_date,
          site,
          site_id,
          on_hold: isHeldAppointment,
          timezone,
        },
        patient_id,
      },
    })
    .then((r) => r.data === true);

// Booking the appointment in the server.
const bookAppointmentApi = ({
  mode,
  isHeldAppointment,
  provider_id,
  appointment: { start_date, duration, is_virtual, site, visit_type_id, id },
  patient_id,
  survey_id,
  encounter_id,
  site_id,
  member_self_serve_record,
  params,
  email,
  sms,
  transactionResponse,

  // call center specific params
  timezone,
  comments,
  callCenterEncounterId,
  specialty_id,
  exclude_specialities,
  referral_home,
  reschedule,
  multi_referral,
}) => {
  const paramData = {
    appointment: JSON.stringify({
      start_date,
      duration,
      is_virtual,
      site,
      visit_type_id,
      on_hold: isHeldAppointment,
      timezone,
      id,
    }),
    phone: sms,
    email,
    provider_id,
    patient_id,
    survey_id,
    encounter_id,
    site_id,
    member_self_serve_record,
    ...(params.reschedule ? { reschedule: true } : {}),
    ...(params.multi_referral ? { multi_referral: true } : {}),
    ...(params.referral_instruction_id
      ? { referral_instruction_id: params.referral_instruction_id }
      : {}),
    ...(params.referral_option_id
      ? { referral_option_id: params.referral_option_id }
      : {}),
    ...(transactionResponse && {
      enc_id: transactionResponse.encounter_id,
      practitioner_status: transactionResponse.practitioner_status,
    }),

    timezone,
    comments,
    call_center_encounter_id: callCenterEncounterId,
    specialty_id,
    exclude_specialities,
    referral_home,
    reschedule,
    multi_referral,
  };
  const url =
    mode === 'call_center'
      ? '/call_centers/process_appointment'
      : '/admin/dfd/process_appointment';
  return axios
    .post(url, paramData, {
      headers: csrfHeader,
    })
    .then((response) => response);
};

// Do the necessary checks and book the appointment slot.
const bookSlot = async (
  {
    mode, // 'mss' or 'call_center'
    dsboConfirmationEnabled,
    dsboCancellationConfirmationEnabled,
    cancellationPollingTimeout,
    slotId,
    provider_id,
    appointment: {
      start_date,
      duration,
      is_virtual,
      site,
      site_id,
      visit_type_id,
      hasIdentifiers,
      id,
    },
    patient_id,
    survey_id,
    encounter_id,
    encounterToReschedule,
    member_self_serve_record,
    params,
    email,
    sms,

    // call center specific params
    isHeldAppointment,
    callCenterEncounterId,
    comments,
    specialty_id,
    exclude_specialities,
    referral_home,
    reschedule,
    multi_referral,
    timezone,
  },
  statusFeedback = () => {},
) => {
  statusFeedback('Locking the slot');
  const lockApiRes = await lockSlotForPatient({
    slotId,
    patientId: patient_id,
  });
  if (lockApiRes.data.status !== 200) {
    throw new Error(
      'We’re sorry, but the slot you’ve attempted to book is no longer available. Please select another slot.',
    );
  }

  if (isHeldAppointment) {
    statusFeedback('Checking insurance matching');
    const isMatching = await checkWhetherInsuranceMatches({
      patientId: patient_id,
      providerId: provider_id,
    });
    if (!isMatching) {
      throw new Error(
        "The provider does not accept this patient's insurance type. Please contact provider to ensure their accepted insurance types are up to date.",
      );
    }
  }

  statusFeedback('Checking whether the slot is still free');
  const isSlotFree = await checkIsSlotFree({
    provider_id,
    appointment: {
      start_date,
      site,
      site_id,
      on_hold: isHeldAppointment,
      timezone,
    },
    patient_id,
  });
  if (!isSlotFree) {
    throw new Error(
      "We're sorry, the appointment that you picked has been booked to another patient. Select OK to search again for available appointments.",
    );
  }

  if (mode === 'call_center') {
    statusFeedback('Checking experian patient benefit');
    await delay(1000); // To show the status message
    await getExperianPatientBenefitById({
      patientId: patient_id,
      surveyId: survey_id,
      callCenterEncounterId,
    });
    statusFeedback('Experian patient benefit check successful');
    await delay(1000); // To show the status message
  }

  if (dsboCancellationConfirmationEnabled && encounterToReschedule) {
    statusFeedback('Cancelling the previous appointment');
    await cancelAppointment({
      mode,
      dsboCancellationConfirmationEnabled,
      encounterId: encounterToReschedule.id,
      hasIdentifiers: encounterToReschedule.hasIdentifiers,
      patientId: patient_id, // optional - only for mss
      cancelOnlyOnExternalCalendar: true,
      pollingTimeout: cancellationPollingTimeout,
    });
  }

  let transactionResponse;
  if (dsboConfirmationEnabled && hasIdentifiers) {
    statusFeedback('Creating booking transaction');
    const transaction = await createSlotBookRequestTransaction({
      survey_id,
      patient_id, // only for mss
      slot_id: slotId,
      visit_type_id,
      start_date,
      end_date: start_date + duration * 60 * 1000,
      duration,
      site_id,
      is_virtual,
      phone: sms,
      email,
      // call center specific params
      timezone,
      comments,
      callCenterEncounterId,
    });
    statusFeedback('Confirming booking transaction');
    transactionResponse = await confirmTransactionByPolling({
      transactionId: transaction.transaction_id,
      patientId: patient_id, // optional - only for mss
    }).catch(() => {
      throw new Error(
        'We’re sorry, but the slot you’ve attempted to book is no longer available. Please select another slot.',
      );
    });
  }

  statusFeedback('Booking the appointment');
  const result = await bookAppointmentApi({
    mode,
    isHeldAppointment,
    provider_id,
    appointment: {
      start_date,
      duration,
      is_virtual,
      site,
      visit_type_id,
      id,
    },
    patient_id,
    survey_id,
    encounter_id,
    site_id,
    member_self_serve_record,
    params,
    email,
    sms,
    transactionResponse,

    comments,
    timezone,
    callCenterEncounterId,
    specialty_id,
    exclude_specialities,
    referral_home,
    reschedule,
    multi_referral,
  }).catch((err) => {
    statusFeedback('Booking failed');
    console.error(err);
    throw err;
  });
  statusFeedback('Booking successful');
  return result;
};

export default bookSlot;
