import moment from 'moment';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import useFetch from '../../../../../hooks/useFetch';
import FilterSection from './FilterSection';
import styles from './provider_drill_down.module.scss';
import useQueryNormalizer from '../../../../../hooks/useQueryNormalizer';
import SlotSection from './SlotSection';
import ProviderMediaBlock from '../provider_block/ProviderMediaBlock';
import {
  getUserTimeZone,
  tryWithDefault,
} from '../../../../../helperFunctions';
import useWindowSize from '../../../../../hooks/useWindowSize';
import { useUtilities } from '../../../../../context/utilities';
import AppBreadcrumb from '../../shared/AppBreadcrumb';
import { mssBreadcrumbs } from '../../../../helpers/moduleHelper';
import usePendoUpdateUrl from '../../../../../hooks/usePendoUpdateUrl';

const userTimeZone = getUserTimeZone() || 'UTC';

const ProviderDrillDown = ({
  providerId,
  surveyId,
  siteIds,
  defaultFilters,
  showSlotBookingModal,
  stringValues,
  headerFilterOpen,
  handleHeaderFilterToggle,
  memberSelfServeId,
  pathway,
  pdoEnabled,
}) => {
  const { setSpinner } = useUtilities();
  const [queryString, formValues, handleSubmit] = useQueryNormalizer(
    '',
    defaultFilters,
  );
  const [isLoading, provider, , fetchData] = useFetch(
    `/api/v1/practitioners/${providerId}?${queryString}&mode=dfd&timezone=${userTimeZone}&survey_id=${surveyId}&default_site_id=${siteIds}`,
  );
  const [, filterFieldsData] = useFetch(
    `/filters/practitioner_profile?id=${providerId}`,
  );
  useEffect(() => {
    setSpinner(isLoading);
  }, [setSpinner, isLoading]);

  const sitesWithOpenSlots = provider?.sites_with_open_slots || [];
  const [selectedSiteId, setSelectedSiteId] = useState(siteIds?.[0]);
  const selectedSite = sitesWithOpenSlots.find((s) => s.id === selectedSiteId);

  const siteInsurances = provider?.practitioner_site_insurances || [];
  const selectedSiteInsurances = tryWithDefault(
    () =>
      siteInsurances.find((s) => s.site_id === selectedSite.id).insurance_types,
    [],
  );
  const siteInsurancesLabel = tryWithDefault(
    () => selectedSiteInsurances.map((insurance) => insurance.name).join(', '),
    '',
  );

  const handleReset = useCallback(() => {
    handleSubmit(defaultFilters);
  }, [handleSubmit, defaultFilters]);

  const onClickSlot = useCallback(
    (slot) => showSlotBookingModal({ ...slot, provider }, formValues),
    [showSlotBookingModal, provider],
  );
  const history = useHistory();
  const goBack = useCallback(() => {
    history.goBack();
  }, [history]);

  const initialValues = useMemo(
    () => ({ ...defaultFilters, ...formValues }),
    [defaultFilters, formValues],
  );

  const slotData = useMemo(() => {
    const rawTimeSlots = provider.time_slots || [];
    // eslint-disable-next-line camelcase
    const { start_date, end_date } = formValues;

    const startDate = moment(start_date, 'MM/DD/YYYY');
    const endDate = moment(end_date, 'MM/DD/YYYY');

    const result = [];
    const noOfDays = endDate.diff(startDate, 'days') + 1;

    for (
      let i = moment(startDate), end = moment(endDate);
      i <= end;
      i.add(1, 'days')
    ) {
      result.push({
        stamp: moment(i),
        title: `${
          stringValues[moment(i).format('dddd').toLowerCase()]
        } ${moment(i).format('M/D')}`,
        timeSlots: [],
      });
    }

    for (let i = 0; i < noOfDays; i++) {
      for (let j = 0; j < rawTimeSlots.length; j++) {
        if (moment(rawTimeSlots[j].start_date).isSame(result[i].stamp, 'day')) {
          result[i].timeSlots.push(rawTimeSlots[j]);
        }
      }
    }

    const resultFiltered = result.filter((o) => o.timeSlots.length);
    return resultFiltered;
  }, [formValues, provider.time_slots]);

  const { windowWidth } = useWindowSize();
  const isMobile = windowWidth < 1235;

  const [isCollapsedDescription, setIsCollapsedDescription] =
    React.useState(true);

  const mediaBlockWrapperRef = useRef();
  useEffect(() => {
    if ('IntersectionObserver' in window) {
      const stickyElm = mediaBlockWrapperRef.current;
      const observer = new IntersectionObserver(
        ([e]) =>
          e.target.classList.toggle('stickyTriggered', e.intersectionRatio < 1),
        { threshold: [1] },
      );
      observer.observe(stickyElm);
    }
  }, []);

  // if (!provider.time_slots) return '';

  const crumbs = useMemo(
    () =>
      mssBreadcrumbs(memberSelfServeId, stringValues).filter((e) =>
        pathway && pathway === 'member_self_serve_provider_list'
          ? e.tag === 'home' ||
            e.tag === 'provider_list' ||
            e.tag === 'provider_detail'
          : e.tag === 'home' || e.tag === 'provider_detail',
      ),
    [memberSelfServeId],
  );

  usePendoUpdateUrl(`/provider_drill_down/${providerId}`);

  return (
    <React.Fragment>
      <div className={styles.panelLeft}>
        <div ref={mediaBlockWrapperRef} className={styles.mediaBlockWrapper}>
          <AppBreadcrumb crumbs={crumbs} />
          <ProviderMediaBlock
            provider={provider}
            stringValues={stringValues}
            selectedSiteId={selectedSiteId}
            selectedSite={selectedSite}
            changeSelectedSiteId={(value) => {
              setSelectedSiteId(value);
            }}
          />
        </div>
        <div className={styles.panelLeftContent}>
          <div className={styles.accordionBtnWrapper}>
            <hr className={styles.separator} />
            <button
              className={`flex align-center justify-content-center  accordion-btn visible-sm visible-xs ${
                styles.accordionBtn
              } ${isCollapsedDescription ? 'invert-vertical' : ''}`}
              onClick={() => setIsCollapsedDescription(!isCollapsedDescription)}
              type="button"
            >
              <img
                className=""
                src="/assets/icn_expand.png"
                alt="icon_expand"
              />
            </button>
          </div>
          <div
            className={`${styles.providerInfo} ${
              isCollapsedDescription && isMobile
                ? styles.collapsedProviderInfo
                : ''
            }`}
          >
            {provider.intake_appt_role === 'coordinator' && (
              <p className="color-plum">
                <strong>
                  <i>{stringValues.intake_coordinator_info}</i>
                </strong>
              </p>
            )}
            <p className={`${styles.biography}`}>{provider.biography}</p>

            {provider.specialties?.length > 0 && (
              <React.Fragment>
                <h2 className={styles.subHeading}>
                  {stringValues['areas_of_practice']}
                </h2>
                <p>
                  {pdoEnabled
                    ? provider.trainings.map((spec) => spec.name).join(', ')
                    : provider.specialties.map((spec) => spec.name).join(', ')}
                </p>
              </React.Fragment>
            )}

            {provider.years_in_practice && (
              <React.Fragment>
                <h2 className={styles.subHeading}>
                  {stringValues['experience']}
                </h2>
                <p>
                  {`${provider.years_in_practice} ${
                    provider.years_in_practice > 1 ? 'Years' : 'Year'
                  }`}
                </p>
              </React.Fragment>
            )}

            {provider.education?.length > 0 && (
              <React.Fragment>
                <h2 className={styles.subHeading}>
                  {stringValues['education']}
                </h2>
                <p>
                  {provider.education.map((education) => (
                    <span key={education.id} className="display-block">
                      {education.degree && education.degree.name},{' '}
                      {education.school} {education.year_completed}
                    </span>
                  ))}
                </p>
              </React.Fragment>
            )}

            {(provider.age_range?.length > 0 ||
              provider.treatment_age_range_labels?.length > 0) && (
              <React.Fragment>
                <h2 className={styles.subHeading}>
                  {stringValues['client_focus']}
                </h2>
                <p>
                  {provider.age_range &&
                    provider.age_range.map((ageRange) => (
                      <span key={ageRange.id} className="display-block">
                        {ageRange.name}
                      </span>
                    ))}
                  <div className="bold">{stringValues.ages_treated}:</div>
                  <ul className="margin-0 padding-left-lg">
                    {provider.treatment_age_range_labels?.map(
                      (ageRangeLabel) => (
                        <li key={ageRangeLabel} className="display-block">
                          {ageRangeLabel}
                        </li>
                      ),
                    )}
                  </ul>
                </p>
              </React.Fragment>
            )}

            {provider.treatment_types?.length > 0 && (
              <React.Fragment>
                <h2 className={styles.subHeading}>
                  {stringValues['types_of_therapy']}
                </h2>
                <p>
                  {provider.treatment_types.map((type) => type.name).join(', ')}
                </p>
              </React.Fragment>
            )}

            <h2 className={styles.subHeading}>
              {stringValues['accepts_virtual_appointments']}
            </h2>
            <p>{provider.accept_video_appointments ? 'Yes' : 'No'}</p>

            {provider.payment_methods?.length > 0 && (
              <React.Fragment>
                <h2 className={styles.subHeading}>
                  {stringValues['accepted_payment_methods']}
                </h2>
                <p>
                  {provider.payment_methods
                    .map((method) => method.name)
                    .join(', ')}
                </p>
              </React.Fragment>
            )}

            {siteInsurancesLabel && (
              <React.Fragment>
                <h2 className={styles.subHeading}>
                  {stringValues['accepted_insurance_plans']}
                </h2>
                <p>{siteInsurancesLabel}</p>
              </React.Fragment>
            )}

            {provider.cancellation_policy && (
              <React.Fragment>
                <h2 className={styles.subHeading}>
                  {stringValues['cancellation_policy']}
                </h2>
                <p>{provider.cancellation_policy}</p>
              </React.Fragment>
            )}

            {provider.licenses?.length > 0 && (
              <React.Fragment>
                <h2 className={styles.subHeading}>
                  {stringValues['licenses']}
                </h2>
                <p>
                  {provider.licenses.map((license) => (
                    <p key={license.id}>
                      {license.license_number}, {license.state?.name}
                    </p>
                  ))}
                </p>
              </React.Fragment>
            )}

            {provider.languages?.length > 0 && (
              <React.Fragment>
                <h2 className={styles.subHeading}>
                  {stringValues['languages']}
                </h2>
                <p>
                  {provider.languages.map((method) => method.name).join(', ')}
                </p>
              </React.Fragment>
            )}

            {provider.modalities?.length > 0 && (
              <React.Fragment>
                <h2 className={styles.subHeading}>
                  {stringValues['modality']}
                </h2>
                <p>
                  {provider.modalities.map((method) => method.name).join(', ')}
                </p>
              </React.Fragment>
            )}

            {provider.phone && (
              <React.Fragment>
                <h2 className={styles.subHeading}>
                  {stringValues['phone_number']}
                </h2>
                <p>{provider.phone}</p>
              </React.Fragment>
            )}

            {selectedSite && (
              <React.Fragment>
                {selectedSite.hours && (
                  <React.Fragment>
                    <h2 className={styles.subHeading}>
                      {stringValues['hours']}
                    </h2>
                    <p>{selectedSite.hours}</p>
                  </React.Fragment>
                )}

                {selectedSite.transportation_accommodations?.length > 0 && (
                  <React.Fragment>
                    <h2 className={styles.subHeading}>
                      {stringValues['transportation_access_provider']}
                    </h2>
                    <ul
                      className={`${styles.transportationAccommodations} padding-0`}
                    >
                      {selectedSite.transportation_accommodations.map((ta) => (
                        <li
                          className="align-center flex margin-bottom-xs"
                          key={ta.name}
                        >
                          {ta.name === 'Near public transportation' ? (
                            <img src="/assets/icons/icn_bus.png" />
                          ) : ta.name === 'Parking available' ? (
                            <img src="/assets/icons/icn_parking.png" />
                          ) : (
                            <img src="/assets/icons/icn_bus.png" />
                          )}
                          {ta.name}
                        </li>
                      ))}
                    </ul>
                  </React.Fragment>
                )}
              </React.Fragment>
            )}
          </div>

          {isMobile && (
            <SlotSection
              {...{ slotData, stringValues, onClickSlot }}
              filtersApplied={formValues}
              outOfTravelRadius={provider.out_of_travel_radius}
            />
          )}
          {!crumbs && (
            <div className="navigation-buttons-space clear">
              <button type="button" className="btn-back" onClick={goBack}>
                {stringValues['back_button']}
              </button>
            </div>
          )}
        </div>
      </div>
      <FilterSection
        handleSubmit={handleSubmit}
        handleReset={handleReset}
        fieldsData={filterFieldsData}
        initialValues={initialValues}
        stringValues={stringValues}
        headerFilterOpen={headerFilterOpen}
        handleHeaderFilterToggle={handleHeaderFilterToggle}
      >
        {!isMobile && (
          <SlotSection
            {...{ slotData, stringValues, onClickSlot }}
            filtersApplied={formValues}
            outOfTravelRadius={provider.out_of_travel_radius}
          />
        )}
      </FilterSection>
    </React.Fragment>
  );
};

export default ProviderDrillDown;
