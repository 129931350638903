import moment from 'moment';
import React, { useCallback, useMemo } from 'react';
import { CheckBox } from '../../../../components/inputs';
import DateField from '../../../../components/inputs/DateField';
import SliderField from '../../../../components/inputs/SliderField';
import TypeAhead from '../../../../components/inputs/TypeAhead';
import MultiSelectField from '../../../../components/inputs/MultiSelectField';

import FilterWrapper from './FilterWrapper';
import styles from './filter_section.module.scss';
import { FormikSingleSelect as SingleSelectField } from '../../../../components/inputs/SingleSelectField';

const validate = (values) => {
  const errors = {};
  if (values.start_time && !values.end_time) {
    errors.end_time = 'Please enter end time';
  }
  if (!values.start_time && values.end_time) {
    errors.start_time = 'Please enter start time';
  }
  return errors;
};

const getSpecialtyMinSelected = (params, fieldsData) => {
  if (fieldsData.pdo_flag) return [];
  if (
    params.specialty_id &&
    fieldsData.specialties &&
    fieldsData.specialties.options
  ) {
    return fieldsData.specialties.options
      .filter((option) => option.value === params.specialty_id)
      .map((option) => option.value);
  }
  return [];
};

const FilterSection = ({
  handleSubmit,
  handleReset,
  fieldsData,
  params,
  initialValues = {},
  stringValues,
  headerFilterOpen,
  handleHeaderFilterToggle,
}) => {
  const specialty_field_name = fieldsData.pdo_flag
    ? 'training_ids'
    : 'specialty_id';
  const specialty_options =
    (fieldsData.pdo_flag
      ? fieldsData.trainings && fieldsData.trainings.options
      : fieldsData.specialties && fieldsData.specialties.options) || [];
  const specialty_min_selected = getSpecialtyMinSelected(params, fieldsData);
  return (
    <FilterWrapper
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validate={validate}
      handleReset={handleReset}
      stringValues={stringValues}
      isFilterOpen={headerFilterOpen}
      setIsFilterOpen={handleHeaderFilterToggle}
    >
      {({ setFieldValue, values }) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const onStartDateChange = useCallback(
          (value) => {
            if (
              value &&
              moment(values.end_date, 'MM/DD/YYYY').isBefore(
                moment(value, 'MM/DD/YYYY'),
              )
            ) {
              setFieldValue('end_date', value);
            }
          },
          [values.end_date],
        );
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const minEndDate = useMemo(
          () => moment(values.start_date, 'MM/DD/YYYY').toDate(),
          [values.start_date],
        );
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const minStartDate = useMemo(
          () => moment().add(2, 'days').toDate(),
          [],
        );
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const maxStartDate = useMemo(
          () => moment().add(90, 'days').toDate(),
          [],
        );
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const maxEndDate = useMemo(
          () =>
            moment(values.start_date, 'MM/DD/YYYY').add(90, 'days').toDate(),
          [values.start_date],
        );

        // eslint-disable-next-line react-hooks/rules-of-hooks
        const onStartTimeChange = useCallback(
          (value) => {
            if (
              moment(values.end_time, 'h:mm a').isBefore(
                moment(value, 'h:mm a'),
              )
            ) {
              setFieldValue('end_time', value);
            }
          },
          [values.end_time],
        );
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const onEndTimeChange = useCallback(
          (value) => {
            if (
              moment(value, 'h:mm a').isBefore(
                moment(values.start_time, 'h:mm a'),
              )
            ) {
              setFieldValue('end_time', values.start_time);
            }
          },
          [values.start_time],
        );

        // eslint-disable-next-line react-hooks/rules-of-hooks
        const defaultPatientLocation = useMemo(
          () =>
            values.patient_location
              ? [{ displayString: values.patient_location }]
              : [],
          [values.patient_location],
        );
        const months = Object.values(stringValues.month_full || {});
        const days = Object.values(stringValues.day || {});

        return (
          <React.Fragment>
            <h2>{stringValues.filter_provider_list}</h2>
            <fieldset className={styles.dateRangeWrap}>
              <legend className="prompt">{stringValues.date_range}</legend>
              <div className="flex align-items-start">
                <DateField
                  name="start_date"
                  placeholder="MM/DD/YYYY"
                  onChange={onStartDateChange}
                  minDate={minStartDate}
                  maxDate={maxStartDate}
                  fallback={values.start_date}
                  locale={{
                    localize: {
                      month: (n) => months[n],
                      day: (n) => days[n],
                    },
                    formatLong: {},
                    match: () => null,
                  }}
                />
                <span className="margin-top-xs">
                  &nbsp;&nbsp;{stringValues.to}&nbsp;&nbsp;
                </span>
                <DateField
                  name="end_date"
                  placeholder="MM/DD/YYYY"
                  popperPlacement="bottom-end"
                  minDate={minEndDate}
                  maxDate={maxEndDate}
                  fallback={values.end_date}
                  locale={{
                    localize: {
                      month: (n) => months[n],
                      day: (n) => days[n],
                    },
                    formatLong: {},
                    match: () => null,
                  }}
                />
              </div>
            </fieldset>
            <fieldset className={styles.dateRangeWrap}>
              <legend className="prompt">{stringValues.time_range}</legend>
              <div className="flex align-items-start">
                <DateField
                  showTimeSelect
                  showTimeSelectOnly
                  name="start_time"
                  onChange={onStartTimeChange}
                  placeholder="HH:MM"
                  timeIntervals={15}
                  dateFormat="h:mm a"
                  saveFormat="HH:mm:ss[.000]"
                />
                <span className="margin-top-xs">
                  &nbsp;&nbsp;{stringValues.to}&nbsp;&nbsp;
                </span>
                <DateField
                  showTimeSelect
                  showTimeSelectOnly
                  name="end_time"
                  onChange={onEndTimeChange}
                  placeholder="HH:MM"
                  timeIntervals={15}
                  dateFormat="h:mm a"
                  saveFormat="HH:mm:ss[.000]"
                />
              </div>
            </fieldset>
            <fieldset>
              <TypeAhead
                name="patient_location"
                label={stringValues.distance_from}
                placeholder={stringValues.start_typing_address}
                url="/seach_address?q="
                getOptions={(r) => r.data.results}
                valueKey="displayString"
                labelKey={(option) => `${option.displayString}`}
                getValue={(value) => (value[0] ? value[0].displayString : '')}
                defaultOptions={defaultPatientLocation}
                emptyLabel={stringValues.no_matches_found}
                searchText={stringValues.searching}
                helpText={stringValues.enter_two_or_more_chars}
                promptText={stringValues.type_to_search}
                onSelection={(value) => {
                  setFieldValue(
                    'patient_location_state_code',
                    value[0]?.place?.properties?.stateCode,
                  );
                  setFieldValue(
                    'patient_location_longitude',
                    value[0]?.place?.geometry?.coordinates?.[0],
                  );
                  setFieldValue(
                    'patient_location_latitude',
                    value[0]?.place?.geometry?.coordinates?.[1],
                  );
                }}
              />
              <SliderField
                name="distance"
                label=""
                min={0}
                max={5}
                sliderLabels={{
                  0: `1 ${stringValues.mile}`,
                  1: '5',
                  2: '10',
                  3: '20',
                  4: '25',
                  5: '50',
                }}
                sliderValues={{
                  0: '1',
                  1: '5',
                  2: '10',
                  3: '20',
                  4: '25',
                  5: '50',
                }}
                wrapperClassName={styles.slider}
              />
              <fieldset>
                <legend className={`${styles.appointmentTypeLabel} prompt`}>
                  {stringValues.appointment_type}
                </legend>
                <div className="flex-start">
                  <CheckBox
                    label={stringValues.in_person}
                    name="in_person"
                    className="switch-checkbox"
                    icon={
                      <i
                        className={`${styles.slotTypeIcon} fa fa-user`}
                        aria-label="in person time slot"
                      />
                    }
                  />
                  <CheckBox
                    label={stringValues.online}
                    name="virtual"
                    className="switch-checkbox"
                    icon={
                      <i
                        className={`${styles.slotTypeIcon} fa fa-video-camera`}
                        aria-label="online time slot"
                      />
                    }
                  />
                </div>
              </fieldset>
              <SingleSelectField
                label={stringValues.provider_gender_preference}
                placeholder={stringValues.select}
                name="sex_id"
                options={fieldsData.sexes?.options || []}
                addPlaceholderOption
              />
              <MultiSelectField
                lucetTheme
                label={stringValues.provider_language_preference}
                placeholder={stringValues.select}
                name="language_id"
                options={fieldsData.languages?.options || []}
                noOptionsMessageString={stringValues.no_options}
                valueKey="value"
                labelKey="label"
              />
              <MultiSelectField
                lucetTheme
                label={stringValues.provider_types_of_therapy}
                placeholder={stringValues.select}
                name="treatment_type_id"
                options={
                  (fieldsData.therapy_types &&
                    fieldsData.therapy_types.options) ||
                  []
                }
                noOptionsMessageString={stringValues.no_options}
                valueKey="id"
                labelKey="name"
              />
              <MultiSelectField
                lucetTheme
                label={stringValues.provider_specialities}
                placeholder={stringValues.select}
                name={specialty_field_name}
                options={specialty_options}
                minSelected={specialty_min_selected}
                noOptionsMessageString={stringValues.no_options}
              />
              <SingleSelectField
                label={stringValues.transportation_access}
                placeholder={stringValues.select}
                name="transportation_access_id"
                options={fieldsData.transportation?.options || []}
                valueKey="id"
                labelKey="name"
                addPlaceholderOption
              />
              <SingleSelectField
                label={stringValues.provider_education_preference}
                placeholder={stringValues.select}
                name="degree_id"
                options={fieldsData.education?.options || []}
                valueKey="value"
                labelKey="label"
                addPlaceholderOption
              />
              <MultiSelectField
                lucetTheme
                label={stringValues.provider_years_in_practice}
                placeholder={stringValues.select}
                name="years_in_practice"
                options={fieldsData.years_in_practice?.options || []}
                noOptionsMessageString={stringValues.no_options}
                valueKey="value"
                labelKey="label"
              />
              <MultiSelectField
                lucetTheme
                label={stringValues.provider_ethnicity_preference}
                placeholder={stringValues.select}
                name="ethnicity_ids"
                options={fieldsData.ethnicity?.options || []}
                noOptionsMessageString={stringValues.no_options}
                valueKey="value"
                labelKey="label"
              />
              <SingleSelectField
                label={stringValues.provider_race_preference}
                placeholder={stringValues.select}
                name="race_id"
                options={fieldsData.races?.options || []}
                addPlaceholderOption
              />
            </fieldset>
          </React.Fragment>
        );
      }}
    </FilterWrapper>
  );
};

export default FilterSection;
