import React, { useCallback, useMemo } from 'react';
import {
  ReactSelect,
  customStyle as ReactSelectCustomStyle,
} from './ReactSelect';
import wrapField from './wrapField';
import FieldError from './FieldError';

const MultiSelectField = ({
  options,
  placeholder,
  customClass,
  showRequiredLabel,
  disabled,
  minSelected = [],
  valueKey = 'value',
  labelKey = 'label',
  noOptionsMessageString = 'No options',
  lucetTheme,
  field: { value = [] },
  label,
  meta: { touched, error },
  helpers: { setValue },
  menuPlacement = 'auto',
  customStyles = {},
}) => {
  const selected = useMemo(
    () => options.filter((option) => value.includes(option[valueKey])),
    [options, value, valueKey],
  );
  const noOptionsMessage = useCallback(
    () => noOptionsMessageString,
    [noOptionsMessageString],
  );
  const sanitizedCustomStyles =
    ReactSelectCustomStyle.sanitizeCustomStyles(customStyles);

  return (
    <div
      className={`${error ? 'has-error' : ''} ${
        touched ? 'touched' : ''
      } field-wrapper-inner ${customClass || ''}`}
    >
      <fieldset>
        {label && (
          <legend className="prompt">
            <span>
              {showRequiredLabel ? (
                <span className="required-star">* </span>
              ) : null}
              {label}
            </span>
          </legend>
        )}
        <div className="prompt">
          <ReactSelect
            value={selected}
            onChange={(val, meta) => {
              if (
                meta.action === 'select-option' &&
                meta.option.is_none_of_the_above
              ) {
                val = [meta.option];
              } else {
                val = val.filter((o) => !o.is_none_of_the_above);
              }
              const newVal = [
                ...new Set([...minSelected, ...val.map((e) => e[valueKey])]),
              ];
              setValue(newVal);
            }}
            blurInputOnSelect={false}
            // onBlur={() => input.onBlur()}
            options={options}
            getOptionLabel={(option) => option[labelKey]}
            getOptionValue={(option) => option[valueKey]}
            placeholder={placeholder || 'Select options'}
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            className="react-select"
            isMulti
            isClearable={false}
            isDisabled={disabled}
            theme={(theme) => ({
              ...theme,
              borderRadius: 0,
              colors: {
                ...theme.colors,
                primary: lucetTheme ? '#851c55' : '#69ad24',
              },
            })}
            classNamePrefix="custom-react-select"
            noOptionsMessage={noOptionsMessage}
            menuPlacement={menuPlacement}
            styles={sanitizedCustomStyles}
          />

          <FieldError {...{ error, touched }} />
        </div>
      </fieldset>
    </div>
  );
};

export default wrapField(MultiSelectField);
