import axios from 'axios';
import { csrfHeader } from '../../helperFunctions';
import {
  createVisitCancellationRequestTransaction,
  confirmTransactionByPolling,
} from './transaction';

const cancelAppointmentApi = (encounterId, mode) => {
  const url =
    mode === 'call_center'
      ? '/cancel_appointment'
      : '/member_self_serve/update_status';
  return axios
    .patch(
      `${url}?id=${encounterId}`,
      {
        visit_status: 'cancelled',
        pageName: 'Member Self-Serve Page',
      },
      {
        csrfHeader,
      },
    )
    .then((res) => res.data);
};

const cancelAppointment = async ({
  mode,
  dsboCancellationConfirmationEnabled,
  encounterId,
  hasIdentifiers,
  patientId,
  cancelOnlyOnExternalCalendar,
  pollingTimeout,
}) => {
  if (dsboCancellationConfirmationEnabled && hasIdentifiers) {
    const transaction = await createVisitCancellationRequestTransaction({
      encounterId,
      patientId, // optional - only for mss
      mode,
    });
    await confirmTransactionByPolling({
      transactionId: transaction.transaction_id,
      patientId, // optional - only for mss
      pollingTimeout,
    }).catch(() => {
      throw new Error('Could not cancel the external appointment.');
    });
  }
  if (cancelOnlyOnExternalCalendar) {
    return null;
  }
  const response = await cancelAppointmentApi(encounterId, mode);
  return response;
};

export default cancelAppointment;
