import axios from 'axios';
const headers = { 'X-CSRF-Token' : $('meta[name="csrf-token"]').attr('content')}

const LOAD = 'LOAD';

export const load = data => ({ type: LOAD, data });

export const loadFetchedClinicalSummary = data => ({ type: 'LOAD_CLINICAL_SUMMARY', data });
export const loadActiveData = data => ({ type: 'SET_ACTIVE_DATA', data });
export const loadFilterInitialValues = data => ({ type: 'SET_FILTER_VALUES', data });
export const loadModalFormInitialValues = data => ({ type: 'SET_MODAL_FORM_VALUES', data });

export const loadEvents = ({
  from,
  to,
  category,
  data,
  counts
}) => ({
  type: "LOAD_EVENTS",
  from,
  to,
  category,
  data,
  counts
});
// export const loadEvents = data => ({ type: 'LOAD_EVENTS', data });
export const loadAppointments = data => ({ type: 'LOAD_APPOINTMENTS', data });
export const loadAvailabilities = data => ({ type: 'LOAD_AVAILABILITIES', data });
export const loadCases = data => ({ type: 'LOAD_CASES', data });
export const loadDashboardData = data => ({ type: 'LOAD_DASHBOARD_DATA', data });
export const loadTherapyGroups = data => ({ type: 'LOAD_THERAPY_GROUPS', data });
export const loadSamlConfigurations = data => ({ type: 'LOAD_SAML_CONFIGURATIONS', data });
// export const loadCalculatedAvailabilities = data => ({ type: 'LOAD_CALCULATED_AVAILABILITIES', data });

export const loadCalculatedAvailabilities = ({
  from,
  to,
  category,
  data,
  counts
}) => ({
  type: "LOAD_CALCULATED_AVAILABILITIES",
  from,
  to,
  category,
  data,
  counts
});


export const setLoader = visibility => ({
  type: 'SET_LOADER',
  visibility
})
export const setActiveComponent = component => ({
  type: 'SET_ACTIVE_COMPONENT',
  component
})

const requestData = () => ({ type: 'LOADING' });

function receiveData(subreddit, json) {
  return {
    type: 'RECEIVE_DATA',
    subreddit,
    posts: json.data.children.map(child => child.data),
    receivedAt: Date.now()
  }
}
// /api/v1/clinical-summaries/a50707a3-f0e6-4c08-8307-6b8c6d795871
export function fetchClinicalSummary(id) {
  console.log('FETCH POSTS');
  return dispatch => {
    dispatch(requestData());
    dispatch(setLoader(true));

    return axios.get(`/api/v1/clinical-summaries/${id}`)
      .then((response)=>{console.log('saved data',response);return response})
      .then(response => {
        dispatch(loadFetchedClinicalSummary(response.data));
        return response.data.responses.reduce((map, obj) => (map[obj.question_tag] = obj.value, map), {})
      })
      .then(data => dispatch(load(data)))
      .then(() => dispatch(setLoader(false)))

  }
}
export function createNFetchClinicalSummary(userMetaData) {
  return dispatch => {
    dispatch(setLoader(true));
    return axios
      .post('/api/v1/clinical-summaries/', userMetaData, {headers: headers})
      .then(response => {
        dispatch(loadFetchedClinicalSummary(response.data));
        return response;
      })
      .then(response => {
        const data = response.data.responses.reduce((map, obj) => (map[obj.question_tag] = obj.value, map), {})
        dispatch(load(data));
        dispatch(setLoader(false))
        return response.data;
      })
  }
}




export function fetchPosts(subreddit) {
  console.log('FETCH POSTS');
  return dispatch => {
    dispatch(requestData(subreddit));
    dispatch(setLoader(true));
    return fetch(`https://www.reddit.com/r/${subreddit}.json`)
      .then(response => response.json())
      .then(json => dispatch(receiveData(subreddit, json)))
      .then(() => dispatch(setActiveComponent('EditNote2')))
      .then(() => dispatch(setLoader(false)))
  }
}

export function fetchPostsIfNeeded(subreddit) {
  return (dispatch, getState) => {
      return dispatch(fetchPosts(subreddit))
  }
}