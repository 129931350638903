import React from 'react';
import Slot from './Slot';

import styles from './slot.module.scss';
import MoreSlotsButton from './MoreSlotsButton';
import { noop } from '../../../../../helperFunctions';

const Slots = ({
  slots,
  stringValues,
  onClick,
  filtersApplied,
  outOfTravelRadius,
  showMoreSlotsButton = false,
  onMoreSlotsClick = noop,
}) => (
  <div className={styles.slotsWrapper}>
    {slots.map((slot) => (
      <Slot
        key={slot.id}
        slot={slot}
        onClick={onClick}
        stringValues={stringValues}
        filtersApplied={filtersApplied}
        outOfTravelRadius={outOfTravelRadius}
      />
    ))}
    {showMoreSlotsButton && (
      <MoreSlotsButton onClick={onMoreSlotsClick} stringValues={stringValues} />
    )}
  </div>
);

export default Slots;
