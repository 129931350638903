import React from 'react';
import clsx from 'clsx';
import BookAppointment from './BookAppointment';

const ButtonWrapper = ({
  styles,
  limits,
  startAssessmentUrl,
  memberSelfServeId,
  assessmentButtonTexts,
}) =>
  limits && limits.showStartResumeAssessmentButton ? (
    // eslint-disable-next-line react/jsx-no-comment-textnodes
    <div className={styles.bookAppointmentWrapper}>
      <a
        href={startAssessmentUrl}
        className={clsx('btn btn-primary', styles.startAssessmentButton, {
          resume_assessment: limits.hasInProgressScreener,
          start_assessment: !limits.hasInProgressScreener,
        })}
      >
        {limits && limits.hasInProgressScreener
          ? assessmentButtonTexts.resume_assessment
          : assessmentButtonTexts.start_assessment}
      </a>
    </div>
  ) : limits && limits.showBookAppointmentButton ? (
    <div className={styles.bookAppointmentWrapper}>
      <BookAppointment
        styles={styles}
        assessmentButtonTexts={assessmentButtonTexts}
        bookingInstructions={limits.bookingInstructions}
        memberSelfServeId={memberSelfServeId}
      />
    </div>
  ) : null;

export default ButtonWrapper;
