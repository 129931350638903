import React from "react"
import DialogModal from "../../../components/DialogModal";
import CustomHtmlPrompt from "../../../components/inputs/CustomHtmlPrompt";

class PostThankyou extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
  }
 
  render() {
    const {
      thankyouHtmlSafe,
      id,
      thankyouText,
      postThankYou1,
      postThankYou2,
      postThankYou3,
      postStudyGroup,
    } = this.props;
  
    return (
          <div id="thank_you">
          <div>   
            <div>
               {thankyouText ?
                <p>
                  {thankyouHtmlSafe}
                </p>
                :
                <div>
                <h2 style={{fontSize:'38px'}}>{postThankYou1}</h2>
                {postStudyGroup &&               
                 <p>
                   <CustomHtmlPrompt label={postThankYou2} />
                 </p>
                }
                <p><CustomHtmlPrompt label={postThankYou3} /></p>
                </div>
               }
                </div>              
             <div>
             </div>
             </div>
      </div>
    );
  }
}

export default PostThankyou