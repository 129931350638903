import axios from 'axios';
import { noop } from '../../helperFunctions';

const haveFutureAppointments = (
  patientId,
  userId,
  { success = noop, failed = noop, always = noop },
) => {
  axios
    .get(`/admin/have_future_appointments`, {
      params: {
        user_id: userId,
        patient_id: patientId,
      },
    })
    .then(success)
    .catch(failed)
    .finally(always);
};

const hidePatient = (
  patientId,
  userId,
  { success = noop, failed = noop, always = noop },
) => {
  axios
    .post(`/admin/hide_patients`, {
      patient_id: patientId,
      current_user_id: userId,
    })
    .then(success)
    .catch(failed)
    .finally(always);
};

const removePatient = (
  patientId,
  userId,
  { success = noop, failed = noop, always = noop },
) => {
  axios
    .patch(`/api/v1/users/${userId}/patients/${patientId}`, {
      user: { active: false },
    })
    .then(success)
    .catch(failed)
    .finally(always);
};

const addPatientLog = (patientId, data) =>
  axios.post(`/add_patient_log?patient_id=${patientId}`, data);

const getPatientNotes = (patientId, trackId) =>
  axios.get(
    `/admin/patients/${patientId}/patient_notes?track_id=${trackId}&with_metadata=true`,
  );

const addPatientNoteAttachment = (file, patientId, trackId) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('patient_id', patientId);
  formData.append('track_id', trackId);
  return axios({
    method: 'POST',
    url: '/admin/create_note_attachments',
    data: formData,
  });
};

const getPatientFreeformNote = (patientId, noteId) =>
  axios.get(
    `/filters/patient_freeform_note?id=${noteId}&patient_id=${patientId}`,
  );
const savePatientFreeformNote = (patientId, data) =>
  axios.post(`/admin/patients/${patientId}/save_patient_note`, data);

const deletePatientNote = async (patientId, noteId) =>
  axios.delete(`/admin/patients/${patientId}/delete_patient_note`, {
    data: { patient_note_id: noteId },
  });

const regeneratePatientNoteReport = (reportId) =>
  axios.get(`/admin/generate_notes?id=${reportId}`);

const startProctorToPatientDetails = (
  patientId,
  surveyId,
  surveyDefinitionTag,
  viewType,
  siteId,
) =>
  axios.get(`/admin/admin_assessment`, {
    params: {
      patient_id: patientId,
      survey_id: surveyId,
      tag: surveyDefinitionTag,
      view_type: viewType,
      site_id: siteId,
      skip_partial: true,
    },
  });

const getAdminSurveyQuestions = (patientId, surveyId, view_type, lite) =>
  axios.get(`/admin/patients/${patientId}/survey_questions`, {
    params: {
      survey_id: surveyId,
      mode: 'admin_view',
      view_type,
      lite,
    },
  });

const getSurveyResponses = (patientId, surveyId) =>
  axios.get(
    `/admin/patients/${patientId}/survey_responses?survey_id=${surveyId}`,
  );

const deleteSurveyQuestionResponse = async (questionId, surveyId, isProxy) =>
  axios.post(`/admin/delete_responses`, {
    id: questionId,
    survey_id: surveyId,
    is_proxy: isProxy,
  });

const deleteSurveyQuestionResponses = async (questionData, surveyId) =>
  axios.post(`/admin/delete_responses`, {
    question_data: questionData,
    survey_id: surveyId,
  });

const submitSurveyQuestionResponses = async (
  surveyId,
  page,
  surveyDefinitionQuestionId,
  value,
) => {
  let responses = [
    {
      survey_definition_question_id: surveyDefinitionQuestionId,
      value,
    },
  ];
  if (value && Array.isArray(value)) {
    responses = [];
    if (value.length > 0) {
      value.forEach((v) => {
        responses.push({
          survey_definition_question_id: surveyDefinitionQuestionId,
          value: v,
        });
      });
    } else {
      responses.push({
        survey_definition_question_id: surveyDefinitionQuestionId,
        value: null,
      });
    }
  }
  const data = {
    survey_id: surveyId,
    is_admin_view: true,
    responses,
  };
  return axios.patch(`/api/v1/surveys/${surveyId}/pages/${page}`, data);
};

const pauseSurveyAt = (surveyId, page) =>
  axios.post(`/admin/pause_page`, { survey_id: surveyId, page });

const submitAdminSurvey = (surveyId, adminAction, override) =>
  axios.post(`/admin/save_admin_survey`, {
    survey_id: surveyId,
    admin_action: adminAction,
    lite: true,
    override,
  });

export {
  haveFutureAppointments,
  hidePatient,
  removePatient,
  addPatientLog,
  getPatientNotes,
  addPatientNoteAttachment,
  getPatientFreeformNote,
  savePatientFreeformNote,
  deletePatientNote,
  regeneratePatientNoteReport,
  startProctorToPatientDetails,
  getAdminSurveyQuestions,
  getSurveyResponses,
  deleteSurveyQuestionResponse,
  deleteSurveyQuestionResponses,
  submitSurveyQuestionResponses,
  pauseSurveyAt,
  submitAdminSurvey,
};
