import React from "react"
import DialogModal from "../../../components/DialogModal";
import {Formik, Form, Field} from 'formik';
import {TextField, SelectField, DateTextField} from "../../../components/inputs";
import {masks, validations, csrfHeader} from "../../../../helperFunctions";
import {formValidations} from "../../../helpers/moduleHelper";
import bowser from "bowser";
import axios from 'axios';

class RegisterMRNForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {modal: {}, showNextButton: false}

  }

  handleSubmit = (values, validate, url) => {
    const {submitURL, patientAutoCreate, setLoader} = this.props;
    setLoader(true);
    if (validate === false && values) {
      values = {mrn: values.mrn, dob: values.dob, check_mrn: values.check_mrn ? "true" : "false", survey_definition: values.survey_definition}
    }
    url += (bowser.msie || validate === false) ? '.json' : '';
    return axios({
      method: validate ? 'get' : 'post',
      url: url,
      params: validate ? values : null,
      data: validate ? null : values,
      headers: csrfHeader,
    })
      .then(response => {
        if (validate) {
          if (response && response.data && (response.data.flag === 'true')) {
              this.handleSubmit(values, false, submitURL).then(r => {
                // do nothing
              })
          } else if (response && response.data) {
            this.openModal("DialogModal");
            setTimeout(() => this.goToWelcomePage(),20000);
          }
        } else {
          if (response && response.data && response.data.errors) {
            setLoader(false);
            alert(response.data.errors);
          } else {
            if (response.status === 200 || response.status === 302 || response.data.location) {
              window.location.href = response.data.location || response.request.responseURL;
            }
          }
        }
      })
      .catch(err => {
        setLoader(false);
        const msg =
          err.response && err.response.data && err.response.data.message
            ? err.response.data.message
            : err;
        alert(msg);
      });
  }

  goToWelcomePage = () => {
    window.location.href = "/welcome"
  }

  handleBackButton = () => {
    const {backPath} = this.props;
    window.location.href = backPath ? backPath : "/welcome";
  }

  openModal = (name) => {
    const {setLoader} = this.props;
    setLoader(false);
    document.body.classList.add("modal-open");
    this.setState({
      modal: Object.assign({}, this.state.modal, {
        [name]: true,
      })
    });
  };

  closeModal = (name) => {
    document.body.className = document.body.className.replace(
      'modal-open',
      ''
    );
    this.setState({
      modal: Object.assign({}, this.state.modal, {
        [name]: false
      }),
    });
  };

  validate = (values) => {
    const {mrnRegex, showDOB, locale, mrnError} = this.props;
    const fieldErrorMessage = formValidations.fieldErrorMessage(locale);

    this.props.handleUnloadUpdates(true);
    let errors = {};
    if (!values.mrn) {
      errors.mrn = fieldErrorMessage;
    } else if(formValidations.mrn(values.mrn, mrnRegex, locale)){
      errors.mrn = mrnError;
    }
    if (values.survey_definition === null || values.survey_definition === undefined || values.survey_definition.length === 0) {
      errors.survey_definition = fieldErrorMessage;
    }
    if(showDOB) {
      let dobErrors = !values.dob ? fieldErrorMessage : formValidations.dob(values.dob, "MM/DD/YYYY" , locale);
      if (dobErrors) {
        errors.dob = dobErrors;
      }
    }
    
    this.setState({showNextButton: Object.keys(errors).length === 0})
    return errors;
  }

  render() {
    const {
      pageName, organization, site, mrnLabel, mrnRegex, mrn, showDOB, dobLabel, dobValue,
      surveyDefinitionOptions, surveyDefinitionLabel, nextButtonLabel, backButtonLabel,
      surveyDefinitionPlaceholder, surveyDefinitionValue, checkMRN, patientAutoCreate, submitURL
    } = this.props;
    const {modal, showNextButton} = this.state;
    return (
      <div>
        <Formik
          initialValues={{
            org_id: organization && organization.id,
            site_id: site && site.id,
            dob: dobValue,
            mrn: mrn,
            survey_definition: (formValidations.isNotNull(surveyDefinitionValue) === true) ? surveyDefinitionValue : '',
            check_mrn: checkMRN
          }}
          onSubmit={(values, {setSubmitting}) => {
            this.props.handleUnloadUpdates(false);
            setTimeout(() => {
              const url = patientAutoCreate ? '/qualify_mrn' : submitURL;
              this.handleSubmit(values, patientAutoCreate, url).then(r => {

              })
              setSubmitting(false);
            }, 400);
          }}
          validate={this.validate}
        >
          {props => (
            <Form className="user_match" aria-label="Enter MRN">
              <div className={`rows`}>
                <div className="form-group row clear">
                  <div className="col-md-6 col-sm-10">
                    <TextField
                      label={mrnLabel}
                      name="mrn"
                      type="text"
                      inputClass={`form-control`}
                    />
                  </div>
                </div>
                {showDOB && (
                  <div className="form-group row clear">
                    <div className="col-md-6 col-sm-10">
                      <DateTextField
                        label={dobLabel}
                        name="dob"
                        type="text"
                        placeholder="MM/DD/YYYY"
                        mask={`MM/DD/YYYY`}
                        inputClass={`form-control input`}
                        setFieldValue={props.setFieldValue}
                      />
                    </div>
                  </div>
                )}
                {(surveyDefinitionOptions && surveyDefinitionOptions.length > 1) && (
                  <div className="form-group row clear">
                    <div className="col-md-6 col-sm-10">
                      <SelectField
                        label={surveyDefinitionLabel}
                        name="survey_definition"
                        className={`form-control input`}
                        placeholder={surveyDefinitionPlaceholder}
                        options={(surveyDefinitionOptions) || []}
                      />
                    </div>
                  </div>
                )}
              </div>
              <div className="navigation-buttons-space clear">
                {showNextButton && (
                                <button type="submit" className={`btn-next flt-rgt sub-form`} name=" action" id="
                                                    btn-next">{nextButtonLabel}</button>
                                )}
                <button type="button" onClick={this.handleBackButton}
                        className={`btn-back flt-lft`}>{backButtonLabel}</button>
              </div>
            </Form>
          )}
        </Formik>
        {modal.DialogModal && (
          <DialogModal
            {...this.props}
            closeModal={() => {
              this.closeModal('DialogModal');
              this.goToWelcomePage();
            }}
            saveModal={() => {
              this.closeModal('DialogModal');
              this.goToWelcomePage();
            }}
          />
        )}
      </div>
    );
  }
}

export default RegisterMRNForm