import React from "react"
import CustomHtmlPrompt from "../../../components/inputs/CustomHtmlPrompt";


class BeaconThankyou extends React.Component {
  constructor(props) {
    super(props);
    this.state = {modal: {}, nextButtonClass: 'display-none'}
  }

  render() {
    const {
        survey,
        thankYou,
        beaconThankYou3,
        beaconThankYou4,
        thankYouBody,
        surveyPresent,
        thankyouText,
        thankyouHtmlSafe,
        optinThankyou
    } = this.props;
    
    return (
      <div> 
      {surveyPresent ?
      <div id="thank_you">     
      <div className="page-inner-content" role="main">
      {thankyouText ?
      <div>
          <p>
            <CustomHtmlPrompt label={thankyouHtmlSafe} />
          </p>
          <p><CustomHtmlPrompt label={thankYouBody} /></p>
          </div>
         :
         <div>
         <h2>{thankYou}</h2>
         <p style={{lineHeight:1}}>{beaconThankYou3}</p>
         <p style={{lineHeight:1.25}}>{beaconThankYou4}</p>
         </div>
        }
      </div>      
    </div>
    :
    <div tabIndex="0" dangerouslySetInnerHTML={{__html:optinThankyou}}></div>
    }
   </div>
    );
  }
}

export default BeaconThankyou