import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { Formik, Form, Field, useFormikContext } from 'formik';
import Accordion from '../Accordion';
import classes from './multireferral.module.scss';
import { delay, csrfHeader, validURL } from '../../helperFunctions';
import RequestLoader from '../UI/RequestLoader';
import { selectBox } from '../inputs';
import SendAssetsModal from '../SendAssetsModal';

const MultiReferral = ({
  referralOutcomes,
  surveyId,
  submitPath,
  smsStatus,
  organizationId,
  saveNotes,
  canSelectReferralInstructions,
  pathway,
  isDFD = false,
  chooseYourOwn = false,
  stringValues,
}) => {
  const [accordionData, setAccordionData] = useState({});
  const [loader, setLoader] = useState(false);
  const [messageCount, setMessageCount] = useState();
  const [formValues, setFormValues] = useState({});
  const [pollParams, setPollParams] = useState({});
  const [timer, setTimer] = useState(1);
  const [modal, setModal] = useState(false);

  const [populatedOutcome, setPopulatedOutcome] = useState();
  const formRef = useRef();
  const connectionTimeout = 120;

  const getAccordionData = (val) => {
    setLoader(true);
    return axios.get(`/admin/referral_home/options/${val}`).then((r) => {
      setAccordionData(r.data.data);
      setTimeout(() => {
        setLoader(false);
      }, 500);
    });
  };

  const pollingCall = (params) =>
    axios({
      method: 'GET',
      url: '/call_centers/transaction_status',
      params: {
        object_id: params.object_id,
        object_name: params.object_name,
        organization_id: organizationId,
        subscriber_id: formValues.referral_option_id,
      },
    }).then((r) => {
      if (r.data.status === 'success') {
        window.location.href = `/call_centers/transaction_result?result=success&survey_id=${surveyId}&referral_option_id=${formValues.referral_option_id}`;
        clearInterval(connectionTimer);
      } else if (timer < connectionTimeout && r.data.status === 'failed') {
        setMessageCount(messageCount + 1);
      } else
        window.location.href = `/call_centers/transaction_result?survey_id=${surveyId}&referral_option_id=${formValues.referral_option_id}`;
    });

  const linkIsExternal = (linkElement) => {
    if (validURL(linkElement)) return linkElement.host !== window.location.host;
    return false;
  };

  useEffect(() => {
    if (!canSelectReferralInstructions) {
      setAccordionData({});
    }
  }, [canSelectReferralInstructions]);

  const pollSubmit = async (values) => {
    await delay(1000);
    const formValues = {
      dsbo: true,
      ...values,
    };
    const formData = new FormData();
    Object.keys(formValues).forEach((key) => {
      formData.append(key, formValues[key]);
    });

    return axios({
      method: 'post',
      url: submitPath,
      data: formData,
      headers: csrfHeader,
    }).then((res) => {
      if (res.data.success) {
        setPollParams(res.data);
        setTimer(timer + 1);
        pollingCall(res.data);
      }
    });
  };

  const handleSubmit = (values) => {
    const selectedOutcome = referralOutcomes.find(
      (item) => item.value === values.referral_instruction_id,
    );
    const selectedOption =
      (selectedOutcome &&
        Object.keys(selectedOutcome).length &&
        selectedOutcome.options.find(
          (item) => item.id === values.referral_option_id,
        )) ||
      {};

    if (selectedOption && selectedOption.id) {
      if (selectedOption.organization_associate) {
        setMessageCount(0);
      } else if (selectedOption.action === 'send_email') {
        setModal(true);
      } else if (linkIsExternal(selectedOption.action_button_path)) {
        window.open(selectedOption.action_button_path, '_blank');
        formRef.current.submit();
      } else {
        setLoader(true);
        formRef.current.submit();
      }
    } else if (selectedOutcome && selectedOutcome.value) {
      if (selectedOutcome.action == 'send_email') {
        setModal(true);
      } else {
        setLoader(true);
        formRef.current.submit();
      }
    }
  };

  useEffect(() => {
    if (messageCount === 0) pollSubmit(formValues);
    if (messageCount > 0)
      delay(5000).then(() => {
        pollingCall(pollParams);
      });
  }, [messageCount]);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimer(timer + 1);
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [timer]);

  useEffect(() => {
    const outcome = referralOutcomes.find(
      (outcome) => outcome.is_populate === true,
    );
    setPopulatedOutcome(outcome);
  }, [referralOutcomes]);

  return (
    <React.Fragment>
      <div className="comments-section-multi-referral">
        {isDFD !== true && <h3>Do you need to complete another referral?</h3>}
        <Formik
          initialValues={{
            referral_instruction_id: populatedOutcome
              ? populatedOutcome.value
              : '',
            survey_id: surveyId,
            complete: populatedOutcome ? populatedOutcome.complete_survey : '',
            default_specialty: populatedOutcome
              ? populatedOutcome.default_specialty
              : '',
            default_visit_type: populatedOutcome
              ? populatedOutcome.default_visit_type
              : '',
            action_button_path: populatedOutcome
              ? populatedOutcome.action_button_path
              : '',
            exclude_specialities: populatedOutcome?.exclude_specialities
              ? populatedOutcome?.exclude_specialities?.join(',')
              : '',
            referral_option_id: '',
            ...(saveNotes ? { save_notes: true } : ''),
            multi_referral: true,
          }}
          onSubmit={(values) => {
            setFormValues(values);
            setTimeout(() => {
              handleSubmit(values);
            }, 400);
          }}
          enableReinitialize
        >
          {({ values, setFieldValue }) => {
            const selectedOutcome = referralOutcomes.find(
              (item) => item.value === values.referral_instruction_id,
            );
            const referralOptions =
              (selectedOutcome &&
                Object.keys(selectedOutcome).length !== 0 &&
                selectedOutcome.options) ||
              [];
            const selectedOption =
              (selectedOutcome &&
                Object.keys(selectedOutcome).length &&
                selectedOutcome.options.find(
                  (item) => item.id === values.referral_option_id,
                )) ||
              '';
            const hiddenValues = selectedOption || selectedOutcome;
            const referralOptionsDisabled =
              !referralOptions.length || !values.referral_instruction_id;
            let selectPlaceholder = 'Select an additional referral outcome';
            if (isDFD && chooseYourOwn) {
              selectPlaceholder = 'Select provider referral type';
            }
            return (
              <Form
                aria-label="Multi Referral"
                className="margin-top-md referral-form multi-referral-form"
                method="post"
                action={submitPath}
                ref={formRef}
                id="target"
              >
                <input
                  type="hidden"
                  name="survey_id"
                  value={values.survey_id}
                />
                <input type="hidden" name="multi_referral" value />
                <input
                  type="hidden"
                  name="complete"
                  value={hiddenValues && hiddenValues.complete_survey}
                />
                {(selectedOption
                  ? !linkIsExternal(selectedOption.action_button_path)
                  : selectedOutcome) && (
                  <input
                    type="hidden"
                    name="action_button_path"
                    value={
                      (hiddenValues && hiddenValues.action_button_path) || ''
                    }
                  />
                )}
                <input
                  type="hidden"
                  name="default_visit_type"
                  value={
                    (hiddenValues && hiddenValues.default_visit_type) || ''
                  }
                />
                <input
                  type="hidden"
                  name="default_specialty"
                  value={(hiddenValues && hiddenValues.default_specialty) || ''}
                />
                <input
                  type="hidden"
                  name="exclude_specialities"
                  value={
                    hiddenValues && hiddenValues.exclude_specialities
                      ? hiddenValues.exclude_specialities?.join(',')
                      : ''
                  }
                />
                {populatedOutcome && (
                  <input
                    type="hidden"
                    name="referral_instruction_id"
                    value={values.referral_instruction_id}
                  />
                )}

                {saveNotes && <input type="hidden" name="save_notes" value />}
                <div className="filters-main-container">
                  <div className="filters-sub-container">
                    <div
                      className={`row-block margin-auto custom-dropdown  ${classes['referral-select']} plum-select-box`}
                    >
                      <Field
                        onChange={(outcome) => {
                          if (outcome) {
                            const selectedOutcome = referralOutcomes.find(
                              (item) => item.value === outcome,
                            );
                            setFieldValue('referral_option_id', '');
                            setFieldValue(
                              'complete',
                              selectedOutcome.complete_survey,
                            );
                            setFieldValue(
                              'action_button_path',
                              selectedOutcome.action_button_path,
                            );
                            setFieldValue(
                              'default_specialty',
                              selectedOutcome.default_specialty,
                            );
                            setFieldValue(
                              'default_visit_type',
                              selectedOutcome.default_visit_type,
                            );
                            setFieldValue(
                              'exclude_specialities',
                              selectedOutcome?.exclude_specialities
                                ? selectedOutcome?.exclude_specialities?.join(
                                    ',',
                                  )
                                : '',
                            );
                            setAccordionData({});
                          }
                        }}
                        options={referralOutcomes.map((item) => ({
                          label: item.label,
                          value: item.value,
                        }))}
                        placeholder={selectPlaceholder}
                        populateEntry={false}
                        classNamePrefix="custom-react-select"
                        lucetTheme
                        label=""
                        name="referral_instruction_id"
                        component={selectBox}
                        disabled={!canSelectReferralInstructions}
                      />
                    </div>
                    <div
                      className={`${
                        isDFD && referralOptions?.length === 1 && 'display-none'
                      } row-block margin-auto custom-dropdown ${
                        classes['referral-select']
                      } plum-select-box ref-opt is-disabled-${referralOptionsDisabled}`}
                    >
                      <Field
                        options={referralOptions.map((item) => ({
                          label: item.name,
                          value: item.id,
                        }))}
                        onChange={(option) => {
                          if (option) {
                            const selected =
                              (selectedOutcome &&
                                Object.keys(selectedOutcome).length &&
                                selectedOutcome.options.find(
                                  (item) => item.id === option,
                                )) ||
                              {};
                            setFieldValue('complete', selected.complete_survey);
                            setFieldValue(
                              'action_button_path',
                              selected.action_button_path,
                            );
                            setFieldValue(
                              'default_specialty',
                              selected.default_specialty,
                            );
                            setFieldValue(
                              'default_visit_type',
                              selected.default_visit_type,
                            );
                            setFieldValue(
                              'exclude_specialities',
                              selected?.exclude_specialities
                                ? selected?.exclude_specialities?.join(',')
                                : '',
                            );
                            if (isDFD !== true) {
                              getAccordionData(option);
                            }
                          }
                        }}
                        placeholder="Select an additional referral option"
                        label=""
                        classNamePrefix="custom-react-select"
                        lucetTheme
                        name="referral_option_id"
                        component={selectBox}
                        disabled={
                          !referralOptions.length ||
                          !values.referral_instruction_id
                        }
                      />
                    </div>
                  </div>
                  {isDFD !== true &&
                    accordionData &&
                    Object.keys(accordionData).length !== 0 && (
                      <Accordion
                        customClass={`margin-auto ${classes.multi_referral}`}
                        accordion_data={accordionData}
                        title={accordionData.name}
                        description={accordionData.description}
                        panelList={accordionData.faq.map((item) => ({
                          title: item.question,
                          content: item.answer,
                        }))}
                      />
                    )}
                  <button
                    id="btn-primary"
                    type="submit"
                    className={`${
                      isDFD ? 'isMSS' : 'notMSS'
                    } btn btn-primary plum-btn multi-submit-btn ${
                      referralOptions.length
                        ? values.referral_instruction_id &&
                          values.referral_option_id
                          ? ''
                          : 'btn-disabled'
                        : values.referral_instruction_id
                        ? ''
                        : 'btn-disabled'
                    }`}
                  >
                    {isDFD ? stringValues.book_another : 'Next'}
                  </button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
      {isDFD !== true && (
        <div className="comments-section-multi-referral">
          <h3>If not,</h3>
          <a
            className="btn btn-primary plum-btn"
            href={`/call_centers/complete_session?survey_id=${surveyId}&pathway=${pathway}`}
          >
            Complete Session
          </a>
        </div>
      )}
      {loader ? (
        <div className="loader">
          <div />
        </div>
      ) : null}
      {messageCount >= 0 ? (
        <RequestLoader
          requestCount={messageCount}
          customClassName="referral-loader"
          referral
        />
      ) : null}
      {modal && (
        <SendAssetsModal
          closeModal={() => setModal(false)}
          setLoader={setLoader}
          organizationStatus={smsStatus}
          sendAssetsText={{
            title: 'Send Digital Assets',
            subtitle: 'How would you like to send the digital assets?',
            subtitle2:
              'By entering a phone and/or email, you are confirming you have consent from the patient to communicate via these methods.',
          }}
          optionId={formValues.referral_option_id}
          instructionId={formValues.referral_instruction_id}
          customProps={{
            width: '400px',
            class: 'send-modal',
            closeButton: true,
          }}
        />
      )}
    </React.Fragment>
  );
};

export default MultiReferral;
