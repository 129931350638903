import React from "react"
import DialogModal from "../../../components/DialogModal";
import {Formik, Form} from 'formik';
import {DateTextField, TextField} from "../../../components/inputs";
import {masks, validations, csrfHeader} from "../../../../helperFunctions";
import {formValidations} from "../../../helpers/moduleHelper";
import bowser from "bowser";
import axios from 'axios';

class RegisterForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {modal: {}, showNextButton: false}
    this.dobMonth = React.createRef();
    this.dobDay = React.createRef();
    this.dobYear = React.createRef();
  }

  handleSubmit = (values, validate, url) => {
    const {pageName, submitURL, patientAutoCreate, mrn, setLoader} = this.props;
    setLoader(true);
    url += bowser.msie ? '.json' : '';
    return axios({
      method: validate ? 'get' : 'post',
      url: url,
      params: validate ? values: null,
      data: validate ? null: values,
      headers: csrfHeader,
    })
      .then(response => {
        if (validate) {
          if (response && response.data && (response.data.flag === 'true' ||
            (pageName === 'patient_details' && url === "/qualify_mrn" && (mrn === '' || mrn === null || mrn === undefined))) ) {
            if (pageName === 'register') {
              this.handleSubmit(values, false, submitURL).then(r => {
                // do nothing
              })
            } else if (pageName === 'patient_details') {
              if (url === "/qualify_mrn") {
                this.handleSubmit(values, true, "/qualify").then(r => {
                  // do nothing
                })
              } else if (url === "/qualify") {
                this.handleSubmit(values, false, submitURL).then(r => {
                  // do nothing
                })
              }
            }
          } else if (response && response.data) {
              if (patientAutoCreate) {
                this.handleSubmit(values, false, "/create_patient").then(r => {
                  // do nothing
                })
              } else {
                this.openModal("DialogModal");
                setTimeout(() => this.goToWelcomePage(), 20000);
              }
          }
        } else {
          if (response.status === 200 || response.status === 302 || response.data.location) {
            window.location.href = response.data.location || response.request.responseURL;
          }
        }
      })
      .catch(err => {
        setLoader(false);
        const msg =
          err.response.data && err.response.data.message
            ? err.response.data.message
            : err;
        alert(msg);
      });
  }

  goToWelcomePage = () => {
    window.location.href = "/welcome"
  }

  handleBackButton = () => {
    const {backPath} = this.props;
    window.location.href = backPath ? backPath : "/welcome";
  }

  openModal = (name) => {
    const {setLoader} = this.props;
    setLoader(false);
    document.body.classList.add("modal-open");
    this.setState({
      modal: Object.assign({}, this.state.modal, {
        [name]: true,
      })
    });
  };

  closeModal = (name) => {
    document.body.className = document.body.className.replace(
      'modal-open',
      ''
    );
    this.setState({
      modal: Object.assign({}, this.state.modal, {
        [name]: false
      }),
    });
  };

  validate = (values) => {
    this.props.handleUnloadUpdates(true);
    const {locale} = this.props;
    const fieldErrorMessage = formValidations.fieldErrorMessage(locale);
    let errors = {};
    let firstNameValidationErrors = !values.first_name ? fieldErrorMessage : formValidations.name(values.first_name, locale);
    if (firstNameValidationErrors) {
      errors.first_name = firstNameValidationErrors;
    }
    let lastNameValidationErrors = !values.last_name ? fieldErrorMessage : formValidations.name(values.last_name, locale);
    if (lastNameValidationErrors) {
      errors.last_name = lastNameValidationErrors;
    }
    if (values.mi_name && values.mi_name.length > 0) {
      let middleNameValidationErrors = !values.mi_name ? fieldErrorMessage : formValidations.name(values.mi_name, locale);
      if (middleNameValidationErrors) {
        errors.mi_name = middleNameValidationErrors;
      }
    }
    let dobErrors = !values.dob ? fieldErrorMessage : formValidations.dob(values.dob, "MM/DD/YYYY", locale);
    if (dobErrors) {
      errors.dob = dobErrors;
    }
    this.setState({showNextButton: Object.keys(errors).length === 0})
    return errors;
  }

  dobChanged = (type, value, props) => {
    const {setFieldValue} = props;
    if (value === null || value === undefined || parseInt(value) < 0 || !(Number.isInteger(parseInt(value)))) {
      setFieldValue(type, '');
      return;
    }
    var updatedValue = parseInt(value);
    switch (type) {
      case "month":
        if (value.length === 1 && parseInt(value) > 1) {
          this.dobDay.current.focus();
        } else if (value.length === 2 && parseInt(value) <= 12 && parseInt(value) > 0) {
          this.dobDay.current.focus();
        } else if (value.length > 2 || parseInt(value) > 12 || parseInt(value) < 0) {
          updatedValue = '';
        }
        break;
      case "day":
        if (value.length === 1 && parseInt(value) > 3) {
          this.dobYear.current.focus();
        } else if (value.length === 2 && parseInt(value) <= 31 && parseInt(value) > 0) {
          this.dobYear.current.focus();
        } else if (value.length > 2 || parseInt(value) > 31 || parseInt(value) < 0) {
          updatedValue = '';
        }
        break;
      case "year":
        if (value.length > 4 || (value.length === 4 && parseInt(value) < (new Date(1900, 1, 1).getFullYear())) || parseInt(value) > (new Date().getFullYear())) {
          updatedValue = '';
        }
        break;
    }
    setFieldValue(type, updatedValue);
  }

  render() {
    const {
      pageName, organization, site, mrn, groupId, locale, firstNameLabel, middleNameLabel, lastNameLabel, dobLabel,
      dobExampleLabel, monthLabel, dayLabel, yearLabel, errorDatePrompt, dobValue, nextButtonLabel, backButtonLabel,
      submitURL
    } = this.props;
    const {modal, showNextButton} = this.state;
    return (
      <div>
        <Formik
          initialValues={{
            first_name: '',
            last_name: '',
            mi_name: '',
            month: '',
            day: '',
            year: '',
            org_id: organization && organization.id,
            site_id: site && site.id,
            dob: dobValue ? dobValue : '',
            mrn: mrn,
            group_id: groupId,
          }}
          onSubmit={(values, {setSubmitting}) => {
            this.props.handleUnloadUpdates(false);
            setTimeout(() => {
              const url = pageName === 'register' ? '/qualify' : pageName === 'patient_details' ? '/qualify_mrn' : '';
              this.handleSubmit(values, true, url).then(r => {

              })
              setSubmitting(false);
            }, 400);
          }}
          validate={this.validate}
        >
          {props => (
            <Form className="user_match">
              <div className={`rows`}>
                <div className="row clear">
                  <div className="form-group col-md-4 col-sm-10 flt-lft name-field">
                    <TextField
                      label={firstNameLabel}
                      name="first_name"
                      type="text"
                      inputClass={`form-control`}
                    />
                  </div>
                  {locale !== "es" && (
                    <div className="form-group col-md-2 col-sm-10 flt-lft name-field">
                      <TextField
                        label={middleNameLabel}
                        name="mi_name"
                        type="text"
                        inputClass={`form-control`}
                      />
                    </div>
                  )}
                  <div className="form-group col-md-4 col-sm-10 flt-lft name-field">
                    <TextField
                      label={lastNameLabel}
                      name="last_name"
                      type="text"
                      inputClass={`form-control`}
                    />
                  </div>
                </div>

                <div className={`row clear`}>
                  <div className={`col-md-12 col-sm-10`}>
                    <p className="prompt form-control">
                      {dobLabel}&nbsp;&nbsp;&nbsp;<em>{dobExampleLabel}: 02/20/1942</em></p>
                  </div>
                </div>
                <div className="dob-row col-md-5 col-sm-10 clear">
                  <DateTextField
                    name="dob"
                    type="text"
                    placeholder="MM/DD/YYYY"
                    mask={`MM/DD/YYYY`}
                    inputClass={`form-control input`}
                    setFieldValue={props.setFieldValue}
                  />
                </div>
              </div>
              <div className="navigation-buttons-space clear">
                {showNextButton && (
                <button type="submit" className={`btn-next flt-rgt sub-form`} name=" action" id="
                                    btn-next">{nextButtonLabel}</button>
                )}
                <button type="button" onClick={this.handleBackButton} className={`btn-back flt-lft`}>{backButtonLabel}</button>
              </div>
            </Form>
          )}
        </Formik>
        {modal.DialogModal && (
          <DialogModal
            {...this.props}
            closeModal={() => {
              this.closeModal('DialogModal');
              this.goToWelcomePage();
            }}
            saveModal={() => {
              this.closeModal('DialogModal');
              this.goToWelcomePage();
            }}
          />
        )}
      </div>
    );
  }
}

export default RegisterForm