import React, { useMemo, useState } from 'react';

import clsx from 'clsx';
import styles from './member_journey.module.scss';
import ResendMssLinkForm from './ResendMssLinkForm';
import RequestLoader from '../../UI/RequestLoader';
import requestLoaderStyles from '../../UI/RequestLoader.module.scss';
import { noop } from '../../../helperFunctions';

const ResendMssLink = ({
  patientId,
  surveyId,
  member_self_serve,
  resendMssLinkData,
  refreshJourneyData,
  version = 'call_center',
  sendLinkFormTexts = {},
  onSuccess = noop,
  onPartialSuccess = noop,
  memberSelfServeId,
  initiallyShowRetrySendLinkBanner,
}) => {
  const [notificationStatus, setNotificationStatus] = useState();
  const [sectionCollapsed, setSectionCollapsed] = useState(true);
  const [showRetrySendLinkBanner, setShowRetrySendLinkBanner] = useState(
    initiallyShowRetrySendLinkBanner,
  );
  const [notificationStatusLoading, setNotificationStatusLoading] =
    useState(false);
  const [isPolling, setIsPolling] = useState(false);
  const toggleSection = () => {
    setSectionCollapsed(!sectionCollapsed);
  };

  const isInCallCenter = version === 'call_center';
  const loaderMessage = sendLinkFormTexts.processingRequestBody
    ? `${sendLinkFormTexts.processingRequestBody}...`
    : 'We are confirming that this message was sent successfully…';

  const notificationMethods = useMemo(
    () =>
      resendMssLinkData.notification_methods.reduce(
        (accumulator, currentValue) => {
          accumulator[currentValue.method_type] = currentValue;
          return accumulator;
        },
        {},
      ),
    [resendMssLinkData.notification_methods],
  );

  const optOutInfo = {
    email: resendMssLinkData.notification_methods.find(
      (method) => method.method_type === 'email',
    )?.opt_out,
    sms: resendMssLinkData.notification_methods.find(
      (method) => method.method_type === 'sms',
    )?.opt_out,
  };

  return (
    <div
      className={clsx(
        styles.resendMssLink,
        !isInCallCenter && styles.resendMssLinkPatientPortal,
      )}
    >
      {isInCallCenter && (
        <button
          className={clsx(
            'btn-plain margin-left-md margin-top-md margin-bottom-md ',
            styles.resendAccordionButton,
          )}
          type="button"
          onClick={toggleSection}
        >
          Resend Member Self-Serve Link
          <i className={`fa fa-chevron-${sectionCollapsed ? 'up' : 'down'}`} />
        </button>
      )}
      <div
        className={`${styles.mssJourneySection} ${
          sectionCollapsed ? styles.out : styles.in
        }`}
      >
        <ResendMssLinkForm
          initialValues={{
            email: notificationMethods.email?.contact_value,
            sms: notificationMethods.sms?.contact_value,
          }}
          isInCallCenter={isInCallCenter}
          optOutInfo={optOutInfo}
          textNotificationEnabledOrg
          sendAssetsText={{}}
          patientId={patientId}
          surveyId={surveyId}
          resend={member_self_serve && member_self_serve.resend}
          expired={member_self_serve && member_self_serve.expired}
          notificationStatus={notificationStatus}
          setNotificationStatus={setNotificationStatus}
          setNotificationStatusLoading={setNotificationStatusLoading}
          refreshJourneyData={refreshJourneyData}
          sendLinkFormTexts={sendLinkFormTexts}
          onSuccess={onSuccess}
          onPartialSuccess={onPartialSuccess}
          memberSelfServeId={memberSelfServeId}
          isPolling={isPolling}
          setIsPolling={setIsPolling}
          showRetrySendLinkBanner={showRetrySendLinkBanner}
          setShowRetrySendLinkBanner={setShowRetrySendLinkBanner}
          stringValues={{
            sendViaEmail: sendLinkFormTexts.sendViaEmail,
            enterEmail: sendLinkFormTexts.enterEmail,
            sendViaSmsTxtMsg: sendLinkFormTexts.sendViaText,
            enterPhoneNumber: sendLinkFormTexts.enterPhoneNumber,
            cancel: sendLinkFormTexts.reset,
            send: sendLinkFormTexts.send,
          }}
        />
      </div>
      {notificationStatusLoading && (
        <RequestLoader
          customStyles={isInCallCenter ? {} : requestLoaderStyles}
          customHeaderMessage={sendLinkFormTexts.processingRequestHeader}
          loaderMessage={loaderMessage}
        />
      )}
    </div>
  );
};
export default ResendMssLink;
