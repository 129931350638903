import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import './style.scss';

class Main extends React.Component {
  render() {
    return (
      <Router>
      </Router>
    );
  }
}

export default Main;
