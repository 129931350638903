/* eslint-disable camelcase */
import axios from 'axios';
import React from 'react';
import ProgressBar from '../../../../../components/shared/ProgressBar';
import { useAuthentication } from '../../../../../context/authentication';
import { ModalConsumer } from '../../../../../context/modal';
import PauseDialogue from './PauseDialogue';

const ProgressSection = (props) => {
  const {
    pageName,
    assessmentData = {},
    stringValues = {},
    videoSession,
    mode,
    patient,
    setLoader,
    rebrand,
  } = props;
  const {
    progress = pageName === 'thankyou' ? 100 : null,
    page,
    survey_id,
    is_proxy,
    proxy,
    showPauseButtonForMssScreener,
  } = assessmentData;

  const { token } = useAuthentication();
  const pauseSurvey = () =>
    axios({
      method: 'patch',
      url: `/api/v1/surveys/${survey_id}`,
      data: {
        paused_at: page,
        status: 'paused',
        survey_id,
        survey_token: token,
      },
    });

  return (
    <div className={`progressbar-div progress-${progress}`}>
      <div
        className="progress-container"
        tabIndex="0"
        aria-describedby="progressText progressValue_1"
        role="region"
        aria-live="polite"
      >
        <div className="progress-text" id="progressText" tabIndex="-1">
          {stringValues.progress}
        </div>
        <div className="progressbar">
          <div className="progress" style={{ width: `${progress}%` }}>
            <div className="progress-value" id="progressValue_1" tabIndex="-1">
              {progress}%
            </div>
          </div>
        </div>
        <div className="progress-radial">
          <ProgressBar theme={rebrand && 'lucet'} progress={progress} />
        </div>
      </div>
      {page && (mode !== 'dfd' || showPauseButtonForMssScreener) && (
        <div className="pause" id="opener">
          <ModalConsumer>
            {({ setModal, unSetModal }) => (
              <button
                onClick={() => {
                  if (mode === 'dfd') {
                    pauseSurvey();
                  }
                  setModal(
                    <PauseDialogue
                      {...props}
                      survey_id={survey_id}
                      unSetModal={unSetModal}
                      page={page}
                      stringValues={stringValues}
                      videoSession={videoSession}
                      mode={mode}
                      patient={patient}
                      proxy={proxy}
                      is_proxy={is_proxy}
                      setLoader={setLoader}
                      customProps={{ disableLucetTheme: !rebrand }}
                      rebrand={rebrand}
                      pauseSurvey={pauseSurvey}
                    />,
                  );
                }}
                className="btn-without-loader pause-survey-btn"
                type="button"
              >
                {stringValues['pause-survey']}
              </button>
            )}
          </ModalConsumer>
        </div>
      )}
    </div>
  );
};

export default ProgressSection;
