import React from 'react';

import { Field } from 'redux-form';
import { masks, validations } from '../helperFunctions';

import {
  LabelField,
  checkBox,
  textArea,
  selectBox,
  multiSelectBox,
  textField,
  textFieldInline,
  dateField,
  sliderField,
  phoneField,
  numberField,
  checkBoxInline,
  toggleSwitch,
  fileInput,
} from './inputs';

const requiredValidator = validations.required;

const comboFieldValidator = {
  email: (value, allValues, props, name) => {
    if (allValues[`${name}_checkbox`]) {
      if (requiredValidator(value)) return requiredValidator(value);
      if (validations.email(value)) return validations.email(value);
    }
  },
  phone: (value, allValues, props, name) => {
    if (allValues[`${name}_checkbox`]) {
      if (requiredValidator(value)) return requiredValidator(value);
      if (validations.phone(value)) return validations.phone(value);
    }
  },
};

const renderError = ({ style, meta: { touched, error, warning } }) => (
  <span style={style}>
    {touched &&
      ((error && <span className="error">{error}</span>) ||
        (warning && <span>{warning}</span>))}
  </span>
);
const validators = {
  email: validations.email,
  phone: validations.phone,
};

const Cell = (props) => {
  const { fieldData, onChange, formValues, change, disabled, valueType, lucetTheme, populateEntry } =
    props;
  let validations = [];

  if (fieldData.is_required) {
    validations = [requiredValidator];
  }
  if (fieldData.type === 'email') {
    validations.push(validators.email);
  }

  return (
    <div
      className={
        fieldData.parent_class
          ? fieldData.parent_class
          : 'cell clearfix notes-label'
      }
    >
      {fieldData.visible !== false &&
        ({
          label: (
            <Field
              name={fieldData.name}
              component={LabelField}
              showRequiredLabel={fieldData.show_required_label}
              infoText={fieldData.info_text}
              type="label"
              label={fieldData.label}
            />
          ),
          checkbox: (
            <Field
              placeholder={fieldData.placeholder}
              id={fieldData.name}
              name={fieldData.name}
              component={checkBox}
              type="text"
              label={fieldData.label}
              onChange={onChange}
            />
          ),
          toggle: <Field {...props} {...fieldData} component={toggleSwitch} />,
          file: <Field {...props} {...fieldData} component={fileInput} />,
          single: (
            <Field
              placeholder={fieldData.placeholder}
              validate={validations}
              name={fieldData.name}
              component={selectBox}
              type="text"
              label={fieldData.label}
              options={fieldData.options}
              onChange={onChange}
              showRequiredLabel={fieldData.show_required_label}
              disabled={fieldData.disabled || disabled}
              lucetTheme={lucetTheme}
              populateEntry={populateEntry}
            />
          ),
          demographic: (
            <Field
              placeholder={fieldData.placeholder}
              validate={validations}
              name={fieldData.name}
              component={selectBox}
              type="text"
              label={fieldData.label}
              options={fieldData.options}
              onChange={onChange}
              lucetTheme={lucetTheme}
            />
          ),
          multi: (
            <Field
              {...props}
              placeholder={fieldData.placeholder}
              validate={validations}
              name={fieldData.name}
              component={multiSelectBox}
              showRequiredLabel={fieldData.show_required_label}
              type="text"
              label={fieldData.label}
              options={fieldData.options}
              multiple
              onChange={onChange}
              valueType={valueType}
              lucetTheme={lucetTheme}
            />
          ),
          textarea: (
            <Field
              {...props}
              {...fieldData}
              // placeholder={fieldData.placeholder}
              validate={validations}
              // name={fieldData.name}
              component={textArea}
              // label={fieldData.label}
              maxLength={fieldData.character_limit}
              maxLineBreaks={fieldData.line_limit}
              // onChange={onChange}
            />
          ),
          text: (
            <Field
              {...props}
              {...fieldData}
              // placeholder={fieldData.placeholder}
              validate={validations}
              // name={fieldData.name}
              component={textField}
              showRequiredLabel={fieldData.show_required_label}
              type="text"
              // label={fieldData.label}
              onChange={onChange}
            />
          ),
          date: (
            <Field
              placeholder={fieldData.placeholder}
              validate={validations}
              name={fieldData.name}
              component={dateField}
              type="text"
              label={fieldData.label}
              onChange={onChange}
            />
          ),
          slider: (
            <Field
              placeholder={fieldData.placeholder}
              validate={validations}
              name={fieldData.name}
              component={sliderField}
              type="text"
              label={fieldData.label}
              inputData={fieldData}
              onChange={onChange}
            />
          ),
          phone: (
            <Field
              placeholder={fieldData.placeholder}
              validate={validations}
              name={fieldData.name}
              component={phoneField}
              label={fieldData.label}
              onChange={onChange}
            />
          ),
          number: (
            <Field
              placeholder={fieldData.placeholder}
              validate={validations}
              name={fieldData.name}
              component={numberField}
              label={fieldData.label}
              onChange={onChange}
            />
          ),
          email: (
            <Field
              placeholder={fieldData.placeholder}
              validate={validations}
              name={fieldData.name}
              component={textField}
              label={fieldData.label}
              onChange={onChange}
            />
          ),
          password: (
            <Field
              placeholder={fieldData.placeholder}
              validate={validations}
              name={fieldData.name}
              component={textField}
              label={fieldData.label}
              onChange={onChange}
              type="password"
            />
          ),
          combo_field: (
            <div className="no-label">
              <div className="space-between">
                <Field
                  name={`${fieldData.name}_checkbox`}
                  component={checkBoxInline}
                  label={fieldData.label}
                  onChange={(event, newValue, previousValue, name) => {
                    // if(!newValue) {
                    //   change(name.replace('_checkbox',''), "")
                    // }
                    if (onChange)
                      onChange(event, newValue, previousValue, name);
                  }}
                  defaultValue={fieldData.default_checked}
                  disabled={fieldData.disabled || disabled}
                />
                <Field
                  placeholder={fieldData.placeholder}
                  validate={[comboFieldValidator[fieldData.combo_type]]}
                  name={fieldData.name}
                  component={textFieldInline}
                  onChange={onChange}
                  disabled={
                    fieldData.disabled ||
                    (formValues && !formValues[`${fieldData.name}_checkbox`]) ||
                    disabled
                  }
                  style={{ width: 300 }}
                  normalize={masks[fieldData.mask]}
                  showDummy={
                    !fieldData.disabled &&
                    formValues &&
                    !formValues[`${fieldData.name}_checkbox`]
                  }
                  hideError
                />
              </div>
              <div className="space-between">
                <span />
                <Field
                  style={{ width: 300 }}
                  validate={[comboFieldValidator[fieldData.combo_type]]}
                  name={fieldData.name}
                  component={renderError}
                />
              </div>
            </div>
          ),
        }[fieldData.type] || (
          <Field
            placeholder={fieldData.placeholder}
            validate={validations}
            name={fieldData.name}
            component={textField}
            type="text"
            label={fieldData.label}
          />
        ))}
    </div>
  );
};

export default Cell;
