import React, { useCallback } from 'react';

import Slots from '../slot/Slots';
import ProviderMediaBlock from './ProviderMediaBlock';
import styles from './provider_block.module.scss';
import { tryWithDefault } from '../../../../../helperFunctions';

const ProviderBlock = ({
  provider,
  onClickSlot,
  handleSelectProvider,
  bestMatch,
  hasMultipleSites,
  stringValues,
  filtersApplied,
  maxNumberOfSlotsShown,
}) => {
  const handleClickMore = useCallback(() => {
    handleSelectProvider(provider);
  }, [handleSelectProvider, provider]);

  const showMoreSlotsButton =
    provider.time_slots?.length > maxNumberOfSlotsShown;
  const providerTimeSlots = tryWithDefault(
    () => provider.time_slots.slice(0, maxNumberOfSlotsShown),
    [],
  );
  return (
    <div className={styles.blockWrap}>
      <ProviderMediaBlock
        provider={provider}
        bestMatch={bestMatch}
        stringValues={stringValues}
        hasMultipleSites={hasMultipleSites}
      />
      {provider.intake_appt_role === 'coordinator' && (
        <p className="color-plum">
          <strong>
            <i>{stringValues.intake_coordinator_info}</i>
          </strong>
        </p>
      )}
      <p className={`${styles.biography} ellipsis ellipsis-4`}>
        {provider.biography}
      </p>
      <div className="space-between">
        <p className={styles.txtPickATime}>{stringValues.pick_a_time}:</p>
        <button
          onClick={handleClickMore}
          className={`${styles.btnMore} btn-textonly`}
          type="button"
        >
          {stringValues.learn_more_about_me}
        </button>
      </div>
      <Slots
        slots={providerTimeSlots}
        onClick={onClickSlot}
        stringValues={stringValues}
        filtersApplied={filtersApplied}
        outOfTravelRadius={provider.out_of_travel_radius}
        showMoreSlotsButton={showMoreSlotsButton}
        onMoreSlotsClick={handleClickMore}
      />
    </div>
  );
};

export default ProviderBlock;
