import React from "react"
import DialogModal from "../../../components/DialogModal";
import {Formik, Form, Field} from 'formik';
import {TextField, SelectField, DateTextField} from "../../../components/inputs";
import {masks, validations, csrfHeader} from "../../../../helperFunctions";
import {formValidations} from "../../../helpers/moduleHelper";
import axios from 'axios';

class RegisterTPIForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {modal: {}, showNextButton: false}

  }

  handleSubmit = (values, validate, url) => {
    const {submitURL, patientAutoCreate, setLoader} = this.props;
    setLoader(true);
    if(patientAutoCreate === false) {
      window.location.href = `/register/patient_details?mrn=${values.mrn}&group_id=${values.group_id}&check_mrn=${values.check_mrn}`;
    } else {
      return axios({
        method: validate ? 'get' : 'post',
        url: url,
        params: validate ? values : null,
        data: validate ? null : values,
        headers: csrfHeader,
      })
        .then(response => {
          if (validate) {
            if (response && response.data && (response.data.flag === 'true')) {
              window.location.href = `/register/patient_details?mrn=${values.mrn}&group_id=${values.group_id}&check_mrn=${values.check_mrn}`;
            } else if (response && response.data && (response.data.flag === 'false')) {
              this.openModal("DialogModal");
              setTimeout(() => this.goToWelcomePage(),20000);
            }
          } else {
            if (response.status === 200 || response.status === 302) {
              window.location.href = response.request.responseURL;
            }
          }
        })
        .catch(err => {
          setLoader(false);
          const msg =
            err.response.data && err.response.data.message
              ? err.response.data.message
              : err;
          alert(msg);
        });
    }
  }

  goToWelcomePage = () => {
    window.location.href = "/welcome"
  }

  handleBackButton = () => {
    const {backPath} = this.props;
    window.location.href = backPath ? backPath : "/welcome";
  }

  openModal = (name) => {
    const {setLoader} = this.props;
    setLoader(false);
    document.body.classList.add("modal-open");
    this.setState({
      modal: Object.assign({}, this.state.modal, {
        [name]: true,
      })
    });
  };

  closeModal = (name) => {
    document.body.className = document.body.className.replace(
      'modal-open',
      ''
    );
    this.setState({
      modal: Object.assign({}, this.state.modal, {
        [name]: false
      }),
    });
  };

  validate = (values) => {
    const {mrnRegex, counselorsError, locale} = this.props;
    const fieldErrorMessage = formValidations.fieldErrorMessage(locale);

    this.props.handleUnloadUpdates(true);
    let errors = {};
    let mrnErrors = !values.mrn ? fieldErrorMessage : formValidations.mrn(values.mrn, mrnRegex, locale);
    if (mrnErrors) {
      errors.mrn = mrnErrors;
    }
    if (!values.group_id) {
      errors.group_id = fieldErrorMessage;
    }

    this.setState({showNextButton: Object.keys(errors).length === 0})
    return errors;
  }

  render() {
    const {
      pageName, organization, site, mrnLabel, mrnError, mrn, counselorsLabel, counselorsPlaceholder,
      counselorsOptions, counselorsError, nextButtonLabel, backButtonLabel,
      checkMRN, patientAutoCreate, submitURL
    } = this.props;
    const {modal, showNextButton} = this.state;
    return (
      <div>
        <Formik
          initialValues={{
            org_id: organization && organization.id,
            site_id: site && site.id,
            mrn: mrn,
            group_id: '',
            check_mrn: checkMRN
          }}
          onSubmit={(values, {setSubmitting}) => {
            this.props.handleUnloadUpdates(false);
            setTimeout(() => {
              this.handleSubmit(values, true, `/qualify_mrn`).then(r => {

              })
              setSubmitting(false);
            }, 400);
          }}
          validate={this.validate}
        >
          {props => (
            <Form className="user_match" aria-label="Enter MRN">
              <div className={`rows`}>
                <div className="form-group row clear">
                  <div className="col-lg-8 col-md-10 col-sm-10">
                    <TextField
                      label={mrnLabel}
                      name="mrn"
                      type="text"
                      inputClass={`form-control`}
                    />
                  </div>
                </div>
                {counselorsOptions && (
                  <div className="form-group row clear">
                    <div className="col-lg-8 col-md-10 col-sm-10">
                      <SelectField
                        label={counselorsLabel}
                        name="group_id"
                        className={`form-control input`}
                        placeholder={counselorsPlaceholder}
                        options={(counselorsOptions) || []}
                      />
                    </div>
                  </div>
                )}

              </div>
              <div className="navigation-buttons-space clear">
                {showNextButton && (
                  <button type="submit" className={`btn-next flt-rgt sub-form`} name=" action" id="
                                                    btn-next">{nextButtonLabel}</button>
                )}
                <button type="button" onClick={this.handleBackButton}
                        className={`btn-back flt-lft`}>{backButtonLabel}</button>
              </div>
            </Form>
          )}
        </Formik>
        {modal.DialogModal && (
          <DialogModal
            {...this.props}
            closeModal={() => {
              this.closeModal('DialogModal');
              this.goToWelcomePage();
            }}
            saveModal={() => {
              this.closeModal('DialogModal');
              this.goToWelcomePage();
            }}
          />
        )}
      </div>
    );
  }
}

export default RegisterTPIForm