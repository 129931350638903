import React, { useEffect, useState } from 'react';
import { showAgeRanges } from '../../../../helperFunctions';
import PronounBadge from '../../../../shared/PronounBadge';

const areasOfPracticeLabel = (data) =>
  data &&
  data.map((item, i) => (i + 1 === data.length ? item.name : `${item.name}, `));

const ProviderDetailPopUp = ({
  provider,
  profileData,
  customClass,
  onClose,
}) => {
  const [profileDetails, setProfileDetails] = useState(null);
  useEffect(() => {
    setProfileDetails(profileData);
  }, [profileData]);
  return (
    <div className={`provider-popup ${customClass}`}>
      <div className="provider-popup-inner">
        <div className="provider-header">
          {provider && provider.avatar && (
            <div className="provider-avatar-cont">
              <div
                className="provider-avatar"
                style={{ backgroundImage: `url(${provider.avatar})` }}
              />
            </div>
          )}
          <div className="provider-header-details">
            <h1>{provider.name}</h1>
            <div className="gender-and-pronoun">
              {profileDetails && profileDetails.sex && (
                <p className="gender">{profileDetails.sex}</p>
              )}
              {profileDetails && profileDetails.pronoun && (
                <PronounBadge
                  pronoun={profileDetails.pronoun}
                  theme="dark"
                  tooltipPlacement="bottom"
                />
              )}
              {profileDetails && profileDetails.races?.length > 0 && (
                <span className="race left-border-separator">
                  {profileDetails.races.map((race) => race.name).join(', ')}
                </span>
              )}
            </div>
            {profileDetails && profileDetails.site_name && (
              <p>{profileDetails.site_name}</p>
            )}
            {profileDetails &&
              profileDetails.site &&
              profileDetails.intake_appt_role !== 'coordinator' && (
                <p>{profileDetails.site}</p>
              )}
            {profileDetails && profileDetails.site_phone && (
              <p>{profileDetails.site_phone}</p>
            )}
            {provider && provider.travel_radius && (
              <p>{provider && provider.travel_radius} </p>
            )}
          </div>
          {/* eslint-disable-next-line react/button-has-type */}
          <button className="prov-popup-close-btn" onClick={onClose}>
            x
          </button>
        </div>
        <div className="header-separator" />
        {profileDetails ? (
          <div className="popup-sub-inner provider-body">
            <div className="provider-popup-scroll-wrap">
              {profileDetails.intake_appt_role === 'coordinator' && (
                <div>
                  <p className="color-plum">
                    <strong>
                      <i>{profileDetails.intake_coordinator_info}</i>
                    </strong>
                  </p>
                </div>
              )}
              {profileDetails.biography && (
                <div className="detail-row bio">
                  <p>{profileDetails.biography}</p>
                </div>
              )}
              <div className="detail-row">
                <h4>Areas of Practice</h4>
                <p>
                  {profileDetails.pdo_enabled
                    ? areasOfPracticeLabel(profileDetails.trainings)
                    : areasOfPracticeLabel(profileDetails.specialties)}
                </p>
              </div>
              <div className="detail-row">
                <h4>Experience</h4>
                {profileDetails.years_in_practice && (
                  <p>{profileDetails.years_in_practice} years</p>
                )}
              </div>
              <div className="detail-row">
                <h4>Education</h4>
                <p>
                  {profileDetails.education &&
                    profileDetails.education.map((item, i) =>
                      i + 1 === profileDetails.education.length
                        ? `${
                            (item.degree ? `${item.degree.name}, ` : ' ') +
                            (item.school || '')
                          } ${item.year_completed || ''}`
                        : `${
                            (item.degree ? `${item.degree.name}, ` : ' ') +
                            (item.school || '')
                          } ${item.year_completed || ''}, `,
                    )}
                </p>
              </div>
              <div className="detail-row">
                <h4>Client Focus</h4>
                <p>
                  {profileDetails.age_range &&
                    profileDetails.age_range.map((item, i) =>
                      i + 1 === profileDetails.age_range.length
                        ? item.name || ''
                        : `${item.name || ''}, `,
                    )}
                </p>
              </div>
              <div className="detail-row">
                <h4>Ages Treated</h4>
                <p>
                  {showAgeRanges(
                    profileDetails.treatment_age_ranges,
                    profileDetails.treats_all_age_ranges,
                  )}
                </p>
              </div>
              <div className="detail-row">
                <h4>Types of Therapy </h4>
                <p>
                  {profileDetails.treatment_types &&
                    profileDetails.treatment_types.map((item, i) =>
                      i + 1 === profileDetails.treatment_types.length
                        ? item.name
                        : `${item.name}, `,
                    )}
                </p>
              </div>
              <div className="detail-row">
                <h4>Licenses</h4>
                <p>
                  {profileDetails.licenses &&
                    profileDetails.licenses.map((item, i) =>
                      i + 1 === profileDetails.licenses.length
                        ? `${item.license_number} ${item.state}`
                        : `${item.license_number} ${item.state}, `,
                    )}
                </p>
              </div>
              <div className="detail-row">
                <h4>Accepts Virtual Appointments</h4>
                <p>{profileDetails.accept_video_appointments ? 'Yes' : 'No'}</p>
              </div>
              <div className="detail-row">
                <h4>Accepted Payment Methods</h4>
                <p>
                  {profileDetails.payment_methods &&
                    profileDetails.payment_methods.map((item, i) =>
                      i + 1 === profileDetails.payment_methods.length
                        ? item.name
                        : `${item.name}, `,
                    )}
                </p>
              </div>
              <div className="detail-row bio">
                <h4>Transportation Access</h4>
                <p>
                  {profileDetails.transportation_accesses &&
                    profileDetails.transportation_accesses.map((item, i) =>
                      i + 1 === profileDetails.transportation_accesses.length
                        ? item.name
                        : `${item.name}, `,
                    )}
                </p>
              </div>
              <div className="detail-row">
                <h4>Accepted Insurance Plans</h4>
                <p>
                  {profileDetails.insurance_plans &&
                    profileDetails.insurance_plans.map((item, i) =>
                      i + 1 === profileDetails.insurance_plans.length
                        ? item.name
                        : `${item.name}, `,
                    )}
                </p>
              </div>
              <div className="detail-row">
                <h4>Languages</h4>
                <p>
                  {profileDetails.languages &&
                    profileDetails.languages.map((item, i) =>
                      i + 1 === profileDetails.languages.length
                        ? item.name
                        : `${item.name}, `,
                    )}
                </p>
              </div>
              <div className="detail-row">
                <h4>Modality</h4>
                <p>
                  {profileDetails.modalities &&
                    profileDetails.modalities.map((item, i) =>
                      i + 1 === profileDetails.modalities.length
                        ? item.name
                        : `${item.name}, `,
                    )}
                </p>
              </div>
              <div className="detail-row">
                <h4>Cancellation Policy</h4>
                <p>{profileDetails.cancellation_policy}</p>
              </div>
            </div>
          </div>
        ) : (
          <div className="provider-popup-inner-spinner">
            <div className="spinner">
              <div />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProviderDetailPopUp;
