/* eslint-disable camelcase */
import moment from 'moment';
import React, { useMemo } from 'react';
import useFilterDataAsUrl from '../../../../hooks/useFilterDataAsUrl';
import styles from './appointment_success.module.scss';
import './style.css.scss';
import MultiReferral from '../../../../components/call_center/MultiReferral';
import { getUserTimeZoneName } from '../../../../helperFunctions';
import AppBreadcrumb from '../shared/AppBreadcrumb';
import { mssBreadcrumbs } from '../../../helpers/moduleHelper';
import clsx from 'clsx';

const AppointmentSuccess = ({
  start_date,
  end_date,
  locale,
  member_self_serve_id,
  showMultiReferral,
  stringValues,
  timezone,
  multiReferralData,
  encounterId,
  referralsExhausted,
  referralsExhaustedText,
}) => {
  // eslint-disable-next-line no-unused-vars
  const [, params] = useFilterDataAsUrl();
  let appointmentStartDate = null;
  let appointmentEndDate = null;
  if (start_date && end_date) {
    appointmentStartDate = moment(start_date);
    appointmentEndDate = moment(end_date);
  }
  const crumbs = useMemo(
    () =>
      mssBreadcrumbs(member_self_serve_id, stringValues).filter(
        (e) => e.tag === 'home' || e.tag === 'appointment_confirmation',
      ),
    [member_self_serve_id],
  );
  return (
    <div
      className={`${styles.appointmentSuccessWrapper} lucet-brand-detail-page mss-appointment-success-wrapper`}
    >
      <AppBreadcrumb crumbs={crumbs} />
      {encounterId && appointmentStartDate && appointmentEndDate && (
        <div>
          {stringValues.appt_confirmation_text1}
          <br />
          <br />
          <div>
            <span className={`${styles.date} apt-date`}>
              {appointmentStartDate.locale(locale).format('dddd, MMMM Do')}
            </span>
            <br />
            <span className={`${styles.time} apt-time`}>
              {appointmentStartDate.format('h:mm A')} {stringValues.to}{' '}
              {appointmentEndDate.format('h:mm A')}
            </span>
          </div>
          <br />
          <div
            id="dialog_desc"
            dangerouslySetInnerHTML={{
              __html: stringValues.appt_confirmation_text2,
            }}
          />
          <div>
            <br />
            <div
              id="dialog_desc"
              dangerouslySetInnerHTML={{
                __html: stringValues.appt_confirmation_text3,
              }}
            />
          </div>
        </div>
      )}

      {showMultiReferral && (
        <div>
          <br />
          {multiReferralData && multiReferralData.selectedReferral ? (
            <span className="result-ind">
              {stringValues.your_results_schedule_appt}{' '}
              {multiReferralData.selectedReferral.label}:
            </span>
          ) : (
            referralsExhausted !== true && (
              <span className="result-ind">
                Schedule another appointment with a different provider:
              </span>
            )
          )}
        </div>
      )}
      {referralsExhausted && referralsExhaustedText && (
        <div className="margin-bottom-md">
          <br />
          {referralsExhaustedText}
        </div>
      )}
      {showMultiReferral && multiReferralData ? (
        <div className="multi-referral-cont">
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <MultiReferral {...{ ...multiReferralData, stringValues }} />
        </div>
      ) : (
        member_self_serve_id && (
          <div className="text-center margin-top-lg">
            <a
              href={`/member_self_serve/${member_self_serve_id}`}
              className={clsx('btn return_home', styles.btnReturnHome)}
            >
              {stringValues.return_home_text}
            </a>
          </div>
        )
      )}
    </div>
  );
};

export default AppointmentSuccess;
