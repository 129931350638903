import React, { useCallback, useRef } from 'react';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import wrapField from './wrapField';
import Prompt from './Prompt';

const DateField = ({
  field,
  helpers: { setValue, setTouched },
  label,
  placeholder = 'Select a date',
  showTimeSelect,
  showTimeSelectOnly,
  timeIntervals = 30,
  dateFormat = 'MM/dd/yyyy',
  saveFormat,
  minDate,
  maxDate,
  fallback,
  showMonthYearPicker,
  showMonthDropdown,
  showYearDropdown,
  includeTimes,
  setTimeTo,
  disabled,
  popperPlacement,
  onChange,
  meta: { touched, error },
  locale,
  showError = true,
}) => {
  const datePickerRef = useRef();

  const onKeyDown = useCallback(
    (e) => {
      if (e.key === 'Tab') {
        datePickerRef.current.setOpen(false);
      }
    },
    [datePickerRef],
  );

  const stateFormat = saveFormat || 'MM/DD/YYYY';
  const selectedValue = field.value
    ? moment(field.value, stateFormat).toDate()
    : null;
  return (
    <div>
      <Prompt
        {...{
          label,
        }}
      />
      <div>
        <DatePicker
          dateFormat={dateFormat}
          selected={selectedValue}
          locale={locale}
          autoOk
          showTimeSelect={showTimeSelect}
          showTimeSelectOnly={showTimeSelectOnly}
          timeIntervals={timeIntervals}
          placeholderText={placeholder}
          // onBlur={() => setTouched(true)}
          onCalendarClose={() => {
            setTimeout(() => setTouched(true), 0);
          }} // hack to fix onBlur not firing when a value is selected
          onChange={(value) => {
            if (!(minDate && showTimeSelect && value < minDate)) {
              if (setTimeTo) {
                setValue(
                  value
                    ? moment(value)
                        .set({
                          hour: setTimeTo.get('hour'),
                          minute: setTimeTo.get('minute'),
                        })
                        .format(stateFormat)
                    : fallback || moment(),
                );
              } else {
                const modifiedValue = value
                  ? moment(value).format(stateFormat)
                  : fallback || moment();
                setValue(modifiedValue);
                if (onChange) onChange(modifiedValue);
              }
            }
          }}
          minDate={minDate}
          maxDate={maxDate}
          includeTimes={includeTimes}
          disabled={disabled}
          showMonthYearPicker={showMonthYearPicker}
          showMonthDropdown={showMonthDropdown}
          showYearDropdown={showYearDropdown}
          dropdownMode="select"
          popperPlacement={popperPlacement}
          ref={datePickerRef}
          onKeyDown={onKeyDown}
        />

        {showError && touched && error && <div className="error">{error}</div>}
      </div>
    </div>
  );
};

export default wrapField(DateField);
