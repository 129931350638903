import React from 'react';

class DialogModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      closeModal,
      saveModal,
      organization,
      dialogTitle,
      dialogCloseButtonTitle,
      dialogBody,
      dialogBodyClass,
      dialogSaveButtonTitle,
      dialogBodyFooterClass,
      dialogHeaderTitle,
      showCloseButton = false,
    } = this.props;
    return (
      <div
        id="dialog"
        title="Video dialog"
        className="popup modal shown custom-modal accessible-dialog"
        aria-describedby="dialog_desc"
        aria-labelledby="dialog_head"
        role=""
      >
        <div className="modal-dialog modal-type1">
          <div className="modal-content">
            <div className="modal-header">
              {showCloseButton && (
                <button
                  onClick={closeModal}
                  type="button"
                  className="close"
                  aria-label="Close"
                />
              )}
              <h2 id="dialog_head" title={dialogHeaderTitle || ''}>
                {dialogTitle}
              </h2>
            </div>
            <div className="modal-body">
              {organization && organization.intro_video && (
                <video
                  width="100%"
                  autoPlay="autoplay"
                  preload="true"
                  id="video"
                  tabIndex="0"
                >
                  <source
                    src={`/assets/${organization.intro_video}`}
                    type="video/mp4"
                  />
                  Your browser does not support the video tag.
                </video>
              )}
              {dialogBody && (
                <div className={`modal-body-text ${dialogBodyClass}`}>
                  <div
                    id="dialog_desc"
                    dangerouslySetInnerHTML={{ __html: dialogBody }}
                  />
                </div>
              )}
            </div>
            <div className={`modal-footer ${dialogBodyFooterClass}`}>
              {dialogCloseButtonTitle && (
                <button
                  type="button"
                  id="close-modal"
                  className="btn btn-default"
                  onClick={closeModal}
                  data-dismiss="modal"
                >
                  {dialogCloseButtonTitle}
                </button>
              )}
              {dialogSaveButtonTitle && (
                <button
                  type="button"
                  id="save-modal"
                  className="btn btn-primary"
                  onClick={saveModal}
                  data-dismiss="modal"
                >
                  {dialogSaveButtonTitle}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DialogModal;
