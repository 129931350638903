import React from 'react';
import AppointmentDetail from './AppointmentDetail';
import ProviderDetail from './ProviderDetail';

const Appointment = ({
  encounter,
  retakeScreener,
  cancellationLimitReached,
  handleCancelAppointment,
  handleRescheduleAppointment,
  patientLocation,
  showActionButtons,
  styles = {},
}) => (
  <React.Fragment>
    <AppointmentDetail
      encounter={encounter}
      retakeScreener={retakeScreener}
      cancellationLimitReached={cancellationLimitReached}
      handleCancelAppointment={handleCancelAppointment}
      handleRescheduleAppointment={handleRescheduleAppointment}
      showActionButtons={showActionButtons}
      styles={styles}
    />
    <ProviderDetail
      encounter={encounter}
      patientLocation={patientLocation}
      styles={styles}
    />
  </React.Fragment>
);

export default Appointment;
