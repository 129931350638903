import moment from 'moment';
import React, { useCallback, useState } from 'react';

import styles from './slot.module.scss';

const Slot = ({
  slot,
  stringValues,
  onClick,
  outOfTravelRadius,
  filtersApplied = {},
}) => {
  const [isSelected, setIsSelected] = useState(false);
  const handleClick = useCallback(async () => {
    setIsSelected(true);
    await onClick(slot);
    setIsSelected(false);
  }, [onClick, setIsSelected, slot]);
  const startDate = moment(slot.start_date);
  return (
    <button
      type="button"
      onClick={handleClick}
      className={`slot-button ${styles.wrapper} ${
        isSelected ? styles.selected : ''
      }`}
    >
      <span className={`${styles.dateAndIcon} flex justify-between`}>
        <span className={styles.date}>
          {stringValues[startDate.format('ddd').toLowerCase()]}
          <br />
          {stringValues[startDate.format('MMM').toLowerCase()]}{' '}
          {startDate.format('D')}
        </span>
        <span className={`${styles.iconsWrap} flex-column`}>
          {slot.in_person && filtersApplied.in_person && !outOfTravelRadius && (
            <i
              className={`${styles.slotTypeIcon} fa fa-user`}
              aria-label="in person time slot"
            />
          )}
          {slot.virtual && filtersApplied.virtual && (
            <i
              className={`${styles.slotTypeIcon} fa fa-video-camera`}
              aria-label="online time slot"
            />
          )}
        </span>
      </span>
      <span className={styles.startTime}>{startDate.format('h:mma')}</span>
    </button>
  );
};

export default Slot;
