import React, { useCallback } from 'react';
import {
  reduxForm,
  getFormValues,
  getFormSyncErrors,
  getFormMeta,
  stopSubmit,
  Field,
  reset,
} from 'redux-form';
import { connect } from 'react-redux';
import clsx from 'clsx';
import { getSetValueFun } from '../../../services/redux_form';
import {
  validations,
  masks,
  noop,
  numbersFromString,
  millisecondFromSec,
  deleteCookie,
} from '../../../helperFunctions';
import formProvider from '../../formProvider';
import { checkBoxInline, textFieldInline } from '../../inputs';

import styles from './member_journey.module.scss';
import { getMssNotificationStatus, sendMssLink } from '../../../services/mss';
import {
  emailStatus,
  getSymbolicBgColorClass,
  isEmailFailed,
  isEmailPending,
  isEmailSuccess,
  isSmsFailed,
  isSmsPending,
  isSmsSuccess,
  notificationSent,
  smsStatus,
} from '../../../services/notification/status_helper';

const formName = 'ResendMssLinkForm';
const maxNotificationStatusCheckDelay = millisecondFromSec(20);

const shouldShowContinueButton = (notificationStatus) => {
  if (emailStatus(notificationStatus) && !smsStatus(notificationStatus))
    return isEmailPending(notificationStatus);
  if (!emailStatus(notificationStatus) && smsStatus(notificationStatus))
    return isSmsPending(notificationStatus);
  if (emailStatus(notificationStatus) && smsStatus(notificationStatus))
    return (
      (isEmailPending(notificationStatus) ||
        isSmsPending(notificationStatus)) &&
      !(isEmailFailed(notificationStatus) || isSmsFailed(notificationStatus))
    );
  return false;
};

const handleFormResetAfterSubmit = (
  isInCallCenter,
  dispatch,
  notificationMethodStatuses,
) => {
  const emailSuccess = isEmailSuccess(notificationMethodStatuses, {
    defaultValue: false,
  });
  const smsSuccess = isSmsSuccess(notificationMethodStatuses, {
    defaultValue: false,
  });
  dispatch(reset(formName));
  const setFormValue = getSetValueFun(dispatch, formName);
  if (!isInCallCenter) {
    if (emailSuccess)
      setFormValue('email', notificationMethodStatuses.last_processed_email);
    if (smsSuccess)
      setFormValue('sms', notificationMethodStatuses.last_processed_sms);
  }
};

let notificationStatusPollingInterval = null;
const notificationPollingPeriod = millisecondFromSec(3);
const notificationPollingTimeout = maxNotificationStatusCheckDelay;
let notificationStatusCounter = 0;

const endNotificationStatusPolling = (props) => {
  const { setNotificationStatusLoading, setIsPolling, refreshJourneyData } =
    props;
  notificationStatusCounter = 0;
  setNotificationStatusLoading(false);
  if (refreshJourneyData) {
    refreshJourneyData();
  }
  if (notificationStatusPollingInterval) {
    setIsPolling(false);
    clearInterval(notificationStatusPollingInterval);
  }
};

const postLinkSendProcess = async ({
  mssId,
  email,
  phone,
  emailRequested,
  smsRequested,
  isInCallCenter,
  setNotificationStatus,
  props,
  dispatch,
  onSuccess,
}) => {
  const notificationStatus = await getMssNotificationStatus({
    mssId,
    email: emailRequested,
    sms: smsRequested,
    isMemberPortal: !isInCallCenter,
  });
  setNotificationStatus({
    ...notificationStatus,
    last_processed_email: email,
    last_processed_sms: phone,
  });
  if (isInCallCenter) {
    dispatch(reset(formName));
  } else {
    const notificationSuccessful = notificationSent(notificationStatus);
    if (notificationSuccessful) {
      const successMessage = notificationStatus?.common_status?.message;
      onSuccess({ message: successMessage });
      endNotificationStatusPolling(props);
    }
  }
};

const startNotificationStatusPolling = (id, values, dispatch, props) => {
  const {
    isInCallCenter,
    setNotificationStatus,
    setIsPolling,
    onSuccess = noop,
  } = props;
  notificationStatusCounter = 0;
  setIsPolling(true);
  notificationStatusPollingInterval = setInterval(() => {
    if (notificationStatusCounter > notificationPollingTimeout) {
      endNotificationStatusPolling(props);
    }
    postLinkSendProcess({
      mssId: id,
      email: values.email,
      phone: values.sms,
      emailRequested: values.email_checkbox,
      smsRequested: values.sms_checkbox,
      isInCallCenter,
      setNotificationStatus,
      dispatch,
      onSuccess,
      props,
    });
    notificationStatusCounter += notificationPollingPeriod;
  }, notificationPollingPeriod);
  return () => {
    endNotificationStatusPolling(props);
  };
};

const handleSubmit = (values, dispatch, props) => {
  const {
    patientId,
    surveyId,
    setNotificationStatusLoading,
    setShowRetrySendLinkBanner,
  } = props;
  setNotificationStatusLoading(true);
  return sendMssLink({ patientId, surveyId, values })
    .then(async ({ id }) => {
      deleteCookie('show_send_link_form');
      deleteCookie('lastest_send_link_failed');
      setShowRetrySendLinkBanner(false);
      await startNotificationStatusPolling(id, values, dispatch, props);
    })
    .catch((err) => {
      endNotificationStatusPolling(props);
      const msg =
        err.response.data && err.response.data.message
          ? err.response.data.message
          : err;
      alert(msg);
    });
};

let InitializeFromStateForm = ({
  // eslint-disable-next-line no-shadow
  handleSubmit,
  submitting,
  formValues = {},
  invalid,
  locale,
  textNotificationEnabledOrg,
  expired,
  stringValues = {},
  notificationStatus,
  initialValues,
  optOutInfo,
  dispatch,
  sendLinkFormTexts,
  isInCallCenter,
  isPolling,
  showRetrySendLinkBanner,
  onPartialSuccess = noop,
}) => {
  const setFormValue = getSetValueFun(dispatch, formName);
  const isNumberOptOut = (number) =>
    optOutInfo.sms &&
    numbersFromString(number) === numbersFromString(initialValues.sms);
  const isCurrentNumberOptOut = isNumberOptOut(formValues.sms);

  const showDoneButton =
    !isPolling ||
    (emailStatus(notificationStatus) &&
      smsStatus(notificationStatus) &&
      (isEmailSuccess(notificationStatus) || isSmsSuccess(notificationStatus)));

  const showContinueButton = shouldShowContinueButton(notificationStatus);

  const emailValidation = useCallback(
    (value) =>
      formValues.email_checkbox &&
      (validations.required(value, locale) || validations.email(value, locale)),
    [formValues.email_checkbox, locale],
  );
  const phoneValidation = useCallback(
    (value) =>
      formValues.sms_checkbox &&
      (validations.required(value, locale) || validations.phone(value, locale)),
    [formValues.sms_checkbox, locale],
  );

  // in case the notification sent successful, we won't display the orange banner
  const hideBannerForMSS =
    !isInCallCenter && notificationSent(notificationStatus);
  const notificationCommonStatus = notificationStatus?.common_status;
  const notificationCommonStatusFailed =
    notificationCommonStatus?.status === 'failed';
  const symbolicBgColorClass = getSymbolicBgColorClass(
    notificationCommonStatus,
  );

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit}>
        {((notificationStatus && !isPolling && !hideBannerForMSS) ||
          showRetrySendLinkBanner) && (
          <div
            className={clsx(
              'text-center margin-bottom-md',
              styles.notification,
              {
                [symbolicBgColorClass]: isInCallCenter,
                [styles.error]:
                  !isInCallCenter &&
                  (showRetrySendLinkBanner || notificationCommonStatusFailed),
                [styles.mssNotification]: !isInCallCenter,
              },
            )}
          >
            <p className={isInCallCenter && 'margin-0'}>
              {showRetrySendLinkBanner
                ? sendLinkFormTexts?.retryLinkSend
                : notificationCommonStatus?.message}
            </p>
            {!isInCallCenter &&
              notificationCommonStatus &&
              (showDoneButton || showContinueButton) && (
                <button type="button" onClick={onPartialSuccess}>
                  {showContinueButton
                    ? sendLinkFormTexts?.continue
                    : sendLinkFormTexts?.done}
                </button>
              )}
          </div>
        )}

        <div className="padding-left-xl padding-right-xl padding-top-sm padding-bottom-sm">
          <span className={clsx(!isInCallCenter && styles.mssExposition)}>
            {!isInCallCenter && (
              <React.Fragment>
                <p
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html:
                      sendLinkFormTexts?.ifBookingForSomeoneElseContactAssistance,
                  }}
                  className="italic"
                />
                <hr className={styles.bookingWarningSeparator} />
              </React.Fragment>
            )}
            {sendLinkFormTexts?.description ? (
              <p
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: sendLinkFormTexts?.description,
                }}
                className={styles.mssSendLinkDescription}
              />
            ) : (
              <React.Fragment>
                <p>
                  The Resend Member Self-Serve Link on this page should only be
                  used if the member <b>does NOT need to be screened</b> and is
                  unable to access the last message containing the link.
                </p>
                <p>
                  Check the box(es), and update contact information, as needed.
                  By sending the link, you are confirming you have consent from
                  the member to communicate via these methods.
                </p>
              </React.Fragment>
            )}
          </span>

          <div
            className={clsx(
              'd-flex margin-top-lg',
              !isInCallCenter && styles.mssFormWrapper,
            )}
          >
            <div
              className={clsx(
                'cell',
                isInCallCenter && 'margin-bottom-lg margin-right-lg w-50',
              )}
            >
              <div className="no-label">
                <div className="plum-checkbox">
                  <Field
                    name="email_checkbox"
                    component={checkBoxInline}
                    label={stringValues.sendViaEmail || 'Send via email'}
                  />
                  <Field
                    placeholder={stringValues.enterEmail || 'Enter email'}
                    name="email"
                    component={textFieldInline}
                    type="text"
                    className="ip-small-packed"
                    style={{ width: '100%' }}
                    normalize={masks.email}
                    disabled={!formValues.email_checkbox}
                    validate={[emailValidation]}
                  />
                </div>
              </div>
            </div>

            {textNotificationEnabledOrg && (
              <div
                className={clsx(
                  'cell',
                  isInCallCenter && 'margin-left-lg w-50',
                )}
              >
                <div className="no-label">
                  <div className="plum-checkbox">
                    <Field
                      name="sms_checkbox"
                      component={checkBoxInline}
                      label={
                        stringValues.sendViaSmsTxtMsg ||
                        'Send via SMS text message'
                      }
                      disabled={isCurrentNumberOptOut}
                    />
                    <Field
                      placeholder={
                        stringValues.enterPhoneNumber || 'Enter phone number'
                      }
                      name="sms"
                      component={textFieldInline}
                      type="text"
                      className="ip-small-packed"
                      style={{ width: '100%' }}
                      normalize={masks.phone}
                      disabled={
                        !formValues.sms_checkbox && !isCurrentNumberOptOut
                      }
                      onChange={(e) => {
                        if (isNumberOptOut(e.target.value)) {
                          setFormValue('sms_checkbox', false);
                        }
                      }}
                      validate={[phoneValidation]}
                    />
                    {isCurrentNumberOptOut && (
                      <p
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{
                          __html: sendLinkFormTexts?.optOut,
                        }}
                        className={styles.optOutText}
                      />
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div
            className={clsx(
              !isInCallCenter && styles.mssFormSubmit,
              'd-flex justify-content-end margin-top-xl',
            )}
          >
            <button
              type="button"
              className="btn btn-default plum-font"
              name="nav-button"
              onClick={() =>
                handleFormResetAfterSubmit(
                  isInCallCenter,
                  dispatch,
                  notificationStatus,
                )
              }
            >
              {stringValues.cancel || 'Reset'}
            </button>
            <button
              type="submit"
              id="save-changes"
              className="btn btn-primary plum-btn"
              disabled={
                invalid ||
                submitting ||
                (formValues &&
                  !formValues.email_checkbox &&
                  !formValues.sms_checkbox)
              }
            >
              {expired ? 'Reactivate' : stringValues.send || 'Resend'}
            </button>
          </div>
        </div>
      </form>
    </React.Fragment>
  );
};

InitializeFromStateForm = reduxForm({
  form: formName,
  onSubmit: handleSubmit,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(InitializeFromStateForm);

InitializeFromStateForm = connect((state, ownProps) => ({
  initialValues: ownProps.initialValues,
  stopSubmit,
  formValues: getFormValues(formName)(state),
  synchronousErrors: getFormSyncErrors(formName)(state),
  meta: getFormMeta(formName)(state),
}))(InitializeFromStateForm);

export default formProvider(InitializeFromStateForm);
