import React from 'react';
import styles from './footer.module.scss';

const Footer = ({ bannerText, footerText = {} }) => (
  <div className={`${styles.footerWrapper} footer-fixed`}>
    <div className={`${styles.copyrightText}`}>{footerText}</div>
    <div
      className={`${styles.customFooterText} `}
      dangerouslySetInnerHTML={{ __html: bannerText }}
    />
  </div>
);

export default Footer;
