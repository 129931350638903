import React from 'react';
import RightPageForm from "../components/RightPageForm";
import './style.scss';

class Forms extends React.Component {

  render() {
    return (
      <div className={`register-presentation`}>
        <RightPageForm {...this.props} />
      </div>
    );
  }
}

export default Forms;
