import React from 'react';
import clsx from 'clsx';
import styles from './slot.module.scss';
import { noop } from '../../../../../helperFunctions';

const MoreSlotsButton = ({ stringValues = {}, onClick = noop }) => (
  <button
    type="button"
    onClick={onClick}
    className={clsx('slot-button', styles.wrapper, styles.moreSlotsButton)}
  >
    + {stringValues.more || 'MORE'}
  </button>
);
export default MoreSlotsButton;
