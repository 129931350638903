import React from "react"
import {Formik, Form} from 'formik';
import {SelectField, TextField} from "../../../components/inputs";
import {masks, validations, csrfHeader} from "../../../../helperFunctions";
import {formValidations} from "../../../helpers/moduleHelper";
import axios from 'axios';

class ProtectedNotAuthorizedForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {surveyDefinitionOptions: [], showNextButton: false, pageErrors: null}

  }

  handleSubmit = (values, url) => {
    const {setLoader, submitURL} = this.props;
    setLoader(true);
    values = {token: values.enter_authentication_token, enter_authentication_token: values.enter_authentication_token}
    return axios({
      method: 'post',
      url: url + '.json',
      data: values,
      headers: csrfHeader,
    })
      .then(response => {
        if (response.data && response.data.errors) {
          setLoader(false);
           this.setState({pageErrors: response.data.errors});
        } else if (response.status === 200 || response.status === 302) {
          window.location.href = response.request.responseURL;
        } else {
          setLoader(false);
        }
      })
      .catch(err => {
        setLoader(false);
        const msg =
          err.response.data && err.response.data.message
            ? err.response.data.message
            : err;
        alert(msg);
      });
  }
  
  validate = (values) => {
    const {locale} = this.props;
    const fieldErrorMessage = formValidations.fieldErrorMessage(locale);
    let errors = {};
    if (!values.enter_authentication_token) {
      errors.enter_authentication_token = fieldErrorMessage;
    }
    this.setState({showNextButton: Object.keys(errors).length === 0, pageErrors: null})
    return errors;
  }

  render() {
    const {pageName, authTokenLabel, nextButtonLabel, submitURL} = this.props;
    const {showNextButton, pageErrors} = this.state;
    return (
    <div className="protected-not-authorized-cont">
        <Formik
          initialValues={{
            enter_authentication_token: '',
          }}
          onSubmit={(values, {setSubmitting}) => {
            setTimeout(() => {
              this.handleSubmit(values, submitURL).then(r => {

              })
              setSubmitting(false);
            }, 400);
          }}
          validate={this.validate}
        >
          {props => (
            <Form className="user_match" aria-label="Authorize form">
              <div className={`rows`}>
                <div className="row clear">
                  <div className="form-group col-md-12 col-sm-12" aria-labelledby="site">
                    <TextField
                      label={authTokenLabel}
                      name="enter_authentication_token"
                      type="text"
                      inputClass={`form-control`}
                    />
                  </div>
                </div>
                {pageErrors && (
                                  <div className="row clear">
                                    <div className="form-group col-md-12 col-sm-12 flt-lft">
                                      <div className="field-error">{pageErrors}
                                      </div>
                                    </div>
                                  </div>
                                )}
              </div>
              <div className="navigation-buttons-space clear">
                {showNextButton && (
                  <button type="submit" className={`btn-next flt-rgt sub-form`} name=" action" id="
                                    btn-next">{nextButtonLabel}</button>
                )}
              </div>
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}

export default ProtectedNotAuthorizedForm