import React from 'react';
import clsx from 'clsx';
import wrapField from '../../../../components/inputs/wrapField';
import AppTooltip from '../../../../../components/shared/AppTooltip';
import styles from './slot_booking_modal.module.scss';
import useWindowSize from '../../../../../hooks/useWindowSize';

const Options = ({
  value,
  slot,
  filtersApplied,
  stringValues,
  slotStyles,
  field,
  meta,
  options,
  onChange,
}) => {
  const { windowWidth } = useWindowSize();
  const isMobile = windowWidth < 1235;
  return (
    <React.Fragment>
      {options.map((o) => {
        const tooltip = !filtersApplied[o.name]
          ? o.name === 'in_person'
            ? stringValues.in_person_is_disabled_based_on_filters
            : stringValues.online_is_disabled_based_on_filters
          : slot.provider.out_of_travel_radius && o.name === 'in_person'
          ? stringValues.in_person_is_disabled_based_on_travel_radius
          : '';
        return (
          <div key={o.name} className={`${slotStyles.radioToggle}`}>
            <label
              className="blue custom-iptype1 d-inline-flex margin-0 align-items-center"
              htmlFor={`${o.name}_option`}
              data-tip
              data-for={`tooltip__radio-booking-modal-${o.name}`}
              data-class="tooltip"
            >
              <input
                id={`${o.name}_option`}
                type="radio"
                {...field}
                value={o.value}
                checked={o.value === String(meta.value)}
                onChange={(e) => {
                  field.onChange(e);
                  if (onChange) onChange(e.target.value);
                }}
                disabled={
                  !slot[o.name] ||
                  !filtersApplied[o.name] ||
                  (slot.provider.out_of_travel_radius && o.name === 'in_person')
                }
              />
              <span>
                {o.label === 'Virtual'
                  ? stringValues[o.label.toLowerCase()]
                  : stringValues.in_person}
              </span>
              {tooltip && (
                <React.Fragment>
                  {isMobile && (
                    <span
                      className={clsx(
                        styles.infoIcon,
                        'margin-left-xs material-symbols-outlined',
                      )}
                    >
                      info
                    </span>
                  )}
                  <AppTooltip
                    id={`tooltip__radio-booking-modal-${o.name}`}
                    event={isMobile && 'click'}
                    multiline
                  >
                    <div style={isMobile ? { width: 180 } : undefined}>
                      {tooltip}
                    </div>
                  </AppTooltip>
                </React.Fragment>
              )}
            </label>
            {o.name === 'in_person' &&
              value === 'in_person' &&
              slot.in_person &&
              slot.provider &&
              slot.provider.intake_appt_role !== 'coordinator' && (
                <span className={slotStyles.slotAddress}>
                  {slot.provider.address_line_1 && (
                    <span className="display-block">
                      {slot.provider.address_line_1}
                    </span>
                  )}
                  {slot.provider.address_line_2 && (
                    <span className="display-block">
                      {slot.provider.address_line_2}
                    </span>
                  )}
                </span>
              )}
          </div>
        );
      })}
    </React.Fragment>
  );
};

export default wrapField(Options);
