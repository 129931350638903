import { Form, Formik } from 'formik';
import React, { useCallback, useMemo } from 'react';
import { BottomSheet } from 'react-spring-bottom-sheet';
import useWindowSize from '../../../../../hooks/useWindowSize';

import styles from './filter_section.module.scss';

const FilterWrapper = ({
  children,
  slotsInstance,
  initialValues = {},
  onSubmit,
  handleReset,
  stringValues,
  isFilterOpen,
  validate,
  validationSchema,
  setIsFilterOpen,
}) => {
  const { windowWidth } = useWindowSize();
  const isMobile = windowWidth < 1235;
  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      onSubmit={onSubmit}
      validateOnMount
      validate={validate}
      validationSchema={validationSchema}
    >
      {(formikProps) => {
        const { isValid, submitForm, resetForm } = formikProps;
        const handleClickClear = useCallback(() => {
          handleReset();
          resetForm();
        }, [handleReset]);
        const WrapperComponent = isMobile ? BottomSheet : React.Fragment;
        const Footer = useMemo(
          () => (
            <div className={`${styles.btnWrap} flex-end`}>
              <button
                onClick={handleClickClear}
                type="button"
                className={`btn-clear ${styles.btnClear} btn btn-textonly`}
              >
                {stringValues.clear_button}
              </button>
              <button
                type="button"
                className={`btn-apply ${styles.btnSubmit} btn btn-contained ${
                  !isValid ? 'opacity-50' : ''
                }`}
                // disabled={!isValid}
                onClick={() => {
                  submitForm();
                  setIsFilterOpen(false);
                }}
              >
                {stringValues.apply_button}
              </button>
            </div>
          ),
          [isValid, handleClickClear, submitForm],
        );

        return (
          <React.Fragment>
            <WrapperComponent
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...(isMobile && {
                open: isFilterOpen,
                onDismiss: () => {
                  setIsFilterOpen(false);
                },
                expandOnContentDrag: true,
                defaultSnap: ({ minHeight }) => minHeight,
                footer: Footer,
                initialFocusRef: false,
                className: styles.bottomSheet,
              })}
            >
              <div className={styles.panelRight}>
                <div
                  className={`${styles.filterWrapper} lucet-brand-right-filter`}
                >
                  <Form>
                    {children(formikProps)}
                    {!isMobile && Footer}
                    {slotsInstance}
                  </Form>
                </div>
              </div>
            </WrapperComponent>
          </React.Fragment>
        );
      }}
    </Formik>
  );
};

export default FilterWrapper;
