/* eslint-disable camelcase */
import React from 'react';
import clsx from 'clsx';
import AppTooltip from '../../../../components/shared/AppTooltip';
import Modal from '../../../../shared/modal';
import { fixTooltipPosition } from '../../../../helperFunctions';

const ProviderDetailWrapper = ({
  isMobile,
  encounter,
  modal,
  showModalDialog,
  closeModalDialog,
  setData,
  showProviderDetail,
  tooltipReference,
  styles = {},
}) => {
  const tooltipPosition = (position) => fixTooltipPosition(position);

  return (
    <React.Fragment>
      {isMobile ? (
        // eslint-disable-next-line react/button-has-type
        <button
          className={styles.link}
          onClick={() => {
            showModalDialog();
          }}
        >
          {encounter.learn_more_about_me}
        </button>
      ) : (
        // eslint-disable-next-line react/button-has-type,jsx-a11y/anchor-is-valid
        <a
          data-tip=""
          data-event="click"
          data-for={`provider-profile-${encounter.id}`}
          className={styles.link}
        >
          {encounter.learn_more_about_me}
        </a>
      )}

      {isMobile ? (
        modal && (
          <Modal
            closeModal={() => {
              closeModalDialog();
            }}
            customProps={{
              class: 'provider_details pd-bottom-sheet',
              closeButton: false,
            }}
          >
            {showProviderDetail()}
          </Modal>
        )
      ) : (
        <AppTooltip
          id={`provider-profile-${encounter.id}`}
          tooltipRef={tooltipReference}
          className="provider-list-tooltip-mss"
          getContent={() => <div>{showProviderDetail()}</div>}
          afterShow={setData}
          type="light"
          borderColor="#cecece"
          arrowColor="#fff"
          place="top"
          clickable
          overridePosition={tooltipPosition}
        />
      )}
      {encounter.link_to_appt_confirmation?.link && (
        <a
          className={clsx(styles.link, 'view_appointment_details')}
          href={encounter.link_to_appt_confirmation?.link}
          target="_blank"
          rel="noreferrer"
        >
          {encounter.link_to_appt_confirmation?.text}
        </a>
      )}
    </React.Fragment>
  );
};

export default ProviderDetailWrapper;
